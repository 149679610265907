import {
  Aktivitet,
  Anforande,
  Bilaga,
  Dokument,
  DokumentStatus,
  Forslag,
  Intressent,
  Uppgift,
  Utskottsforslag
} from "../model/DokumentStatus";
import { CollapsibleCard, TitleCase } from "../model/Helpers";
import { bottomBorder, pagingMargin } from "../CssConstants";
import {
  faBook,
  faBookReader,
  faCalendar,
  faCalendarTimes,
  faChartPie,
  faEnvelopeOpenText,
  faFile,
  faFileAlt,
  faFileCode,
  faFileContract,
  faFilePdf,
  faFileWord,
  faFlag,
  faGlobe,
  faLink,
  faPersonBooth,
  faSearch,
  faSort,
  faStamp,
  faUser,
  faVoteYea
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import filesize from "filesize";
import moment from 'moment';
import { useTyper } from "../model/useTyper";
import {Path} from "../App";

const size = filesize.partial({ locale: "sv" });

export const ViewDokumentFull = (props: { dokument: DokumentStatus }) => {
  const dokument = props.dokument;
  return (<>
    <ViewDokument
      dokument={dokument.dokument} />
    <ViewUppgift
      uppgift={dokument.dokuppgift && dokument.dokuppgift.uppgift} />
    <ViewAnforande
      anforande={dokument.debatt && dokument.debatt.anforande} />
    <ViewUtskottsforslag
      utskottForslag={dokument.dokutskottsforslag && dokument.dokutskottsforslag.utskottsforslag} />
    <ViewIntressent
      intressent={dokument.dokintressent && dokument.dokintressent.intressent} />
    <ViewForslag
      forslag={dokument.dokforslag && dokument.dokforslag.forslag} />
    {/*
    <ViewMotforslag
      motforslag={dokument.dokmotforslag && dokument.dokmotforslag.motforslag}/>
    */}
    {/*
    <ViewReferens
      referens={dokument.dokreferens && dokument.dokreferens.referens}/>
    */}
    <ViewBilaga
      bilaga={dokument.dokbilaga && dokument.dokbilaga.bilaga} />
    <ViewAktivitet
      aktivitet={dokument.dokaktivitet && dokument.dokaktivitet.aktivitet} />
  </>)
};

const ViewDokument = (props: { dokument: Dokument }) => {
  const dokTyper = useTyper();
  const dokument = props.dokument;
  const maybeTyp = dokTyper.typer.typ.find(it => it.doktyp === dokument.doktyp);

  if (!props.dokument) return (<></>);
  return (
    (<>
      <div style={pagingMargin}>
        <article className="tile is-child notification"  >
          <div className="content" >
            <p className="title"><FontAwesomeIcon
              icon={faBook} /> {dokument.titel ? TitleCase(dokument.titel) : 'Ingen Titel'}
            </p>
            <p className={dokument.subtitel ? 'subtitle' : 'is-hidden'}>
              <FontAwesomeIcon
                icon={faBookReader} /> {dokument.subtitel && TitleCase(dokument.subtitel)}
            </p>
            <div className="content">
              <p><FontAwesomeIcon
                icon={faCalendar} /> Publicerad: {moment(dokument.datum).format('YYYY-MM-DD')}
              </p>
              <p><FontAwesomeIcon
                icon={faSort} /> Typ: {maybeTyp ? maybeTyp.namn : 'trasigt värde:' + dokument.doktyp}
              </p>
              <p><FontAwesomeIcon icon={faLink} /> <a
                href={dokument.dokument_url_html.toString()}>{dokument.dokument_url_html}</a>
              </p>
            </div>
          </div>
          <CollapsibleCard title={'Fulltext'}
            initalState={true}
          >
            <blockquote>{dokument.html}</blockquote>
          </CollapsibleCard>
        </article>
      </div>
    </>)
  )
};

const ViewAnforande = (props: { anforande?: Anforande }) => {
  if (!props.anforande) return (<></>);
  // Evil elastic does only do array if more than 1 object.
  if (Array.isArray(props.anforande)) return RenderAnforande(props.anforande);
  else return RenderAnforande([props.anforande]);
};

const RenderAnforande = (anforanden: Anforande[]) => {

  return (<>
    <CollapsibleCard title={'Anförande'}
      initalState={true} >
      {anforanden.map((it, index) => {
        return (
          <div key={it.anf_nummer}>
            <article className="tile is-child notification">
              <div className="content">
                {/*<img src={`https://mhdownload.riksdagen.se/riksdagen/unrestricted/thumb/${it.anf_video_id}_high.jpg`}/>*/}
                <p className={it.talare ? 'subtitle' : 'is-hidden'}>
                  <FontAwesomeIcon
                    icon={faUser} /> {it.talare ? it.talare : 'Namn Saknas'}
                </p>
                <p className={it.datumtid ? '' : 'is-hidden'}><FontAwesomeIcon
                  icon={faCalendarTimes} /> {it.datumtid ? moment(it.datumtid).format('YYYY-MM-DD HH:mm:ss') : 'Datumtid Saknas'}
                </p>
              </div>
              <div className={'content is-small'}>
                <blockquote>
                  {it.anf_text}
                </blockquote>
              </div>
            </article>
            {index !== anforanden.length - 1 && <div style={bottomBorder}/>}
          </div>
        )
      }
      )}
    </CollapsibleCard>
  </>)
};

const ViewUtskottsforslag = (props: { utskottForslag?: Utskottsforslag[] | Utskottsforslag }) => {
  if (!props.utskottForslag) return (<></>);
  // Evil elastic does only do array if more than 1 object.
  if (Array.isArray(props.utskottForslag)) return RenderUtskottsforslag(props.utskottForslag);
  else return RenderUtskottsforslag([props.utskottForslag]);
};

const RenderUtskottsforslag = (forslag: Utskottsforslag[]) => {

  return (<>
    <CollapsibleCard title={'Utskottsförslag'}
      initalState={true}
    >
      <div className={'content'}>
        {forslag.map((it) => {
          return (
            <div key={it.votering_id}>
              <article className="tile is-child notification">
                <h3>{it.rubrik}</h3>
                <h4>Beslutstyp: {it.beslutstyp}</h4>
                <h5 className={it.votering_id ? '' : 'is-hidden'}>
                  <a
                    href={`${Path.ENKAMMARRIKSDAGEN}/voteringStats?votering_id=${it.votering_id}`}>
                    <FontAwesomeIcon icon={faChartPie} />
                    Röstningsstatistik</a>
                </h5>
                <h5 className={it.votering_id ? '' : 'is-hidden'}>
                  <a href={`${Path.ENKAMMARRIKSDAGEN}/votering?votering_id=${it.votering_id}`}>
                    <FontAwesomeIcon icon={faPersonBooth} />
                    Personröster</a>
                </h5>
                <blockquote>{it.forslag}</blockquote>
              </article>
            </div>
          )
        }
        )}
      </div>
    </CollapsibleCard>
  </>
  )
};


const ViewForslag = (props: { forslag?: Forslag[] | Forslag }) => {
  if (!props.forslag) return (<></>);
  // Evil elastic does only do array if more than 1 object.
  if (Array.isArray(props.forslag)) return RenderForslag(props.forslag);
  else return RenderForslag([props.forslag]);
};

const RenderForslag = (forslagList: Forslag[]) => {
  return (
    (<>
      <CollapsibleCard title={'Forslag'} initalState={true} >

        {forslagList.map(it => {
          return (
            <article key={it.nummer} className="tile is-child notification">
              <div className="content">
                <p className={it.intressent ? 'subtitle' : 'is-hidden'}>
                  <FontAwesomeIcon
                    icon={faUser} /> {it.intressent ? TitleCase(it.intressent) : 'Namn Saknas'}
                </p>
                <p className={it.kammarbeslutstyp ? 'subtitle' : 'is-hidden'}>
                  <FontAwesomeIcon
                    icon={faFileContract} /> {it.kammarbeslutstyp ? TitleCase(it.kammarbeslutstyp) : 'Namn Saknas'} -
                    Kammaren: {it.kammaren}, Utskottet: {it.utskottet}
                </p>
                <div className="content">
                  <p>Behandlas i: {it.behandlas_i}
                  </p>
                  <p className={it.lydelse ? '' : 'is-hidden'}><FontAwesomeIcon
                    icon={faEnvelopeOpenText} /> {it.lydelse}
                  </p>
                  <p className={it.lydelse2 ? '' : 'is-hidden'}><FontAwesomeIcon
                    icon={faEnvelopeOpenText} /> {it.lydelse2}
                  </p>
                </div>
              </div>
            </article>
          )
        }
        )}
      </CollapsibleCard>
    </>)
  )
};

const ViewIntressent = (props: { intressent?: Intressent[] | Intressent }) => {
  if (!props.intressent) return (<></>);
  // Evil elastic does only do array if more than 1 object.
  if (Array.isArray(props.intressent)) return RenderIntressenter(props.intressent);
  else return RenderIntressenter([props.intressent]);
};

const RenderIntressenter = (intressenter: Intressent[]) => {
  return (
    (<>
      <CollapsibleCard title={'Intressenter'} initalState={true} >

        {intressenter.map(it => {
          return (
            <article key={it.intressent_id + it.roll || it.namn + it.roll} className="tile is-child notification">
              <div className="content">
                <p className="subtitle"><FontAwesomeIcon
                  icon={faUser} /> {it.namn ? TitleCase(it.namn) : 'Namn Saknas'}
                </p>
                <div className="content">
                  <p><FontAwesomeIcon
                    icon={faCalendar} /> Roll: {it.roll}
                  </p>
                  <p><FontAwesomeIcon
                    icon={faFlag} /> Parti: {it.partibet.toUpperCase()} </p>
                  <p><FontAwesomeIcon
                    icon={faSearch} /> ID: {it.intressent_id} </p>
                  <a className={it.intressent_id ? '' : 'is-hidden'}
                    href={`${Path.ENKAMMARRIKSDAGEN}/person?intressent_id=${it.intressent_id}`}><FontAwesomeIcon
                      icon={faGlobe} /> Mer info... </a>
                </div>
              </div>
            </article>)
        }
        )}
      </CollapsibleCard >
    </>)
  )
};


const ViewBilaga = (props: { bilaga?: Bilaga[] | Bilaga }) => {
  if (!props.bilaga) return (<></>);
  // Evil elastic does only do array if more than 1 object.
  if (Array.isArray(props.bilaga)) return RenderBilagor(props.bilaga);
  else return RenderBilagor([props.bilaga]);
};

const RenderBilagor = (bilagaList: Bilaga[]) => {
  return (
    (<>
      <CollapsibleCard title={'Bilagor'} initalState={true} >
        {bilagaList.map((it, index) => {
          return (
            <div key={it.dok_id + it.filtyp}>
              <article className="tile is-child notification">
                <div className="content">
                  <p
                    className={it.titel ? 'title' : 'is-hidden'}>{getFileIcon(it.filtyp)} {it.titel ? TitleCase(it.titel) : 'Namn Saknas'}
                  </p>
                  <p className={it.subtitel ? 'subtitle' : 'is-hidden'}>
                    <FontAwesomeIcon
                      icon={faStamp} /> {it.subtitel ? TitleCase(it.subtitel) : 'Namn Saknas'}
                  </p>
                  <div className="content">
                    <p><
                      FontAwesomeIcon icon={faFile} /> Dok_id: {it.dok_id}
                    </p>
                    <p>
                      <a href={it.fil_url}>
                        {getFileIcon(it.filtyp)} {it.filnamn} ({size(Number(it.filstorlek))})
                      </a>
                    </p>
                  </div>

                </div>
              </article>
              {index !== bilagaList.length - 1 && <div style={bottomBorder}/>}
            </div>
          )
        }
        )}
      </CollapsibleCard>
    </>)
  )
};

const getFileIcon = (fileType: string) => {
  switch (fileType) {
    case 'PDF':
    case 'pdf':
      return <FontAwesomeIcon icon={faFilePdf} />;
    case 'doc':
    case 'DOC':
    case 'docx':
    case 'DOCX':
      return <FontAwesomeIcon icon={faFileWord} />;
    case 'HTM':
      return <FontAwesomeIcon icon={faFileCode} />;
    default:
      return <FontAwesomeIcon icon={faFileAlt} />
  }
};

const ViewAktivitet = (props: { aktivitet?: Aktivitet[] }) => {

  if (!props.aktivitet) return (<></>);

  const aktiviteterList: Aktivitet[] = props.aktivitet;
  return (
    (<>
      <CollapsibleCard title={'Aktivitet'} initalState={true} >

        {aktiviteterList.map((it, index) => {
          return (
            <article key={index} className="tile is-child notification">
              <div className="content">
                <p className="subtitle"><FontAwesomeIcon
                  icon={faStamp} /> {it.namn ? TitleCase(it.namn) : 'Namn Saknas'}
                </p>
                <div className="content">
                  <p><FontAwesomeIcon
                    icon={faCalendar} /> Datum: {moment(it.datum).format('YYYY-MM-DD')}
                  </p>
                  <p><FontAwesomeIcon
                    icon={faVoteYea} /> Status: {it.status} </p>
                </div>
              </div>
            </article>
          )
        }
        )}
      </CollapsibleCard>
    </>)
  )
};

const ViewUppgift = (props: { uppgift?: Uppgift[] | Uppgift }) => {
  if (!props.uppgift) return (<></>);
  // Evil elastic does only do array if more than 1 object.
  if (Array.isArray(props.uppgift)) return RenderUppgift(props.uppgift);
  else return RenderUppgift([props.uppgift]);
};

const RenderUppgift = (uppgifter: Uppgift[]) => {
  return (
    <CollapsibleCard title={'Uppgift'} initalState={true} >
      {uppgifter.map((it, index) => {
        return (
          <div key={index}>
            <article className="tile is-child notification">
              <div className="content">
                <p className="subtitle"><FontAwesomeIcon
                  icon={faStamp} /> {it.namn ? TitleCase(it.namn) : 'Uppgiftsnamn saknas'}
                </p>
                <div className="content">
                  <p className={it.systemdatum ? '' : 'is-hidden'}>
                    <FontAwesomeIcon
                      icon={faCalendar} /> Datum: {it.systemdatum}
                  </p>
                  <blockquote> {it.text} </blockquote>
                </div>
              </div>

            </article>
            {index !== uppgifter.length - 1 && <div style={bottomBorder}></div>}
          </div>
        )
      }
      )}
    </CollapsibleCard>
  )
};
