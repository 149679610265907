import React, {useEffect, useState} from "react";
import {DokumentResponse} from "../model/DokumentStatus";
import {StringParam, useQueryParam} from "use-query-params";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {ViewDokumentFull} from "./ViewDokumentFull";
import {ClipboardButton} from "../model/ClipboardService";
import {errorDecider} from "../model/Helpers";
import {Modal} from "../model/Modal";
import {noBoxShadow} from "../CssConstants";

const PAGE_TITLE = "Enkammarriksdagen";
const STORED_QUERY = "dok-id-query";

interface Props {
  setPageTitle: (pageTitle: string) => void;
}

interface StoredParams {
  dokId?: string
}

export const DokumentTab = (props: Props) => {
  const [dokId = "", setDokId] = useQueryParam('dok_id', StringParam);
  const [dokIdInput, setDokIdInput] = useState<string>(dokId || "");
  const [result, setResult] = useState<DokumentResponse | undefined>();
  const [emptySubmit, setEmptySubmit] = useState<boolean>(false);
  const [disconnected, setDisconnected] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  props.setPageTitle(PAGE_TITLE);

  useEffect(() => {
    if (!dokId) {
      const storedObject = sessionStorage.getItem(STORED_QUERY);
      if (storedObject) {
        const { dokId }: StoredParams = JSON.parse(storedObject);
        setDokId(dokId);
        setDokIdInput(dokId!)
      }
    }

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setEmptySubmit(false);
    if (dokId === undefined || dokId.trim() === '') {
      setResult(undefined);
      return
    }
    setStorage();
    fetchDokument(dokId)
      .then(setResult)
      .catch(errorHandler)
  }, [dokId]); // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchDokument(dokIdInput: string): Promise<DokumentResponse> {
    setLoading(true);
    let jsonAnswer: DokumentResponse;
    let response: Response | undefined;
    const urlParam = new URLSearchParams();
    urlParam.append('dok_id', dokIdInput);
    try {
      response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/dokument?${urlParam}`);
    } finally {
      setLoading(false);
      errorDecider(response);
    }
    jsonAnswer = await response.json();
    return jsonAnswer;
  }

  function errorHandler(e: Error) {
    switch (e.name) {
      case "EmptyError":
        setEmptySubmit(true);
        setResult(undefined);
        break;
      case "ConnectionError":
        setDisconnected(true);
        break;
    }
  }

  function setStorage() {
    const storedObject: StoredParams = { dokId };
    sessionStorage.setItem(STORED_QUERY, JSON.stringify(storedObject))
  }

  function submitSearch(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setDokId(dokIdInput, "pushIn");
  }

  return (
    <div>
      <form className="field has-addons" onSubmit={submitSearch}>
        <div className="control is-expanded has-icons-left">
          <input className="input" id='dokId-input' type="text"
                 value={dokIdInput || ""}
                 placeholder="Skriv 'dok_id' för dokument här"
                 onChange={(event) => setDokIdInput(event.target.value)}/>
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faSearch}/>
          </span>
        </div>
        <div className="control">
          <button
            className={loading ? "button is-info is-loading" : "button is-info"}>
            Sök
          </button>
        </div>
        <ClipboardButton hidden={result === undefined}/>
      </form>
      <div className={result ? '' : 'is-hidden'}>
        {result !== undefined ?
          <ViewDokumentFull dokument={result.dokumentstatus}/> : <></>}
      </div>
      <div className="box" style={noBoxShadow}>
        {emptySubmit &&
        <p className="subtitle is-4 has-text-danger has-text-centered ">Inga
          sökresultat </p>}
      </div>
      <Modal
        disconnected={disconnected}
        setDisconnected={setDisconnected}/>
    </div>
  )
};
