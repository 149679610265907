import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "70%"
    },
    title: {
      marginBottom: 20,
    },
    colorBoxContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      flex: 1,
    },
    colorBox: {
      width: 400,
      marginRight: 8,
      marginBottom: 8,
    },
    textContainer: {
      marginLeft: 40,
      paddingLeft: 10,
      backgroundColor: "#FFFFFF",
    },
    colorBoxTitle: {
    },
    colorBoxText: {
      paddingTop: 0,
      paddingLeft: 10,
    }
  }),
);
