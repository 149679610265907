import React from 'react';

import Typography from "@material-ui/core/Typography";

import {useStyles} from "./AboutView.style";

const AboutView = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.headContainer}>
        <Typography gutterBottom variant="h3" component="h1">
          Om Örebro universitets analystjänst för riksdagsdata
        </Typography>
      </div>
      <div>
        <article className="message is-danger">
          <div className="message-header"><p>Obs!</p></div>
          <div className="message-body">Söktjänsten är under uppbyggnad så vissa driftstörningar kan förekomma.</div>
        </article>
        <p><span className={classes.boldHeading}>Om oss:</span></p>
	      <p><span>The Open Parliament Laboratory (OPaL) är en forskningsmiljö fokuserad på att skapa en infrastruktur för att lättare kunna använda och analysera stora datamängder. Forskningsmiljön utgår från den svenska riksdagens öppna data, ett unikt digitaliserat arkiv som sträcker sig tillbaka till 1500-talet. Inom ramen för OPaL utvecklas en sökmotor och analysverktyg för humanistisk och samhällsvetenskaplig forskning med utgångspunkt i riksdagens öppna data. </span></p>
	      <p> </p>
      	<p><span className={classes.boldHeading}>Om databasen:</span></p>
      	<p><span>Örebro universitets analystjänst för riksdagsdata möjliggör sökningar och analyser av riksdagens öppna data (</span><a href="https://data.riksdagen.se/">
          <span><span>https://data.riksdagen.se/</span></span></a>
          <span>) och Kungliga bibliotekets databas över riksdagstryck (</span><a href="https://riksdagstryck.kb.se/">
            <span><span>https://riksdagstryck.kb.se/</span></span></a>
            <span>). Dessa data erbjuder ett heltäckande och systematiserat arkiv över riksdagens samtida och historiska arbete. Databasen består av anföranden, omröstningar och dokument från riksdagen från 1521 fram till idag. </span></p>
      	<p> </p>
      	<p><span className={classes.boldHeading}>Källhänvisning:</span></p>
        <p><span>Användare av tjänsten uppmanas att källhänvisa till databaserna i eventuella publikationer:</span></p>
        <p> </p>
        <p><span>Riksdagstryck - Förteckning av referenser till riksdagstryck (1521-1970) finns tillgängliga via </span><a href="https://libris.kb.se/hitlist?d=libris&amp;q=db%3ariks"><span><span>Libris</span></span></a><span>. </span><a href="https://libris.kb.se/hitlist?d=libris&amp;q=db%3ariks"><span><span>https://libris.kb.se/hitlist?d=libris&amp;q=db%3ariks</span></span></a></p>
      	<p> </p>
	      <p><span>Källhänvisningar till Riksdagens Öppna Data (1971-) </span><span>enligt följande:</span></p>
      	<p><span>Källa: Sveriges riksdag</span></p>
      	<p> </p>
      	<p><span>Användare av </span><span>Örebro universitets analystjänst för riksdagsdata uppmanas också att hänvisa till tjänsten på följande sätt:</span></p>
        <p><span>The Open Parliament Laboratory (2021). Örebro universitets analystjänst för riksdagsdata. </span><a href="http://Riksdagsdata.oru.se" ><span>Riksdagsdata.oru.se</span></a></p>
      	<p> </p>
      	<p><span className={classes.boldHeading}>Sidansvarig:</span></p>
        <p><a href="mailto:christian.lundahl@oru.se"><span><span>Christian Lundahl</span></span></a><span> </span></p>
      </div>
    </>
  );
}

export default AboutView
