import React from 'react';
import Grid from '@material-ui/core/Grid';

import CustomCard from "./component/CustomCard/CustomCard";
import Typography from "@material-ui/core/Typography";

import Enkammarriksdagen from '../../Assets/Img/enkammarriksdagen.jpg';
import Tvakammarriksdagen from '../../Assets/Img/tvakammarriksdagen.jpg';
import Standsriksdagen from '../../Assets/Img/standsriksdagen.jpg';
import {Path} from "../../App";
import {useStyles} from "./HomeView.style";

const HomeView = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.textContainer}>
        <Typography gutterBottom variant="h3" component="h1">
          Örebro universitets analystjänst för riksdagsdata
        </Typography>
        <Typography gutterBottom variant="h5" component="h2">
          Välj data att söka i nedan
        </Typography>
      </div>
      <Grid container direction="row" justify="center" alignItems="center" className={classes.cardContainer}>
        <CustomCard title={"Ståndsriksdagen"} onClickGoTo={Path.STANDSRIKSDAGEN_SEARCH} img={Standsriksdagen}/>
        <CustomCard title={"Tvåkammarriksdagen"} onClickGoTo={Path.TVAKAMMARRIKSDAGEN_SEARCH} img={Tvakammarriksdagen}/>
        <CustomCard title={"Enkammarriksdagen"} onClickGoTo={Path.ENKAMMARRIKSDAGEN} img={Enkammarriksdagen}/>
      </Grid>
    </>
  );
}

export default HomeView
