import { Parser } from 'json2csv';
import React, { useEffect, useState } from 'react';
import { checkBoxColumns, smallLeftMargin } from '../CssConstants'

import { Anforande } from "./ApiResponse";
import ExportAnforande from '../anforande/export/ExportAnforande'
import downloadExport from './downloadExport'

const anforandeProperties: (keyof Anforande["anforande"])[] =
  ["anforande_id",
    "anforande_nummer",
    "anforandetext",
    "avsnittsrubrik",
    "dok_datum",
    "dok_hangar_id",
    "dok_id",
    "dok_nummer",
    "dok_rm",
    "dok_titel",
    "intressent_id",
    "kammaraktivitet",
    "parti",
    "rel_dok_id",
    "replik",
    "systemdatum",
    "talare",
    "underrubrik"];

export interface ExportParams {
  queryParam: string,
  partier?: Array<string>,
  gteDate?: string,
  lteDate?: string,
  talare: string
}

export const AnforandeExportModal = (props: { openExportModal: boolean, setOpenExportModal: (state: boolean) => void, exportParams: ExportParams, numberOfResults: string }) => {
  const { openExportModal, setOpenExportModal, exportParams, numberOfResults } = props;
  const [checkedExportProps, setCheckedExportProps] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);
  const [downloadedAnforanden, setDownloadedAnforanden] = useState(0);
  const [csvExport, setCsvExport] = useState<boolean>(false);
  const [exportWithBom, setExportWithBom] = useState<boolean>(false);

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        setOpenExportModal(false)
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  });
  const handleClick = (elem: string, alreadyContains: boolean) => {
    if (alreadyContains) {
      setCheckedExportProps(checkedExportProps.filter(item => item !== elem))
    } else {
      setCheckedExportProps([...checkedExportProps, elem])
    }
  };
  const addDownloaded = (inc: number) => {
    if (inc === -1) {
      setDownloadedAnforanden(0);
      return
    }
    setDownloadedAnforanden((old) => old + inc)
  };
  /**
   * TODO: Optinal Bom option
   */
  const customConverter = (objArray: Array<Anforande>) => {
    const json2csv = new Parser({
      withBOM: exportWithBom,
      quote: '"',
      delimiter: ","
    });
    let csvString = json2csv.parse(objArray.map(elem => elem.anforande));
    downloadExport({exportType: "csv", exportName: "anforande", csvString: csvString, json: []});
  };
  return (
    <div>
      <div className={openExportModal ? "modal is-active" : 'is-hidden'}>
        <div className="modal-background"
             onClick={() => setOpenExportModal(false)}/>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Exportera anföranderesultat </p>
            <button className="delete" aria-label="close"
                    onClick={() => setOpenExportModal(false)}/>
          </header>
          <section className="modal-card-body">
            {/* <p>Inlcude everything</p> */}
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  onChange={(e) => e.target.checked ? setCheckedExportProps([...anforandeProperties]) : setCheckedExportProps([])}
                  checked={checkedExportProps.length === anforandeProperties.length}>
                </input>
                {`  alla`}
              </label>
              <ul style={checkBoxColumns}>
                {anforandeProperties.map((elem) => {
                  const alreadyContains = checkedExportProps.some(it => it === elem);
                  return (
                    <div key={elem}>

                      <label className="checkbox">
                        <input type="checkbox"
                               onChange={() => handleClick(elem, alreadyContains)}
                               checked={alreadyContains}/>
                        {`  ${elem}`}
                      </label>
                    </div>
                  )
                })}
              </ul>
            </div>
          </section>

          <footer className="modal-card-foot">
            {loading ?
              <div
                className="modal-card-title is-left is-size-6"> {downloadedAnforanden === 0 ? `Fetching metadata...` : `Fetched ${downloadedAnforanden} of ${numberOfResults}`}</div>
              :
              <div className="modal-card-title control is-left is-size-6">

                <label className="radio">
                  <input type="radio" name="answer" checked={!csvExport}
                         onChange={() => {
                           setCsvExport(false)
                         }}/>
                  {` JSON`}
                </label>
                <label className="radio">
                  <input type="radio" name="answer" checked={csvExport}
                         onChange={() => {
                           setCsvExport(true)
                         }}/>
                  {` CSV `}
                </label>
                {csvExport &&
                <label className="checkbox" style={smallLeftMargin}>
                  <input type="checkbox" checked={exportWithBom} name="answer"
                         onChange={() => setExportWithBom(!exportWithBom)}/>
                  {` with UTF-8 BOM`}
                </label>}
              </div>
            }

            <button className="button"
                    onClick={() => setOpenExportModal(false)}>Avbryt
            </button>
            <button
              className={loading ? "button is-info is-right is-loading" : "button is-info is-right"}
              disabled={checkedExportProps.length <= 0} onClick={() => {
              ExportAnforande(exportParams, setLoading, checkedExportProps, addDownloaded)
                .then((list) => {
                    csvExport ? customConverter(list) : downloadExport({exportType: "json", exportName: "anforande", csvString: "", json: list})
                  }
                )
            }}
            >Exportera
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};


