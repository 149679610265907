import React from "react";

import { Anforande } from "../model/ApiResponse";


export const SimplePager = (props: {
  anf: Anforande | undefined,
  doSearch: (newNr: string) => void
}) => {
  if (props.anf === undefined || props.anf.anforande === undefined) return (<></>);
  const current = Number(props.anf.anforande.anforande_nummer);
  return (
    <nav className="pagination is-centered is-rounded is-medium"
         role="navigation" aria-label="pagination">
      <button className="pagination-previous" onClick={decrease}
         disabled={Number(props.anf.anforande.anforande_nummer) <= 1}>Förra anforande</button>
      <ul className="pagination-list">
        <li>
          <button className="pagination-link is-current"
             aria-label={`Page ${current}`}
             aria-current="page">{current}</button>
        </li>
      </ul>
      <button className="pagination-next" onClick={increase}>Nästa anforande</button>
    </nav>
  );

  async function increase() {
    props.doSearch(String(current + 1))
  }

  async function decrease() {
    if (1 < current) {
      props.doSearch(String(current - 1));
    }
  }
};

