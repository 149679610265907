import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export const useStyles = (color: string) => makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      color: color,
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }),
);
