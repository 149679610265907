import React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert, {AlertProps, Color} from '@material-ui/lab/Alert';
import {useStyles} from "./Snackbar.style";

export interface Props {
  open: boolean;
  message: string;
  severity: Color;
  setIsOpen: any;
}

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Snackbar = (props: Props) => {
  const classes = useStyles();
  const {open, message, setIsOpen, severity} = props;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
  };

  return (
    <div className={classes.root}>
      <MuiSnackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
        <Alert onClose={handleClose} className={classes.alert} severity={severity}>
          {message}
        </Alert>
      </MuiSnackbar>
    </div>
  );
}

export default Snackbar
