import {
  DokumentExport,
  DokumentExportResponse
} from '../../model/DokumentStatus';

import { DokumentSearchResponse } from '../../search/SearchInterface';
import { ExportParams } from '../../model/DokumentExportModal'

async function exportDokument(
  checkedExportProps: Array<string>,
  exportParams: ExportParams,
  setLoading: (loading: boolean) => void,
  addDownloaded: (num: number) => void
): Promise<Array<DokumentExport>> {
  setLoading(true);
  const stringArray = await fetchIdArray(exportParams, 1);
  const dokumentStatusArray = await fetchDocuments(stringArray, (inc: number) => addDownloaded(inc), checkedExportProps);
  setLoading(false);
  addDownloaded(-1);
  return dokumentStatusArray;
}

async function fetchDocuments(
  allIDsToFetch: Array<string>,
  increaseDownloadNumberBy: (downloaded: number) => void,
  checkedExportProps: Array<string>
): Promise<Array<DokumentExport>> {
  const documentsLength = allIDsToFetch.length;
  const batchSize = 10;
  const allElements: Array<DokumentExport> = [];
  for (let index = 0; index < documentsLength; index += batchSize) {
    const part = await fetchBatchedPart(allIDsToFetch.slice(index, index + batchSize), checkedExportProps);
    // Dokumentsstatus maybe undefined if all properties are missing in object (due to property-filtering)
    allElements.push(...part.map(it => it.dokumentstatus || {}));
    increaseDownloadNumberBy(part.length)
  }
  return allElements;
}

interface BatchAnswer {
  documents: Array<DokumentExportResponse>
}

async function fetchBatchedPart(
  someIdsToFetch: Array<string>,
  checkedExportProps: Array<string>,
): Promise<DokumentExportResponse[]> {
  let jsonAnswer: BatchAnswer;
  let response: Response;
  const urlParam = new URLSearchParams();
  urlParam.append('dokIdList', someIdsToFetch.join(','));
  urlParam.append('include', checkedExportProps.join(','));
  response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/dokument?${urlParam}`);
  if (!response.ok) {
    throw Error("Retries not implemented, throwing error as could not fetch documents.")
  }
  jsonAnswer = await response.json();
  return jsonAnswer.documents;
}

async function fetchIdArray(
  exportParams: ExportParams,
  page: number,
): Promise<Array<string>> {
  const { queryParam, types, gteDate, lteDate, selectedProperties } = exportParams;
  let jsonAnswer: DokumentSearchResponse;
  const params = new URLSearchParams();
  let response: Response | undefined;
  params.append('query', queryParam);
  page && params.append('page', page.toString());
  types && params.append('types', types.join(','));
  gteDate && params.append('gteDate', gteDate);
  lteDate && params.append('lteDate', lteDate);
  selectedProperties && params.append('hasProperty', selectedProperties.join(','));
  params.append('pageSize', '100');
  response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/dokument/search?${params}`);
  jsonAnswer = await response.json();
  if (jsonAnswer.paging.of === page) {
    // Ok found nexit
    return jsonAnswer.dokument.map(it => it.dok_id);
  } else {
    const array = jsonAnswer.dokument.map(it => it.dok_id);
    array.push(
      ...await fetchIdArray(
        exportParams,
        page + 1
      ));
    return array
  }
}

export default exportDokument
