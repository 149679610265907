import React from "react";
import Chart from "./Component/Chart/Chart";
import {PieChartData} from "react-minimal-pie-chart";
import {getColors} from "../../util/ColorUtil";
import {AnforandeDistributionResponse} from "../../model/ApiResponse";
import {useStyles} from "./PieChart.style";
import ChartInfo from "./Component/ChartInfo/ChartInfo";
import {Card} from "@material-ui/core";

export enum Variant {
  GENDER = "Fördelning bland kön",
  PARTY = "Fördelning bland parti",
  COUNTY = "Fördelning bland valkrets",
  BORN_YEAR = "Fördelning bland födelseår",
  BORN_DECADE = "Fördelning bland årtionde",
}

interface Props {
  variant: Variant;
  partition: string;
  data?: AnforandeDistributionResponse;
}

const parseData = (partition: string, data?: AnforandeDistributionResponse): PieChartData[] => {
  if (!data || !data.result || data.result?.length < 1) {
    return [];
  }

  const partitionData = data.result.find(it => it.partition === partition);
  if (!partitionData) {
    return [];
  }

  return partitionData.buckets.map((bucket, index) => {
    return {
      title: bucket.side,
      value: bucket.count,
      color: getColors()[index].hex
    }
  })

}

const PieChart = (props:Props) => {
  const classes = useStyles();
  const {data, partition, variant} = props;
  const parsedData = parseData(partition, data)

  // if no result return nothing
  if (parsedData.length < 1) {
    return null;
  }

  return (
    <Card className={classes.root}>
      <Chart data={parsedData}/>
      <ChartInfo title={variant} data={parsedData}/>
    </Card>
  )
};

export default PieChart;
