import React, {useEffect, useState} from "react";
import {VoteringAnalysisResponse} from "../model/ApiResponse";
import {VoteringAnalysisRequest} from "../model/ApiRequest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearchPlus} from "@fortawesome/free-solid-svg-icons";
import {
  BornDecadeChart,
  BornYearChart,
  CountyChart,
  GenderChart,
  PartyChart,
  TotalChart
} from "./VoteCharts";
import {ArrayParam, useQueryParam} from "use-query-params";
import {ClipboardButton} from "../model/ClipboardService";
import {errorDecider} from "../model/Helpers";
import {Modal} from "../model/Modal";
// Make sure these are in the same order as view for logical reasons.
const allStatsBy = ['gender', 'party', 'county', 'bornYear', 'bornDecade'];
const EMPTY_ARRAY: Array<string> = [];

const PAGE_TITLE = "Enkammarriksdagen";
const STORED_QUERY = "votering-analysis-query";
const STORED_ID_QUERY = "votering-id-query";

interface Props {
  setPageTitle: (pageTitle: string) => void;
}

interface StoredParams {
  voteringIdsParam: Array<string>,
  statsBy: Array<string>
}

export const VoteringAnalysis = (props: Props) => {
  const [voteringIdsParam = EMPTY_ARRAY, setVoteringIdsParam] = useQueryParam('votering_id', ArrayParam);
  const [statsBy = EMPTY_ARRAY, setStatsBy] = useQueryParam('statsBy', ArrayParam);
  const [voteringStats, setVoteringStats] = useState<VoteringAnalysisResponse | undefined>(undefined);
  // having an empty array here forces the effect to fire to infinity and beyond
  const [disconnected, setDisconnected] = useState<boolean>(false);
  props.setPageTitle(PAGE_TITLE);

  useEffect(() => {
    const storedObject = sessionStorage.getItem(STORED_QUERY);
    if (storedObject) {
      const { voteringIdsParam, statsBy }: StoredParams = JSON.parse(storedObject);
      setVoteringIdsParam(voteringIdsParam);
      setStatsBy(statsBy)
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function getStatistics(): Promise<VoteringAnalysisResponse> {
      setStorage();
      let jsonAnswer: VoteringAnalysisResponse;
      let response: Response | undefined;
      try {
        response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/votering/analyze`,
          {
            method: 'POST',
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(createRequest(voteringIdsParam, statsBy)) // add request
          });
        jsonAnswer = await response.json();
      } finally {
        errorDecider(response)
      }
      return jsonAnswer;
    }

    if (voteringIdsParam.length !== 0) {
      getStatistics()
        .then(setVoteringStats)
        .catch(errorHandler)
    }
  }, [voteringIdsParam, statsBy]); // eslint-disable-line react-hooks/exhaustive-deps

  function errorHandler(e: Error) {
    switch (e.name) {
      case "EmptyError":
        // setEmptySubmit(true);
        setVoteringStats(undefined);
        break;
      case "ConnectionError":
        setDisconnected(true);
        break;
    }
  }

  function setStorage() {
    const storedObject: StoredParams = { voteringIdsParam, statsBy };
    sessionStorage.setItem(STORED_QUERY, JSON.stringify(storedObject));
    sessionStorage.setItem(STORED_ID_QUERY, JSON.stringify({ "voteringIdParam": voteringIdsParam[0] }))
  }

  function removeId(id: string) {
    const tempState = voteringIdsParam.filter(it => it !== id);
    setVoteringIdsParam(tempState)
  }

  function flushIds() {
    setVoteringStats(undefined);
    setVoteringIdsParam(EMPTY_ARRAY);
  }

  function submitSearch(voteringIds: string) {
    if (!voteringIdsParam.includes(voteringIds)) {
      setVoteringIdsParam([...voteringIdsParam, voteringIds], "pushIn")
    }
  }

  function typeHandler(types: string[]) {
    setStatsBy(types);
  }

  // Came from external source, do search but only once.
  return (<>
    {/* <AppendingForm voteringIds={voteringIds} setVoteringIds={setVoteringIds} voteringStats={voteringStats} /> */}
    <AppendingForm submitSearch={submitSearch} voteringStats={voteringStats}/>
    <ViewDeletables voteringIdsParam={voteringIdsParam}/>
    <TypesMultiSelector allStatsBy={allStatsBy} statsBy={statsBy}
                        typeHandler={typeHandler}/>
    {/* <div className="control">
      <button onClick={submitSearch}
        className="button is-info"
        disabled={voteringIds.length === 0}>
        Hämta analys på befintliga voteringar.
      </button>
    </div>
    <br /> */}
    {voteringIdsParam.length > 0 &&
    <div>
      <TotalChart voteringStats={voteringStats}/>
      <GenderChart voteringStats={voteringStats}/>
      <PartyChart voteringStats={voteringStats}/>
      <CountyChart voteringStats={voteringStats}/>
      <BornYearChart voteringStats={voteringStats}/>
      <BornDecadeChart voteringStats={voteringStats}/>
    </div>}
    <Modal
      disconnected={disconnected}
      setDisconnected={setDisconnected}/>
  </>);

  function createRequest(voteringIds: Array<string>, statsBy: Array<string>): VoteringAnalysisRequest {
    return {
      statsBy: statsBy,
      voteringIds: voteringIds
    };
  }

  function renderOneId(id: string) {
    return (
      <span key={id} className="tag is-success">
        {id}
        <button onClick={_ => removeId(id)} className="delete is-small"/>
      </span>
    )
  }

  function deleteBlock() {
    return (
      <span key="deleteAll" className="tag is-danger">
        Ta bort alla.
        <button onClick={_ => flushIds()} className="delete is-small"/>
      </span>
    )
  }

  function ViewDeletables(props: {
    voteringIdsParam: string[]
  }) {
    return (<>
      <div className="block">
        {props ? props.voteringIdsParam ? props.voteringIdsParam.map(renderOneId) : <></> : <></>}
        {props.voteringIdsParam.length > 0 ? deleteBlock() : <></>}
      </div>
    </>)
  }
};

const AppendingForm = (props: {
  voteringStats?: VoteringAnalysisResponse,
  submitSearch: Function
}): JSX.Element => {
  const [fieldId, setFieldId] = useState<string>();
  const { voteringStats, submitSearch } = props;

  function submitId(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setFieldId("");
    if (fieldId !== undefined && fieldId.trim() !== '') {
      submitSearch(fieldId)
    }
  }

  return (<form className="field has-addons" onSubmit={submitId}>
    <div className="control is-expanded has-icons-left">
      <input className="input" id='votering-id-input' type="text"
             value={fieldId || ""}
             placeholder="Skriv 'votering_id' för voteringen här"
             onChange={event => setFieldId(event.target.value)}/>
      <span className="icon is-small is-left">
        <FontAwesomeIcon icon={faSearchPlus}/>
      </span>
    </div>
    <div className="control">
      <button className="button is-info">
        Lägg till ID.
      </button>
    </div>
    <ClipboardButton hidden={voteringStats === undefined}/>
  </form>)
};
const TypesMultiSelector = (props: {
  allStatsBy: string[],
  statsBy: string[],
  typeHandler: Function
}) => {
  const { allStatsBy, statsBy, typeHandler } = props;
  const handleSelect = (it: string) => {
    typeHandler([...statsBy, it])
  };
  const handleDeselect = (it: string) => {
    let temp = statsBy.filter(contains => contains !== it);
    typeHandler(temp)
  };
  return (<>
    <div className="buttons has-addons">
      {allStatsBy.map(it => {
          const selected = props.statsBy.includes(it);
          return <button
            key={it}
            className={selected ? 'button is-primary is-info is-selected' : 'button'}
            onClick={_ => {
              selected ?
                handleDeselect(it) : handleSelect(it)
            }
            }>{getSwedishName(it)}</button>
        }
      )}

    </div>
  </>)
};

function getSwedishName(name: string): string {
  switch (name) {
    case 'party':
      return 'Parti';
    case 'county':
      return 'Län';
    case 'gender':
      return 'Kön';
    case 'bornYear':
      return 'Födelseår';
    case "bornDecade":
      return 'Årtionde';
    default:
      return "Okänd"
  }
}
