import React from "react";
import ReactMinimalPieChart, {PieChartData} from "react-minimal-pie-chart";
import {useStyles} from "./Chart.style";

interface Props {
  data: PieChartData[];
}

const Chart = (props:Props) => {
  const classes = useStyles()
  const {data} = props;

  return <ReactMinimalPieChart
    className={classes.root}
    animate={true}
    data={data}
  />
};

export default Chart;
