import React, { useEffect, useState } from "react";

import { CollapsibleCard } from "./Helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { usePartier } from "./usePartier";

const INFO_TEXT = 'Partierna är genererade från dokumenten, i antalsordning.';

export const PartierList = (props: {
  setFunction?: ((value: string[], updateType?: "pushIn") => void),
  currentParties?: string[]
}) => {

  const partier = usePartier();

  const [pressedParties, setPressedParties] = useState<string[]>(props.currentParties ? props.currentParties : []);


  useEffect(() => {
    props.currentParties ? setPressedParties(props.currentParties) : setPressedParties([]);
  }, [props.currentParties]);

  function partyPressed(wasPressed: string) {
    const newState = [...pressedParties, wasPressed];
    setPressedParties(newState);
    props.setFunction && props.setFunction(newState, "pushIn")
  }

  function pressedRemove(wasPressed: string) {
    const newState = pressedParties.filter(it => it !== wasPressed);
    setPressedParties(newState);
    props.setFunction && props.setFunction(newState)
  }
  return (
    <div className="card">
      <CollapsibleCard title={'Partier'} initalState={(pressedParties.length === 0)} >
        {partier.partier.map(party => {
          const alreadyContains = pressedParties.some(it => it === party);
          return <button key={party}
            className={alreadyContains ? 'button is-info is-selected' : 'button'}
            onClick={alreadyContains ? _ => pressedRemove(party) : _ => partyPressed(party)}
          >
            {party}
          </button>
        }
        )}
        <span className="icon is-medium has-text-info" title={INFO_TEXT}>
          <FontAwesomeIcon className="is-center" icon={faInfoCircle} />
        </span>
      </CollapsibleCard>
    </div>
  )
};
