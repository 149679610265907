import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from 'react';
import {TextField, Typography} from "@material-ui/core";
import {useStyles} from "./BetweenYearFilter.style";

export interface BetweenYear {
    from: number,
    to: number,
}

export interface Props {
  defaultValues?: BetweenYear;
  setFilter: Dispatch<SetStateAction<BetweenYear | undefined>>
}

const BetweenYearFilter = (props: Props) => {
  const classes = useStyles();
  const {setFilter, defaultValues} = props;

  const handleChange = (event: ChangeEvent) => {
    // @ts-ignore
    const from = document.getElementById('year-from').value
    // @ts-ignore
    const to  = document.getElementById('year-to').value

    if (from !== "" && to !== "") {
      setFilter({from, to})
    } else {
      setFilter(undefined)
    }
  };

  return (
    <div className={classes.root}>
      <Typography component={"h1"} variant={"h6"}>
        Start och Stopp År
      </Typography>
      <form className={classes.formContainer}>
        <TextField
          id="year-from"
          name={"from"}
          type={"number"}
          aria-label="year-from"
          label="fr.o.m."
          placeholder={"YYYY"}
          className={classes.textField}
          onChange={handleChange}
          defaultValue={defaultValues?.from}
        />
        <TextField
          id="year-to"
          name={"to"}
          type={"number"}
          aria-label="year-to"
          label="t.o.m."
          placeholder={"YYYY"}
          className={classes.textField}
          onChange={handleChange}
          defaultValue={defaultValues?.to}
        />
      </form>
    </div>

);
}

export default BetweenYearFilter;
