import React, { useEffect, useState } from "react";

import { CollapsibleCard } from "../model/Helpers";
import { DokumentStatus } from "../model/DokumentStatus";

export const selectableDokumentProperties: (keyof DokumentStatus)[] = [
  'debatt', "dokaktivitet", "dokbilaga", "dokforslag", "dokintressent",
  "dokmotforslag", "dokreferens", "dokuppgift", "dokutskottsforslag"
];

/*
 * API improvement could be to allow for:
 *    - Negation (instead of clicking everything except it)       // Java API wanted, not required.
 *    - OR operator (these ones currently on a strict AND basis). // Java API required.
 *      - Maybe even combine AND and OR operators?                // Java API required.
 *    - More specific sub-properties                              // API sufficient.
 */
const emptyArray: Array<string> = [];

export const DokumentProperties = (props: {
  setProperties: (set: string[], updateType?: "pushIn") => void,
  selectedProps: Array<string>

}) => {
  const [selectedProperties = props.selectedProps, setSelectedProperties] = useState<Array<string>>();

  // Effect that clears state if top tab is used
  useEffect(() => {
    props.selectedProps ? setSelectedProperties(props.selectedProps) : setSelectedProperties(emptyArray);
  }, [props.selectedProps]);

  function pressToAdd(wasPressed: string) {
    const newState = [...selectedProperties, wasPressed];
    setSelectedProperties(newState);
    props.setProperties && props.setProperties(newState, "pushIn")
  }

  function pressToRemove(wasPressed: string) {
    const newState = selectedProperties.filter(it => it !== wasPressed);
    setSelectedProperties(newState);
    props.setProperties && props.setProperties(newState)
  }

  return <div className="card">
    <CollapsibleCard title={'Nyckelfiltrering'}
      initalState={props.selectedProps.length === 0}
    >
      {selectableDokumentProperties.map(property => {
        const alreadyContains = selectedProperties.some(it => it === property);
        return <button key={property}
          className={alreadyContains ? 'button is-info is-selected' : 'button'}
          onClick={alreadyContains ? _ => pressToRemove(property) : _ => pressToAdd(property)}
          title={property}
        >
          <code>{property}.*</code>
        </button>
      }
      )}

    </CollapsibleCard>
  </div>
};
