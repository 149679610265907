import {
  faBook,
  faCalendar,
  faFingerprint,
  faIdBadge,
  faUser
} from "@fortawesome/free-solid-svg-icons";

import { Anforande } from "../model/ApiResponse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { TitleCase } from "../model/Helpers";
import moment from 'moment';
import { pagingMargin } from "../CssConstants";
import {Path} from "../App";

export const SearchPageView = (props: { anf?: Anforande }) => {
  if (props.anf === undefined) return (<></>);
  const anforande = props.anf.anforande;
  return (
    <div key={anforande.anforande_id} style={pagingMargin}>
      <article className="tile is-child notification">
        <div className="content">
          <p className="title">
            <FontAwesomeIcon
              icon={faBook} /> {anforande.avsnittsrubrik && TitleCase(anforande.avsnittsrubrik)}
          </p>
          <a
            href={`${Path.ENKAMMARRIKSDAGEN}/person?intressent_id=${anforande.intressent_id}`}
            className={anforande.dok_titel ? 'subtitle' : 'is-hidden'}>
            <p>
              <FontAwesomeIcon
                icon={faUser} /> {anforande.talare}
            </p>
          </a>
          <div className="content">
            <p><FontAwesomeIcon icon={faIdBadge} /> Dok Id: {anforande.dok_id}
            </p>
            <p><FontAwesomeIcon
              icon={faFingerprint} /> Anförande-Id: {anforande.anforande_id} </p>
            <p><FontAwesomeIcon
              icon={faCalendar} /> Datum: {anforande.dok_datum && moment(anforande.dok_datum).format('YYYY-MM-DD')}
            </p>
          </div>
        </div>
        <div className="tile">
          {anforande.anforandetext}
        </div>
      </article>
    </div>
  )
};

