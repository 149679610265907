import { Parser } from 'json2csv';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { HistogramList } from "../model/AnalysisModel";
import moment from "moment";
import { DateTypes } from "../anforande/AnforandeAnalysis";
import { downloadExportHistogramAsJson } from "./downloadExportAsJson";

export interface Props {
  tableData: HistogramList,
  openExportModal: boolean,
  setOpenExportModal: (state: boolean) => void,
  dateType?: DateTypes;
}

interface TableRow {
  [key: string]: string | number
}

export const AnalyzeExportModal = (props: Props) => {
  const { openExportModal, setOpenExportModal } = props;
  const [csvExport, setCsvExport] = useState<boolean>(false);
  const fileName = props.tableData.queryTerm + "_" + new Date().toLocaleDateString();

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        setOpenExportModal(false)
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  });

  const getCsvFormatData = (): string => {
    const json2csv = new Parser({ delimiter: "," });
    let tableRowArray = [] as TableRow[];
    props.tableData.histogramList.forEach(item => {
      let tableRow = {} as TableRow;
      tableRow["datum"] = props.dateType === "riksmote" ? item.date : moment(item.date).format(props.dateType === "year" ? "YYYY" : "YYYY-MM")
      tableRow["total"] = item.total
      item.partyCountList.map(parti => tableRow[parti.party] = parti.count)
      tableRowArray.push(tableRow);
    })

    return json2csv.parse(tableRowArray);
  };

  return (
    <div>
      <div className={openExportModal ? "modal is-active" : 'is-hidden'}>
        <div className="modal-background" onClick={() => setOpenExportModal(false)} />

        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Exportera tabelldata </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setOpenExportModal(false)} />
          </header>

          <footer className="modal-card-foot">
            <div className="modal-card-title control is-left is-size-6">
              <label className="radio">
                <input type="radio" name="answer" checked={!csvExport}
                  onChange={() => {
                    setCsvExport(false)
                  }} />
                {` JSON`}
              </label>
              <label className="radio">
                <input type="radio" name="answer" checked={csvExport}
                  onChange={() => {
                    setCsvExport(true)
                  }} />
                {` CSV `}
              </label>
            </div>
            {csvExport ?
              <CSVLink
                className={"button is-info is-right"}
                data={getCsvFormatData()}
                separator={","}
                filename={fileName}
              >
                Exportera
              </CSVLink>
              :
              <button
                className={"button is-info is-right"}
                onClick={() => downloadExportHistogramAsJson(fileName, props.tableData, props.dateType)}>
                Exportera
              </button>
            }
          </footer>
        </div>
      </div>
    </div>
  );
};

export default AnalyzeExportModal;
