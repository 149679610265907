import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {customTheme, PANTONE_COOL_GRAY_9} from "../../CustomTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      left: 10,
      top: 0,
      zIndex: 11,
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(-3),
    },
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },

    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemText: {
      color: customTheme.palette.primary.main
    },
    listItemIcon: {
      color: PANTONE_COOL_GRAY_9,
    },
    logoContainer: {
      width: "100%",
      position: "absolute",
      bottom: 30,
      textAlign: "center"
    }
  }),
);
