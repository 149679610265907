import {getStandsriksdagsData, QueryParams} from "./GetStandsriksdagsData";
import {StandsriksdagenMeta} from "../model/ApiResponse";

export enum Properties{
  TITLE = "title",
  YEARS = "years",
  FILNAMN = "filnamn",
  DOKTYP = "doktyp",
  DOKUMENT_TEXT = "dokumenttext",
}

export const getStandsriksdagsDataExport = (queryParams: QueryParams): Promise<StandsriksdagenMeta[]> => {
  return getExportData(queryParams)
}

const getExportData = (queryParams: QueryParams, data?: StandsriksdagenMeta[]): Promise<StandsriksdagenMeta[]> => {
  return getStandsriksdagsData(queryParams)
    .then(result => {
      if (!data) {
        data = [];
      }
      if (result.paging.page < result.paging.of ) {
        data = data.concat(result.dokument)
        let nextPage = Number(queryParams.page) + 1;
        queryParams.page = nextPage.toString();
        return getExportData(queryParams, data)
      }
      return data.concat(result.dokument);
    })
}


export const getPropertiesValues = (): string[] => {
  // @ts-ignore
  return Object.keys(Properties).map((property ) => Properties[property])
}
