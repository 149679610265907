import React, {useEffect, useState} from "react";
import SearchBar from "../../../Component/SearchBar/SearchBar";
import {SearchLegendModal, Variant} from "../../../model/SearchLegendModal";
import Pagination from '@material-ui/lab/Pagination';
import {Paging, StandsriksdagenMeta} from "../../../model/ApiResponse";
import {
  getStandsriksdagsData,
  getTypesValues,
  QueryParams,
  ResponseData,
  Types
} from "../../../Dataprovider/GetStandsriksdagsData";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParam
} from "use-query-params";
import DocumentCard from "../../../Component/DocumentCard/DocumentCard";
import ExportDialog from "../../../Component/ExportDialog/ExportDialog";
import {
  getPropertiesValues,
  getStandsriksdagsDataExport
} from "../../../Dataprovider/GetStandsriksdagsDataExport";
import {Path} from "../../../App";
import {Filter} from "../../../Component/SearchBar/Component/SearchFilter/SearchFilter";

const PAGE_TITLE = "Ståndsriksdagen";
const STORED_QUERY = "stand-query";
const PAGE_SIZE = "10";

interface Props {
  setPageTitle: (pageTitle: string) => void;
}

const SearchView = (props: Props) => {
  const [page = 1, setPage] = useQueryParam('page', NumberParam);
  const [query = "", setQuery] = useQueryParam('query', StringParam);
  const [betweenYearFrom = "", setBetweenYearFrom] = useQueryParam('gteYear', NumberParam);
  const [betweenYearTo = "", setBetweenYearTo] = useQueryParam('lteYear', NumberParam);
  const [types = [Types.BONDESTANDET], setTypes] = useQueryParam('types', ArrayParam);
  const [result, setResult] = useState<ResponseData>({dokument: [], paging: {} as Paging});
  const [showExportDialog, setShowExportDialog] = useState<boolean>(false);
  const [showSearchLegend, setShowSearchLegend] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  props.setPageTitle(PAGE_TITLE);

  const handleSubmit = (newQuery: string, selectedValue?: string, filter?: Filter) => {
    if (newQuery !== "" && selectedValue){
      setIsLoading(true)
      setPage(1)
      setQuery(newQuery)
      setTypes([selectedValue])

      // Set all optional filter rules
      if (filter?.betweenYear) {
        setBetweenYearFrom(filter.betweenYear.from)
        setBetweenYearTo(filter.betweenYear.to)
      }

      getStandsriksdagsData({
        types: [selectedValue],
        query: newQuery,
        page: "1",
        pageSize: PAGE_SIZE,
        gteYear: filter?.betweenYear ? filter.betweenYear.from : "",
        lteYear: filter?.betweenYear ? filter.betweenYear.to: "",
      }).then(result => {
        setResult(result)
        setIsLoading(false)
      })
      sessionStorage.setItem(STORED_QUERY, JSON.stringify({
        types: [selectedValue],
        query: newQuery,
        page: page.toString(),
        pageSize: PAGE_SIZE,
        gteYear: filter?.betweenYear ? filter.betweenYear.from : "",
        lteYear: filter?.betweenYear ? filter.betweenYear.to : "",
      }))
    }
  }

  const handlePagination = (event: object, page:number) => {
    window["scrollTo"]({top: 0, behavior: "smooth"})
    setPage(page)
    setIsLoading(true)
    getStandsriksdagsData({
      types,
      query: query,
      page: page.toString(),
      pageSize: PAGE_SIZE,
      gteYear: betweenYearFrom,
      lteYear: betweenYearTo,
    })
      .then(result => {
        setIsLoading(false)
        setResult(result)
      })
    sessionStorage.setItem(STORED_QUERY, JSON.stringify({
      types,
      query: query,
      page: page.toString(),
      pageSize: PAGE_SIZE,
      gteYear: betweenYearFrom,
      lteYear: betweenYearTo,
    }))
  }

  const handleExport = (): Promise<StandsriksdagenMeta[]> => {
    return getStandsriksdagsDataExport({
      query: query,
      types: types,
      page: "1",
      pageSize: "100",
      gteYear: betweenYearFrom,
      lteYear: betweenYearTo,
    })
  };

  // Reads session storage or queryparams and makes a search
  useEffect(() => {
    const storedValues = sessionStorage.getItem(STORED_QUERY);
    if (query) {
      setIsLoading(true)
      getStandsriksdagsData({
        types,
        query: query,
        page: page.toString(),
        pageSize: PAGE_SIZE,
        gteYear: betweenYearFrom,
        lteYear: betweenYearTo,
      })
        .then(result => {
          setResult(result)
          setIsLoading(false)
        })
    }
    else if (storedValues) {
      const storedQuery: QueryParams = JSON.parse(storedValues);
      setIsLoading(true)
      setPage(Number(storedQuery.page))
      setQuery(storedQuery.query)
      setTypes(storedQuery.types)
      setBetweenYearFrom(Number(storedQuery.gteYear))
      setBetweenYearTo(Number(storedQuery.lteYear))
      getStandsriksdagsData({...storedQuery, page: storedQuery.page.toString(), pageSize: PAGE_SIZE})
        .then(result => {
          setResult(result)
          setIsLoading(false)
        })
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SearchLegendModal isVisible={showSearchLegend} setIsVisible={setShowSearchLegend} variant={Variant.REGULAR}/>
      <ExportDialog
        onExport={handleExport}
        title={"Exportera ståndsriksdags data"}
        open={showExportDialog}
        setOpen={setShowExportDialog}
        properties={getPropertiesValues()}
        exportName={"Standsriksdagen"}
      />
      <SearchBar
        isLoading={isLoading}
        defaultQuery={query}
        defaultFilter={{betweenYear: {from: Number(betweenYearFrom), to: Number(betweenYearTo)}}}
        hits={result?.paging.hits}
        selectDefaultValue={types[0]}
        selectValues={getTypesValues()}
        showExport={!!result?.paging.hits}
        showFilter={true}
        showCopyLink={!!result?.paging.hits}
        showQuestionMark={true}
        onExport={() => setShowExportDialog(!showExportDialog)}
        onQuestionMark={() => setShowSearchLegend(!showSearchLegend)}
        onSubmit={handleSubmit}
      />
      {result.dokument.map(meta => DocumentCard({match: meta, basePath: Path.STANDSRIKSDAGEN_DOCUMENT}))}
      {result.dokument.length > 0 && <Pagination page={result.paging.page} count={result.paging.of} onChange={handlePagination}/>}
      {result.dokument.length < 1 && result.paging.hits && <h1>Inga resultat hittades</h1>}
    </>
  )
};

export default SearchView;
