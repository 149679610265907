import React from "react";
import Divider from "@material-ui/core/Divider";
import {DividerType} from "../IconButton/IconButton";
import {useStyles} from "./TextButton.style";

interface Props {
  color?: string;
  text: string;
  divider?: DividerType;
  isVisible?: boolean;
  ariaLabel: string;
}

const TextButton = (props: Props) => {
  const {text, divider, ariaLabel, isVisible, color} = props
  const classes = useStyles();

  if (!isVisible){
    return null;
  }

  return (
    <>
      {divider === DividerType.LEFT &&
      <Divider className={classes.divider} orientation="vertical"/>}
      <span style={{color: color ? color: "currentColor"}} className={classes.textButton} aria-label={ariaLabel}>{text.replace('hits', '')}</span>
      {divider === DividerType.RIGHT &&
      <Divider className={classes.divider} orientation="vertical"/>}
    </>
  )
}

export default TextButton;
