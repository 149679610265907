import React, { useState } from "react";

import { CollapsibleCard } from "./Helpers";
import { DebounceInput } from 'react-debounce-input';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

interface PersonerSearchAnswer {
  personer: string[]
}

const noError = { error: false };

/**
 * This is the "Person Search" component that will set the selected person
 * in the in-parameter setter.
 * @param props
 * @constructor
 */
export const PersonSearch = (props: { setFunction?: (value: string, updateType?: "pushIn") => void, parties?: string[], currentSpeaker?: string }) => {

  // const [query, setQuery] = useState<string>(props.currentSpeaker ? props.currentSpeaker : '');
  const [result, setResult] = useState<string[]>([]);
  const [error, setError] = useState<{ error: boolean, namn?: string }>(noError);
  const [input, setInput] = useState<string>("");

  async function searchPerson(query: string): Promise<string[]> {
    if (!query) return [];
    let jsonAnswer: PersonerSearchAnswer;
    try {
      const params = new URLSearchParams();
      params.append('query', query);
      if (props.parties && props.parties.length !== 0) params.append('party', props.parties.join(','));
      let response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/personer/autocomplete?${params}`);
      jsonAnswer = await response.json();
    } catch (e) {
      console.error(e);
      return [];
    }
    if (jsonAnswer && jsonAnswer.personer) {
      return jsonAnswer.personer;
    } else {
      setError({ error: true, namn: query });
      return [];
    }
  }


  const onChange = (newValue: string) => {
    setInput(newValue);
    setError(noError);
    if (newValue) {
      searchPerson(newValue)
        .then(setResult);
    } else {
      setResult([]);
    }
  };

  function setAndReset(setState: string) {
    setInput("");
    props.setFunction && props.setFunction(setState, "pushIn");
    // setQuery(setState);
    setResult([]);
  }

  return (
    <div className="card is-collapsible">
      <CollapsibleCard title={'Talare'} initalState={true} >
        <p className="control is-expanded has-icons-left">
          <DebounceInput className="input"
            type="text"
            id="person-type-input"
            value={input}
            debounceTimeout={300}
            placeholder="Sök på personer."
            onChange={event => onChange(event.target.value)} />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faUsers} />
          </span>
        </p>
        <div className="container are-small">
          {result && result.map(string =>
            <button key={string} onClick={_ => setAndReset(string)}
              className='button is-info is-outlined'>{string}</button>
          )}
        </div>
        <button
          className={error.error ? 'button is-outlined is-warning' : 'is-hidden'}>
          {`Inga parlamentariker funna på: '${error.namn}'`}
        </button>
      </CollapsibleCard>
    </div>
  )
};
