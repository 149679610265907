import React, { useState } from "react";
import {
  ExtraVoteResult,
  VotePartition,
  VoteResult,
  VoteringAnalysisResponse
} from "../model/ApiResponse";
import ReactMinimalPieChart from "react-minimal-pie-chart";
import { TitleCase } from "../model/Helpers";
import VoteringsFordelningModal from "../model/VoteringsFordelningModal";
import { ChartType } from "../model/ChartTypeEnum";

const defaultLabelStyle = {
  fontSize: '4px',
  fontFamily: 'sans-serif',
  fill: '#121212',
};

const CHART_GROUP_BY = 3;

export const TotalChart = (props: { voteringStats?: VoteringAnalysisResponse }): JSX.Element => {
  const [openExportModal, setOpenExportModal] = useState(false)
  if (!props.voteringStats) return (<></>);

  return <div
    className={props.voteringStats ? "columns is-centered" : "is-disabled"}>
    <div className="column is-one-third">
      <h1 className="title has-text-centered is-centered">Totalt röster:
        <button
          onClick={() => setOpenExportModal(true)}
          style={{ marginLeft: 30 }}
          className={'button is-centered is-danger is-outlined'}>
          Exportera
        </button>
      </h1>
      {voteToChart(props.voteringStats.voteResult)}
      {voteToTable(props.voteringStats.voteResult)}
    </div>
    <VoteringsFordelningModal openExportModal={openExportModal} setOpenExportModal={setOpenExportModal} tableDataTotal={props.voteringStats.voteResult} chartType={ChartType.total} />
  </div>
};

export const GenderChart = (props: { voteringStats?: VoteringAnalysisResponse }): JSX.Element => {
  if (!props.voteringStats || !props.voteringStats.voteSpecifics) return (<></>);
  const part = props.voteringStats.voteSpecifics.find(it => it.partition === 'gender');
  if (!part) return (<></>);
  return <CreateChart text={'Rösterna fördelade bland könen'}
    part={part} chartType={ChartType.gender} />
};

export const PartyChart = (props: { voteringStats?: VoteringAnalysisResponse }) => {
  if (!props.voteringStats || !props.voteringStats.voteSpecifics) return (<></>);
  const part = props.voteringStats.voteSpecifics.find(it => it.partition === 'party');
  if (!part) return (<></>);
  return <CreateChart text={'Rösterna fördelade partierna:'}
    part={part} chartType={ChartType.party} />
};

export const CountyChart = (props: { voteringStats?: VoteringAnalysisResponse }) => {
  if (!props.voteringStats || !props.voteringStats.voteSpecifics) return (<></>);
  const part = props.voteringStats.voteSpecifics.find(it => it.partition === 'county');
  if (!part) return (<></>);
  return <CreateChart text={'Rösterna fördelade bland valkretsarna:'}
    part={part} chartType={ChartType.county} />
};

export const BornYearChart = (props: { voteringStats?: VoteringAnalysisResponse }) => {
  if (!props.voteringStats || !props.voteringStats.voteSpecifics) return (<></>);
  const part = props.voteringStats.voteSpecifics.find(it => it.partition === 'bornYear');
  if (!part) return (<></>);
  return <CreateChart text={'Rösterna fördelade bland födelseår:'} part={part} chartType={ChartType.bornYear} />
};

export const BornDecadeChart = (props: { voteringStats?: VoteringAnalysisResponse }) => {
  if (!props.voteringStats || !props.voteringStats.voteSpecifics) return (<></>);
  const part = props.voteringStats.voteSpecifics.find(it => it.partition === 'bornDecade');
  if (!part) return (<></>);
  return <CreateChart text={'Rösterna fördelade bland årtionde:'}
    part={part} chartType={ChartType.bornDecade} />
};

export const CreateChart = (props: { text: string, part: VotePartition, chartType: ChartType }) => {
  const [openExportModal, setOpenExportModal] = useState(false)
  return <>
    <h1 className="title coloumn has-text-centered is-full">{props.text}
      <button
        onClick={() => setOpenExportModal(true)}
        style={{ marginLeft: 30 }}
        className={'button is-centered is-danger is-outlined'}>
        Exportera
        </button>
    </h1>
    <MakeChartForPartition partitions={props.part.voteResult} />
    <VoteringsFordelningModal openExportModal={openExportModal} setOpenExportModal={setOpenExportModal} tableDataPartition={props.part} chartType={props.chartType} />
  </>
};


export const MakeChartForPartition = (props: { partitions: ExtraVoteResult[] }) => {
  const groupByThree: Array<ExtraVoteResult[]> = [];
  for (let i = 0; i < props.partitions.length; i++) {
    const next = i * CHART_GROUP_BY;
    groupByThree.push(props.partitions.slice(next, next + CHART_GROUP_BY));
  }
  return (<>
    {groupByThree.map(makeChartForSingleGroup)}
  </>
  )
};


function makeChartForSingleGroup(chartThisChunk: ExtraVoteResult[], index: number) {
  return <div key={index} className='columns is-centered'>
    {chartThisChunk.map(createSingleChart)}
  </div>
}

function createSingleChart(chartMe: ExtraVoteResult) {
  return <div key={chartMe.side} className="column is-one-third">
    <h1
      className="title has-text-centered is-centered">{TitleCase(chartMe.side) + ':'}</h1>
    {voteToChart(chartMe.result)}
    {voteToTable(chartMe.result)}
  </div>
}

function voteToChart(voteResult: VoteResult) {
  return <ReactMinimalPieChart
    label={({ data, dataIndex }) => {
      const extData = data[dataIndex];
      const roundPercent = Math.round(extData.percentage);
      if (roundPercent !== 0) {
        return (typeof extData.title === 'string' ? extData.title.slice(0, 6) : '') + ': ' + Math.round(extData.percentage) + '%'
      } else return '';
    }
    }
    labelPosition={70}
    labelStyle={defaultLabelStyle}
    data={[
      { title: 'Ja', value: voteResult.yes, color: '#E38627' },
      { title: 'Nej', value: voteResult.no, color: '#C13C37' },
      { title: 'Frånvarande', value: voteResult.absent, color: '#6A2135' },
      { title: 'Avstår', value: voteResult.refrain, color: '#6a2562' },
    ]}
  />
}

function voteToTable(voteResult: VoteResult) {
  return <table className="table">
    <tbody>
      <tr>
        <td>{'Ja: ' + voteResult.yes}</td>
        <td>{'Nej: ' + voteResult.no}</td>
        <td>{'Frånvarande: ' + voteResult.absent}</td>
        <td>{'Avstår: ' + voteResult.refrain}</td>
      </tr>
    </tbody>
  </table>
}
