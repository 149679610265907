import React, {useEffect, useState} from "react";
import {AnforandeDistributionResponse} from "../model/ApiResponse";
import {AnforandeDistributionRequest} from "../model/ApiRequest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearchPlus} from "@fortawesome/free-solid-svg-icons";
import {ArrayParam, useQueryParam} from "use-query-params";
import {ClipboardButton} from "../model/ClipboardService";
import {errorDecider} from "../model/Helpers";
import {Modal} from "../model/Modal";
import PieChart, {Variant} from "../Component/PieChart/PieChart";
// Make sure these are in the same order as view for logical reasons.
const allStatsBy = ['gender', 'party', 'county', 'bornYear'];
const EMPTY_ARRAY: Array<string> = [];

const STORED_QUERY = "anforande-distribution-query";
const STORED_SEARCHSTRING_QUERY = "anforande-distribution-searchstring-query"

interface StoredParams {
  searchStringParam: string,
  statsBy: Array<string>
}

export const AnforandeDistribution = () => {
  const [statsBy = EMPTY_ARRAY, setStatsBy] = useQueryParam('statsBy', ArrayParam);
  const [anforandeStats, setAnforandeStats] = useState<AnforandeDistributionResponse | undefined>(undefined);
  const [searchStringParam, setSearchStringParam] = useState<string>("");
  // having an empty array here forces the effect to fire to infinity and beyond
  const [disconnected, setDisconnected] = useState<boolean>(false);
  // const [emptySubmit, setEmptySubmit] = useState<boolean>(false);

  useEffect(() => {
    const storedObject = sessionStorage.getItem(STORED_QUERY);
    if (storedObject) {
      const { searchStringParam, statsBy }: StoredParams = JSON.parse(storedObject);
      setSearchStringParam(searchStringParam);
      setStatsBy(statsBy);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function getStatistics(): Promise<AnforandeDistributionResponse> {
      setStorage();
      let jsonAnswer: AnforandeDistributionResponse;
      let response: Response | undefined;
      try {
        response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/anforande/distribution`,
          {
            method: 'POST',
            mode: "cors",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(createRequest(searchStringParam, statsBy)) // add request
          });
        jsonAnswer = await response.json();
      } finally {
        errorDecider(response)
      }
      return jsonAnswer;
    }

    if (searchStringParam.length !== 0) {
      getStatistics()
        .then(setAnforandeStats)
        .catch(errorHandler)
    }
  }, [searchStringParam, statsBy]); // eslint-disable-line react-hooks/exhaustive-deps

  function errorHandler(e: Error) {
    switch (e.name) {
      case "EmptyError":
        // setEmptySubmit(true);
        setAnforandeStats(undefined);
        break;
      case "ConnectionError":
        setDisconnected(true);
        break;
    }
  }

  function setStorage() {
    const storedObject: StoredParams = { searchStringParam, statsBy };
    sessionStorage.setItem(STORED_QUERY, JSON.stringify(storedObject));
    sessionStorage.setItem(STORED_SEARCHSTRING_QUERY, JSON.stringify({ "searchStringParam": searchStringParam }))
  }

  function submitSearch(searchString: string) {
      setSearchStringParam(searchString)
  }

  function typeHandler(types: string[]) {
    setStatsBy(types);
  }

  // Came from external source, do search but only once.
  return (<>
    <AppendingForm submitSearch={submitSearch} anforandeStats={anforandeStats} />
    <TypesMultiSelector allStatsBy={allStatsBy} statsBy={statsBy}
      typeHandler={typeHandler} />
    {searchStringParam.length > 0 &&
      <div>
        <PieChart variant={Variant.GENDER} data={anforandeStats} partition={"gender"}/>
        <PieChart variant={Variant.PARTY} data={anforandeStats} partition={"party"}/>
        <PieChart variant={Variant.COUNTY} data={anforandeStats} partition={"county"}/>
        <PieChart variant={Variant.BORN_YEAR} data={anforandeStats} partition={"bornYear"}/>
      </div>}
    <Modal
      disconnected={disconnected}
      setDisconnected={setDisconnected} />
  </>);

  function createRequest(searchString: string, statsBy: Array<string>): AnforandeDistributionRequest {
    return {
      statsBy: statsBy,
      searchString: searchString
    };
  }
};

const AppendingForm = (props: {
  anforandeStats?: AnforandeDistributionResponse,
  submitSearch: Function
}): JSX.Element => {
  const [searchString, setSearchString] = useState<string>();
  const { anforandeStats, submitSearch } = props;

  function submitId(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (searchString !== undefined && searchString.trim() !== '') {
      submitSearch(searchString)
    }
  }

  return (<form className="field has-addons" onSubmit={submitId}>
    <div className="control is-expanded has-icons-left">
      <input className="input" id='votering-id-input' type="text"
        value={searchString || ""}
        placeholder="Skriv din sökterm här"
        onChange={event => setSearchString(event.target.value)} />
      <span className="icon is-small is-left">
        <FontAwesomeIcon icon={faSearchPlus} />
      </span>
    </div>
    <div className="control">
      <button className="button is-info">
        Sök
      </button>
    </div>
    <ClipboardButton hidden={anforandeStats === undefined} />
  </form>)
};
const TypesMultiSelector = (props: {
  allStatsBy: string[],
  statsBy: string[],
  typeHandler: Function
}) => {
  const { allStatsBy, statsBy, typeHandler } = props;
  const handleSelect = (it: string) => {
    typeHandler([...statsBy, it])
  };
  const handleDeselect = (it: string) => {
    let temp = statsBy.filter(contains => contains !== it);
    typeHandler(temp)
  };
  return (<>
    <div className="buttons has-addons">
      {allStatsBy.map(it => {
        const selected = props.statsBy.includes(it);
        return <button
          key={it}
          className={selected ? 'button is-primary is-info is-selected' : 'button'}
          onClick={_ => {
            selected ?
              handleDeselect(it) : handleSelect(it)
          }
          }>{getSwedishName(it)}</button>
      }
      )}

    </div>
  </>)
};

function getSwedishName(name: string): string {
  switch (name) {
    case 'party':
      return 'Parti';
    case 'county':
      return 'Län';
    case 'gender':
      return 'Kön';
    case 'bornYear':
      return 'Födelseår';
    default:
      return "Okänd"
  }
}
