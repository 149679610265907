import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch, faUser} from '@fortawesome/free-solid-svg-icons'
import {VoteringFull} from "../model/ApiResponse";
import {StringParam, useQueryParam} from "use-query-params";
import {VoteringFullView} from "./VoteringFullView";
import {Modal} from "../model/Modal";
import {errorDecider} from "../model/Helpers";
import {noBoxShadow} from "../CssConstants";

const PAGE_TITLE = "Enkammarriksdagen";
const STORED_QUERY = "votering-id-query";
const STORED_ANALYSIS_QUERY = "votering-analysis-query";

interface Props {
  setPageTitle: (pageTitle: string) => void;
}

interface StoredParams {
  voteringIdParam?: string
}

export const VoteringTab = (props: Props) => {
  const [filterState, setFilterState] = useState<string | undefined>(undefined);
  const [filterRost, setRost] = useState<string | undefined>(undefined);
  const [voteringIdParam = "", setVoteringIdParam] = useQueryParam('votering_id', StringParam);
  const [emptySubmit, setEmptySubmit] = useState<boolean>(false);
  const [votering, setVotering] = useState<VoteringFull | undefined>(undefined);
  const [disconnected, setDisconnected] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  props.setPageTitle(PAGE_TITLE);

  useEffect(() => {
    if (!voteringIdParam) {
      const storedObject = sessionStorage.getItem(STORED_QUERY);
      if (storedObject) {
        const { voteringIdParam }: StoredParams = JSON.parse(storedObject);
        setVoteringIdParam(voteringIdParam);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    setEmptySubmit(false);
    if (voteringIdParam === undefined || voteringIdParam.trim() === '') {
      setVotering(undefined); // do nothing if no text
      return
    }
    searchVotering()
      .then(setVotering)
      .catch(errorHandler)
  }, [voteringIdParam]); // eslint-disable-line react-hooks/exhaustive-deps

  async function searchVotering(): Promise<VoteringFull> {
    setLoading(true);
    setStorage();
    let jsonAnswer: VoteringFull;
    let response: Response | undefined;
    try {
      response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/votering?votering_id=${voteringIdParam}`);
      // TODO: Handle error.
    } finally {
      setLoading(false);
      errorDecider(response)
    }
    jsonAnswer = await response.json();
    return jsonAnswer;
  }

  function errorHandler(e: Error) {
    switch (e.name) {
      case "EmptyError":
        setEmptySubmit(true);
        setVotering(undefined);
        break;
      case "ConnectionError":
        setDisconnected(true);
        break;
    }
  }

  function setStorage() {
    const storedObject: StoredParams = { voteringIdParam };
    sessionStorage.setItem(STORED_QUERY, JSON.stringify(storedObject));

    sessionStorage.setItem(STORED_ANALYSIS_QUERY, JSON.stringify({
      "voteringIdsParam": [voteringIdParam],
      "statsBy": []
    }))


  }

  function submitSearch(input: string) {
    setVoteringIdParam(input, "pushIn");
  }

  return (
    <div>
      <InputComponent
        submitSearch={submitSearch}
        voteringIdParam={voteringIdParam}
        loading={loading}
      />
      <div className={votering ? "field  has-addons" : 'is-hidden'}>
        <div
          className="control is-expanded has-icons-left has-addons">
          <input className="input" type="text"
                 defaultValue={filterState}
                 placeholder="Filtrera på namn"
                 onSubmit={event => event.preventDefault()}
                 onChange={event => setFilterState(event.target.value)}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faUser}/>
          </span>
        </div>
        <div className="control">
          <div className="select has-icons-left">
            <select onChange={event => setRost(event.target.value)}>
              <option value={''}>Ej Röstfilter.</option>
              <option value="Ja">Ja</option>
              <option value="Nej">Nej</option>
              <option value="Avstår">Avstår</option>
              <option value="Frånvarande">Frånvarande</option>
            </select>
          </div>
        </div>
      </div>
      <VoteringFullView input={votering} filterNamn={filterState}
                        filterRost={filterRost}/>
      <div className="box" style={noBoxShadow}>
        {emptySubmit &&
        <p className="subtitle is-4 has-text-danger has-text-centered">Inga
          sökresultat </p>}
      </div>
      <Modal
        disconnected={disconnected}
        setDisconnected={setDisconnected}/>
    </div>
  );
};

const InputComponent = (props: {
  submitSearch: Function,
  voteringIdParam: string,
  loading: boolean
}) => {
  const [input, setInput] = useState<string>(props.voteringIdParam);
  const { submitSearch, voteringIdParam, loading } = props;
  useEffect(() => {
    voteringIdParam && setInput(voteringIdParam)
  }, [voteringIdParam]);

  const inputHandler = (inputString: string) => {
    setInput(inputString)
  };

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    submitSearch(input);
  };
  return (
    <>
      <form className="field has-addons" onSubmit={(e) => submitHandler(e)}>
        <div className="control is-expanded has-icons-left">
          <input className="input" type="text"
                 value={input || ""}
                 id="votering-id-input"
                 placeholder="Skriv 'votering_id' för voteringen här"
                 onChange={(event) => {
                   inputHandler(event.target.value)
                 }}/>
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faSearch}/>
          </span>
        </div>
        <div className="control">
          <button
            className={loading ? "button is-info is-loading" : "button is-info"}>
            Sök
          </button>
        </div>
      </form>

    </>
  )
};
