import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import {PANTONE_COOL_GRAY_9} from "../../CustomTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: PANTONE_COOL_GRAY_9,
    },
    subCheckbox: {
      marginLeft: 15
    },
  }),
);
