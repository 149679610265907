import {makeStyles, Theme} from '@material-ui/core/styles';
import {customTheme} from "../../CustomTheme";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  alert: {
    backgroundColor: customTheme.palette.secondary.main,
  },
}));
