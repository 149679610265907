import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import copy from 'clipboard-copy';

const SHOW_LINK_ICON: JSX.Element = (<FontAwesomeIcon icon={faLink}/>);
const LINK_COPIED_TEXT: JSX.Element = (<p>Länk kopierad!</p>);
const SHOW_TEXT_FOR = 5000;

/**
 * A simple button that when clicked, shares the URL.
 * If there is no parameters on the URL, only base is copied.
 * @param props.className override the classname of the button.
 * @param props.hidden if set to 'true' button is hidden.
 */
export const ClipboardButton = (props: { className?: string, hidden?: boolean }) => {
  const [buttonText, setButtonText] = useState<JSX.Element>(SHOW_LINK_ICON);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function getCurrentUrl() {
    return window.location.toString();
  }

  function copyToClipboard() {
    setIsLoading(true);
    copy(getCurrentUrl())
      .then(
        () => {
          setButtonText(LINK_COPIED_TEXT);
          setIsLoading(false);
          setTimeout(() => {
            setButtonText(SHOW_LINK_ICON);
          }, SHOW_TEXT_FOR);
        }
      )
  }

  if (props.hidden) {
    return (<></>);
  }

  return (
    <>
      <button
        className={props.className ? props.className + (isLoading ? ' is-loading' : '')
          : 'button is-link is-pulled-right is-outlined' + (isLoading ? ' is-loading' : '')}
        onClick={event => {
          event.preventDefault();
          copyToClipboard()
        }}>
        {buttonText}
      </button>
    </>
  )
};
