import { Anforande } from "../../model/ApiResponse";
import { AnforandeSearchResponse } from "../../search/SearchInterface"
import { ExportParams } from '../../model/AnforandeExportModal'

async function ExportAnforande(
  exportParams: ExportParams,
  setLoading: (loading: boolean) => void,
  checkedProps: Array<string>,
  addDownloaded: (num: number) => void
): Promise<Array<Anforande>> {
  setLoading(true);
  const stringArray = await fetchIdArray(exportParams, 1);
  const batchSize = 100;
  const allElements: Array<Anforande> = [];
  for (let index = 0; index < stringArray.length; index += batchSize) {
    const part = await fetchBatchedPart(stringArray.slice(index, index + batchSize), checkedProps);
    allElements.push(...part);
    addDownloaded(part.length)
  }
  setLoading(false);
  addDownloaded(-1);
  return allElements;
}

interface BatchAnswer {
  anforanden: Array<Anforande>
}

async function fetchBatchedPart(
  someIdsToFetch: Array<string>,
  checkedProps: Array<string>
): Promise<Anforande[]> {
  let jsonAnswer: BatchAnswer;
  let response: Response | undefined;
  const urlParam = new URLSearchParams();
  urlParam.append('uuidList', someIdsToFetch.join(','));
  urlParam.append('include', checkedProps.join(','));
  response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/anforande?${urlParam}`);
  if (!response.ok) {
    throw Error("Retries not implemented, throwing error as could not fetch documents.")
  }
  jsonAnswer = await response.json();
  return jsonAnswer.anforanden;
}

async function fetchIdArray(
  exportParams: ExportParams,
  page: number,
): Promise<Array<string>> {
  const { queryParam, partier, gteDate, lteDate, talare } = exportParams;
  let jsonAnswer: AnforandeSearchResponse;
  const params = new URLSearchParams();
  let response: Response | undefined;
  params.append('query', queryParam);
  talare && params.append('speaker', talare);
  partier && params.append('parties', partier.join(','));
  gteDate && params.append('gteDate', gteDate);
  lteDate && params.append('lteDate', lteDate);
  page && params.append('page', page.toString());
  params.append('pageSize', '100');
  response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/anforande/search?${params}`);
  jsonAnswer = await response.json();
  if (jsonAnswer.paging.of === page) {
    // Ok found nexit
    return jsonAnswer.anforanden.map(it => it.anforande_id);
  } else {
    const array = jsonAnswer.anforanden.map(it => it.anforande_id);
    array.push(
      ...await fetchIdArray(
        exportParams,
        page + 1
      ));
    return array
  }
}


export default ExportAnforande;
