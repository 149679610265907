import './css/App.css';
import './sass/bulma-settings.scss'
import {Link, Redirect, Route, Switch} from "react-router-dom";

import { AnforandeAnalysis } from "./anforande/AnforandeAnalysis";
import { AnforandeSearchPage } from "./search/AnforandeSearchPage";
import { AnforandeDistribution } from './anforande/AnforandeDistribution';
import { AnforandeTab } from "./anforande/AnforandeTab";
import { DokumentAnalysis } from './dokument/DokumentAnalysis';
import { DokumentSearchPage } from './search/DokumentSearchPage'
import { DokumentTab } from "./dokument/GetDokument";
import { PersonSearchTab } from "./intressent/PersonSearchTab";
import { PersonTab } from "./intressent/PersonTab";
import React, {useState} from 'react';
import { VoteringAnalysis } from "./votering/VoteringAnalysis";
import { VoteringTab } from "./votering/VoteringTab";
import orebroLogo from './Assets/Img/orebroLogo.svg'
import HomeView from "./View/Home/HomeView";
import AboutView from "./View/About/AboutView";
import TvakammarriksdagenSearchView
  from "./View/Tvakammarriksdagen/SearchView/SearchView";
import TvakammarriksdagenDocumentView
  from "./View/Tvakammarriksdagen/DocumentSearchView/DocumentSearchView";
import StandsriksdagenSearchView
  from "./View/Standsriksdagen/SearchView/SearchView";
import StandsriksdagenDocumentView
  from "./View/Standsriksdagen/DocumentSearchView/DocumentSearchView";
import CustomAppBar from "./Component/CustomAppBar/CustomAppBar";
import {customTheme} from "./CustomTheme";
import {ThemeProvider} from "@material-ui/core/styles";


export enum Path {
  // Home
  HOME = "/home",

  // About the service
  ABOUT = "/about",

  // Standsriksdagen
  STANDSRIKSDAGEN_SEARCH = "/standsriksdagen/search",
  STANDSRIKSDAGEN_DOCUMENT = "/standsriksdagen/document",

  // Tvakammarriksdagen
  TVAKAMMARRIKSDAGEN_SEARCH = "/tvakammarriksdagen/search",
  TVAKAMMARRIKSDAGEN_DOCUMENT = "/tvakammarriksdagen/document",

  // Enkammarriksdagen
  ENKAMMARRIKSDAGEN = "/enkammarriksdagen",
}

enum ActiveTab {
  DOKUMENT,
  ANALYZE_DOKUMENT,
  SEARCH_DOKUMENT,
  SEARCH_ANFORANDE,
  ANALYZE,
  ANALYZE_ANFORANDE,
  DISTRIBUTION_ANFORANDE,
  ANFORANDE,
  VOTERING,
  VOTERING_STATS,
  PERSON,
  PERSON_SEARCH
}

const centralizeEverything = false;

const App: React.FC = () => {
  const [pageTitle, setPageTitle] = useState<string>("")
  return (
    <>
      <div className="pageWrapper">
        <ThemeProvider theme={customTheme}>
          <CustomAppBar pageTitle={pageTitle}/>
          <div className={centralizeEverything ? 'App' : ''}>
            <Switch>

              {/* HomeView */}
              <Route exact path="/">
                <Redirect to={Path.HOME}/>
              </Route>
              <Route exact path={Path.HOME}>
                <HomeView/>
              </Route>

              {/* AboutView */}
              <Route exact path={Path.ABOUT}>
                <AboutView/>
              </Route>

              {/* Standsriksdagen */}
              <Route exact path={Path.STANDSRIKSDAGEN_SEARCH}>
                <StandsriksdagenSearchView setPageTitle={setPageTitle}/>
              </Route>
              <Route exact path={Path.STANDSRIKSDAGEN_DOCUMENT}>
                <StandsriksdagenDocumentView setPageTitle={setPageTitle}/>
              </Route>

              {/* Tvakammarriksdagen */}
              <Route exact path={Path.TVAKAMMARRIKSDAGEN_SEARCH}>
                <TvakammarriksdagenSearchView setPageTitle={setPageTitle}/>
              </Route>
              <Route exact path={Path.TVAKAMMARRIKSDAGEN_DOCUMENT}>
                <TvakammarriksdagenDocumentView setPageTitle={setPageTitle}/>
              </Route>

              {/* Enkammarriksdagen */}
              <Route exact path={Path.ENKAMMARRIKSDAGEN}>
                <Redirect to={Path.ENKAMMARRIKSDAGEN + "/search/dokument"}/>
              </Route>

              <Route exact path={Path.ENKAMMARRIKSDAGEN + "/search/dokument"}>
                <Tab tab={ActiveTab.SEARCH_DOKUMENT}/>
                <DokumentSearchPage setPageTitle={setPageTitle}/>
              </Route>
              <Route exact path={Path.ENKAMMARRIKSDAGEN + "/dokument"}>
                <Tab tab={ActiveTab.DOKUMENT}/>
                <DokumentTab setPageTitle={setPageTitle}/>
              </Route>
              <Route exact path={Path.ENKAMMARRIKSDAGEN + "/analyse/dokument"}>
                <Tab tab={ActiveTab.ANALYZE_DOKUMENT}/>
                <DokumentAnalysis setPageTitle={setPageTitle}/>
              </Route>
              <Route exact path={Path.ENKAMMARRIKSDAGEN + "/search/anforande"}>
                <Tab tab={ActiveTab.SEARCH_ANFORANDE}/>
                <AnforandeSearchPage setPageTitle={setPageTitle}/>
              </Route>
              <Route exact path={Path.ENKAMMARRIKSDAGEN + "/analyse/anforande"}>
                <Tab tab={ActiveTab.ANALYZE_ANFORANDE}/>
                <AnforandeAnalysis setPageTitle={setPageTitle}/>
              </Route>
              <Route exact path={Path.ENKAMMARRIKSDAGEN + "/analyse/anforandefordelning"}>
                <Tab tab={ActiveTab.DISTRIBUTION_ANFORANDE} />
                <AnforandeDistribution />
              </Route>
              <Route exact path={Path.ENKAMMARRIKSDAGEN + "/anforande"}>
                <Tab tab={ActiveTab.ANFORANDE}/>
                <AnforandeTab setPageTitle={setPageTitle}/>
              </Route>
              <Route exact path={Path.ENKAMMARRIKSDAGEN + "/votering"}>
                <Tab tab={ActiveTab.VOTERING}/>
                <VoteringTab setPageTitle={setPageTitle}/>
              </Route>
              <Route exact path={Path.ENKAMMARRIKSDAGEN + "/voteringStats"}>
                <Tab tab={ActiveTab.VOTERING_STATS}/>
                <VoteringAnalysis setPageTitle={setPageTitle}/>
              </Route>
              <Route path={Path.ENKAMMARRIKSDAGEN + "/person/search"}>
                <Tab tab={ActiveTab.PERSON_SEARCH}/>
                <PersonSearchTab setPageTitle={setPageTitle}/>
              </Route>
              <Route path={Path.ENKAMMARRIKSDAGEN + "/person"}>
                <Tab tab={ActiveTab.PERSON}/>
                <PersonTab setPageTitle={setPageTitle}/>
              </Route>
            </Switch>
          </div>
          <Footer/>
        </ThemeProvider>
      </div>
    </>
  );
};

const Footer = () => {
  return (
    <footer className="customFooter">
      <div className="content has-text-centered ">
        <a href="https://www.oru.se/">
          <img src={orebroLogo} className="oerebroLogo"
               alt="Logo, Örebro Universitet"/>
        </a>
        <p className="oerebroFooterText">
          <strong>Riksdagsdata</strong> - 701 82 Örebro, 019-30 30 00
        </p>
      </div>
    </footer>
  )
};

function Tab(props: { tab: ActiveTab }) {
  return activeMeTab(props.tab);
}

function clearStorage() {
  window.sessionStorage.clear();
}

function activeMeTab(active: ActiveTab) {
  return (
    <>
      {/* heading tabs  */}
      <div className="tabs is-fullwidth is-medium">
        <ul onClick={() => clearStorage()}>
          <li className={
            (ActiveTab.SEARCH_DOKUMENT === active) || (ActiveTab.DOKUMENT === active) || (ActiveTab.ANALYZE_DOKUMENT === active)
              ? 'is-active' : 'not-active'}>
            <Link
              to={Path.ENKAMMARRIKSDAGEN + "/search/dokument"}>Dokument</Link>
          </li>
          <li className={
            (ActiveTab.SEARCH_ANFORANDE === active) || (ActiveTab.ANALYZE_ANFORANDE === active) || (ActiveTab.ANFORANDE === active) || (ActiveTab.DISTRIBUTION_ANFORANDE === active)
              ? 'is-active' : 'not-active'}>
            <Link
              to={Path.ENKAMMARRIKSDAGEN + "/search/anforande"}>Anförande</Link>
          </li>
          <li className={
            (ActiveTab.VOTERING === active) || (ActiveTab.VOTERING_STATS === active)
              ? 'is-active' : 'not-active'}>
            <Link to={Path.ENKAMMARRIKSDAGEN + "/votering"}>Votering</Link>
          </li>
          <li className={
            (ActiveTab.PERSON === active || ActiveTab.PERSON_SEARCH === active)
              ? 'is-active' : 'not-active'}>
            <Link
              to={Path.ENKAMMARRIKSDAGEN + "/person/search"}>Intressent</Link>
          </li>
        </ul>
      </div>
      {/* nested tabs */}
      <div className="tabs is-boxed">
        {
          ((active === ActiveTab.SEARCH_DOKUMENT) || (active === ActiveTab.ANALYZE_DOKUMENT) || (active === ActiveTab.DOKUMENT)) &&
          <ul>
            <li
              className={ActiveTab.SEARCH_DOKUMENT === active ? 'is-active' : 'not-active'}>
              <Link to={Path.ENKAMMARRIKSDAGEN + "/search/dokument"}>Sök
                Dokument</Link>
            </li>
            <li
              className={ActiveTab.DOKUMENT === active ? 'is-active' : 'not-active'}>
              <Link to={Path.ENKAMMARRIKSDAGEN + "/dokument"}>Dokument-ID</Link>
            </li>
            <li
              className={ActiveTab.ANALYZE_DOKUMENT === active ? 'is-active' : 'not-active'}>
              <Link
                to={Path.ENKAMMARRIKSDAGEN + "/analyse/dokument"}>Dokumentanalys</Link>
            </li>
          </ul>
        }
        {
          ((active === ActiveTab.SEARCH_ANFORANDE) || (active === ActiveTab.ANALYZE_ANFORANDE) || (ActiveTab.ANFORANDE === active) || (ActiveTab.DISTRIBUTION_ANFORANDE === active)) &&
          <ul>
            <li
              className={ActiveTab.SEARCH_ANFORANDE === active ? 'is-active' : 'not-active'}>
              <Link to={Path.ENKAMMARRIKSDAGEN + "/search/anforande"}>Sök
                Anförande</Link>
            </li>
            <li
              className={ActiveTab.ANFORANDE === active ? 'is-active' : 'not-active'}>
              <Link
                to={Path.ENKAMMARRIKSDAGEN + "/anforande"}>Anförande-ID</Link>
            </li>
            <li
              className={ActiveTab.ANALYZE_ANFORANDE === active ? 'is-active' : 'not-active'}>
              <Link
                to={Path.ENKAMMARRIKSDAGEN + "/analyse/anforande"}>Anförandeanalys</Link>
            </li>
            <li
              className={ActiveTab.DISTRIBUTION_ANFORANDE === active ? 'is-active' : 'not-active'}>
              <Link to={Path.ENKAMMARRIKSDAGEN + "/analyse/anforandefordelning"}>Anförandefördelning</Link>
            </li>
          </ul>
        }
        {((ActiveTab.VOTERING === active) || (ActiveTab.VOTERING_STATS === active)) &&
        <ul>
          <li
            className={ActiveTab.VOTERING === active ? 'is-active' : 'not-active'}>
            <Link to={Path.ENKAMMARRIKSDAGEN + "/votering"}>Votering-ID</Link>
          </li>
          <li
            className={ActiveTab.VOTERING_STATS === active ? 'is-active' : 'not-active'}>
            <Link
              to={Path.ENKAMMARRIKSDAGEN + "/voteringStats"}>Voteringsfördelningar</Link>
          </li>
        </ul>
        }
        {((ActiveTab.PERSON_SEARCH === active) || (ActiveTab.PERSON === active)) &&
        <ul>
          <li
            className={ActiveTab.PERSON_SEARCH === active ? 'is-active' : 'not-active'}>
            <Link to={Path.ENKAMMARRIKSDAGEN + "/person/search"}>Sök
              Intressent</Link>
          </li>
          <li
            className={ActiveTab.PERSON === active ? 'is-active' : 'not-active'}>
            <Link to={Path.ENKAMMARRIKSDAGEN + "/person"}>Intressent-Id</Link>
          </li>
        </ul>
        }
      </div>
    </>
  )
}

export default App;
