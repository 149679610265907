import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import React from 'react';

export enum Variant {
  REGULAR,
  ANALYSIS,
}

interface Texts {
  title: string;
  subtitle: string
  body: string[];
}

interface Props {
  isVisible: boolean;
  variant: Variant;
  setIsVisible: (isVisible: boolean) => void;
}

const getTexts = (variant: Variant): Texts => {
  switch (variant) {
    case Variant.REGULAR:
      return {
        title: "Sökverktyg",
        subtitle: "Sökfältet stödjer följande tecken:",
        body: [
          "+ signifierar OCH, Exempel: skola + högskola",
          "| signifierar ELLER. Exempel: skola | högskola",
          "- utesluter termen/frasen efter tecknet. Exempel: -skola högskola",
          "\" används för att skapa en fras. Exempel: \"karta över campus\"",
          "* i slutet av en term signifierar att termen är ett prefix. Exempel: hög*",
          "( och ) signifierar prioritet. Exempel: (skola) högskola",
          "~N efter en term signifierar hur många tecken som kan bytas ut/tas bort och ändå få träff. Exempel: utveckling~2",
          "~N efter en fras signifierar tillåtna förändringsgraden i frasen. Exempel: \"lärande mål\"~2",
        ],
      };
    case Variant.ANALYSIS:
      return {
        title: "Sökverktyg",
        subtitle: "Sökfältet stödjer följande tecken:",
        body: [
          ": används för att göra parallella sökningar. Exempel: demokrati:diktatur",
          "+ signifierar OCH. Exempel: skola + högskola",
          "| signifierar ELLER. Exempel: skola | högskola",
          "- utesluter termen/frasen efter tecknet. Exempel: -skola högskola",
          "\" används för att skapa en fras. Exempel: \"karta över campus\"",
          "* i slutet av en term signifierar att termen är ett prefix. Exempel: hög*",
          "( och ) signifierar prioritet. Exempel: (skola) högskola",
          "~N efter en term signifierar hur många tecken som kan bytas ut/tas bort och ändå få träff. Exempel: utveckling~2",
          "~N efter en fras signifierar tillåtna förändringsgraden i frasen. Exempel: \"lärande mål\"~2",
        ],
      }
  }
};

export const SearchLegendModal = (props: Props) => {
  if (!props.isVisible) {
    return null
  }

  const texts = getTexts(props.variant);
  return (
    <div style={{
      position: "absolute",
      zIndex: 5,
      backgroundColor: "#ffffc9",
      width: '65%',
      maxWidth: 900,
      minWidth: 300,
      marginTop: 50,
      lineHeight: 2,
      right: 0,
      padding: 20,
      borderRadius: 15,
      boxShadow: "5px 5px 5px #aaaaaa"
    }}>
      <h1 className="title is-4">{texts.title}
      <span className="icon is-small"
        style={{position: "absolute", marginRight: 20, right: 0, pointerEvents: "initial", cursor: "pointer" }}
        onClick={() => props.setIsVisible(!props.isVisible)}
      >
        <FontAwesomeIcon icon={faTimesCircle} />
      </span>
      </h1>
      <h2 className="title is-6" style={{textDecoration: "underline"}}>{texts.subtitle}</h2>
      <ul>
        {texts.body.map((listItemText, index) => <li key={index} style={{borderBottom: "1px #000 solid"}}>{listItemText}</li>)}
      </ul>
    </div>
  );
};


