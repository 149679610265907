import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParam
} from "use-query-params";
import {
  errorDecider,
  Paginator,
  SearchResultDateSorting,
  StartStopDatePicker
} from "../model/Helpers";
import React, {ChangeEvent, useEffect, useState} from "react";

import {ClipboardButton} from "../model/ClipboardService";
import {DokumentExportModal} from "../model/DokumentExportModal";
import {DokumentPageView} from "./SearchPageView";
import {DokumentProperties} from "../dokument/DokumentProperties";
import {DokumentSearchResponse} from "./SearchInterface";
import {DokumentTyperList} from "../model/DokumentTyper";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Modal} from "../model/Modal";
import {faQuestion, faSearch} from '@fortawesome/free-solid-svg-icons'
import {noBoxShadow} from "../CssConstants";
import {SearchLegendModal, Variant} from "../model/SearchLegendModal";

const PAGE_TITLE = "Enkammarriksdagen";
const STORED_QUERY = "dok-query";

interface Props {
  setPageTitle: (pageTitle: string) => void;
}

// import {ExportModal} from '../model/ExportModal'
interface StoredParams {
  queryParam?: string,
  types?: Array<string>,
  gteDate?: string,
  lteDate?: string,
  sortType?: string,
  page: number,
  selectedProperties: Array<string>
}

const EMPTY_ARRAY: Array<string> = [];

const EMPTY_RESULT = {
  paging: { hits: '0', of: 0, page: 1 },
  dokument: []
} as DokumentSearchResponse;

/**
 * TODO, should search for dokuments, anforanden or both! (with advanced options).
 * @constructor
 */
export const DokumentSearchPage = (props: Props) => {
  // const storedTypesParam:Array<string> = JSON.parse(localStorage.getItem(STORED_TYPES)) || emptyArray;
  // TODO: Use queries more, could be used instead of state? Works better for linking pages!
  const [queryParam = "", setQueryParam] = useQueryParam('query', StringParam);
  const [types = EMPTY_ARRAY, setTypes] = useQueryParam('types', ArrayParam);
  const [gteDate = "", setGteDate] = useQueryParam('gteDate', StringParam);
  const [lteDate = "", setLteDate] = useQueryParam('lteDate', StringParam);
  const [sortType, setSortType] = useQueryParam("sortType", StringParam);
  const [page = 1, setPage] = useQueryParam('page', NumberParam);
  const [selectedProperties = EMPTY_ARRAY, setSelectedProperties] = useQueryParam('property', ArrayParam);
  const [searchResult, setSearchResult] = useState<DokumentSearchResponse>(EMPTY_RESULT);
  const [disconnected, setDisconnected] = useState<boolean>(false);
  const [emptySubmit, setEmptySubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  props.setPageTitle(PAGE_TITLE);

  // TODO: Investigate the missing dependency waring
  // mountEffect
  useEffect(() => {
    const storedValues = sessionStorage.getItem(STORED_QUERY);
    if (storedValues) {
      const { queryParam, types, gteDate, lteDate, sortType, page, selectedProperties }: StoredParams = JSON.parse(storedValues);
      setQueryParam(queryParam);
      setTypes(types);
      setGteDate(gteDate);
      setLteDate(lteDate);
      setPage(page);
      setSortType(sortType);
      setSelectedProperties(selectedProperties)
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // search Effect
  useEffect(() => {
    setEmptySubmit(false);
    if (queryParam === undefined || queryParam.trim() === '') {
      setSearchResult(EMPTY_RESULT);
      return;
    }
    setStorage();
    doSearchAction(queryParam, page)
      .then(setSearchResult)
      .catch(errorHandler)

  }, [queryParam, page, types, gteDate, lteDate, sortType, selectedProperties]); // eslint-disable-line react-hooks/exhaustive-deps

  function setStorage() {
    const storedObject: StoredParams = {
      queryParam,
      types,
      gteDate,
      lteDate,
      sortType,
      page,
      selectedProperties
    };
    sessionStorage.setItem(STORED_QUERY, JSON.stringify(storedObject))
  }

  async function doSearchAction(query: string, page?: number): Promise<DokumentSearchResponse> {
    setLoading(true);
    let jsonAnswer: DokumentSearchResponse;
    const params = new URLSearchParams();
    let response: Response | undefined;
    params.append('query', query);
    page && params.append('page', page.toString());
    types && params.append('types', types.join(','));
    gteDate && params.append('gteDate', gteDate);
    lteDate && params.append('lteDate', lteDate);
    sortType && params.append('sortType', sortType);
    selectedProperties && params.append('hasProperty', selectedProperties.join(','));
    try {
      response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/dokument/search?${params}`);
    } finally {
      setLoading(false);
      errorDecider(response);
    }
    jsonAnswer = await response.json();
    if (jsonAnswer.dokument.length === 0) {
      let error = new Error(`The document [${query}] does not exist!`);
      error.name = "EmptyError";
      throw error;
    }

    return jsonAnswer;
  }

  function errorHandler(e: Error) {
    switch (e.name) {
      case "EmptyError":
        setEmptySubmit(true);
        setSearchResult(EMPTY_RESULT);
        break;
      case "ConnectionError":
        setDisconnected(true);
        break;
    }
  }

  // search from FormInputField
  function doSearchEventHandler(query: string) {
    setPage(1);
    setQueryParam(query, "pushIn");
  }

  function openExportHandler() {
    setOpenExportModal(true)
  }

  return (
    <div>
      <FormInputField
        queryParam={queryParam}
        doSearch={doSearchEventHandler}
        searchResult={searchResult}
        loading={loading}
        openExportHandler={openExportHandler}
      />
      <StartStopDatePicker
        setStartString={setGteDate}
        setStopString={setLteDate}
        start={gteDate}
        stop={lteDate} />
      <SearchResultDateSorting setSortType={setSortType} />
      <DokumentProperties setProperties={setSelectedProperties}
        selectedProps={selectedProperties} />
      <DokumentTyperList alreadySelectedTypes={types} setFunction={setTypes} />
      <Paginator
        paginator={searchResult.paging}
        doSearch={
          (newPage: number) => {
            setPage(newPage, "pushIn")
            // doSearchAction(queryParam, newPage).then(setSearchResult)
          }} />
      <DokumentPageView input={searchResult} />
      {(searchResult.dokument.length > 5) &&
        <Paginator
          paginator={searchResult.paging}
          doSearch={
            (newPage: number) => {
              setPage(newPage, "pushIn")
              // doSearchAction(queryParam, newPage).then(setSearchResult)
            }} />
      }
      <div className="box" style={noBoxShadow}>
        {emptySubmit &&
          <p className="subtitle is-4 has-text-danger has-text-centered ">Inga
          sökresultat </p>}
      </div>
      <Modal
        disconnected={disconnected}
        setDisconnected={setDisconnected} />
      <DokumentExportModal
        openExportModal={openExportModal}
        setOpenExportModal={setOpenExportModal}
        exportParams={{
          queryParam,
          types,
          gteDate,
          lteDate,
          selectedProperties
        }}
        numberOfResults={searchResult.paging.hits.replace('hits', '')}

      />
    </div>
  );
};

const FormInputField = (props: {
  queryParam: string,
  searchResult: DokumentSearchResponse,
  doSearch: (query: string) => void,
  loading: boolean,
  openExportHandler: () => void
}) => {
  const { queryParam, searchResult, doSearch, loading, openExportHandler } = props;
  // This owns it own internal query object, as we don't want to rerender the world on every updated letter.
  const [query, setQuery] = useState<string>(queryParam ? queryParam : '');
  const [showSearchLegend, setShowSearchLegend] = useState<boolean>(false);

  // Effect that updates input string if user backed or come from copied link
  useEffect(() => {
    setQuery(props.queryParam)
  }, [props.queryParam]);

  function doSearchEventHandler(event: React.FormEvent) {
    event.preventDefault();
    doSearch(query)
  }

  function textChanged(event: ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value);
  }

  return (
    <>
      <form onSubmit={doSearchEventHandler}>
        <SearchLegendModal isVisible={showSearchLegend} setIsVisible={setShowSearchLegend} variant={Variant.REGULAR}/>
        <div className="field has-addons">
          <p className="control is-expanded has-icons-left">
            {/* have id to make browser remember form */}
            <input className="input" type="text"
              value={query || ""}
              placeholder="Skriv din sökning här"
              onChange={textChanged} />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faSearch} />
            </span>
            <span className="icon is-small"
                  style={{right: 0, pointerEvents: "initial", cursor: "pointer"}}
                  onClick={() => setShowSearchLegend(!showSearchLegend)}
            >
            <FontAwesomeIcon icon={faQuestion} />
          </span>
          </p>
          <div className="control">
            <button
              className={loading ? "button is-info is-loading" : "button is-info"}>
              Sök
            </button>
          </div>
          <p className="control">
            <span
              className={searchResult.paging && searchResult.paging.hits !== '0' ? "button is-static" : 'is-hidden'}>
              {searchResult.paging.hits.replace('hits', 'träffar')}
            </span>
            <button
              onClick={() => openExportHandler()}
              className={searchResult.dokument.length !== 0 ? 'button is-centered is-pulled-right is-danger is-outlined' : 'is-hidden'}>
              Exportera
            </button>
          </p>
          <ClipboardButton hidden={searchResult.dokument.length === 0} />
        </div>
      </form>

    </>
  )
};
