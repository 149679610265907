import React from "react";
import {
  StandsriksdagenMeta,
  TvakammarriksdagenMeta
} from "../../model/ApiResponse";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCalendar,
  faIdBadge,
  faSort
} from "@fortawesome/free-solid-svg-icons";
import {CollapsibleCard, TitleCase} from "../../model/Helpers";
import {pagingMargin} from "../../CssConstants";

interface Props {
  data: StandsriksdagenMeta | TvakammarriksdagenMeta
}

const DocumentView = (props: Props) => {
  const {data} = props;

  const getYear = (): string => {
    if (data.years?.from && data.years?.from) {
      return data.years.from === data.years.to ?
        data.years.from : `${data.years.from}-${data.years.to}`;
    }

    return 'trasigt värde'
  }

  return (
    <div style={pagingMargin}>
      <article className="tile is-child notification">
        <div className="content">
          <p className="title"><FontAwesomeIcon
            icon={faBook}/> {data.title ? TitleCase(data.title) : 'Ingen Titel'}
          </p>
          <div className="content">
            <p><FontAwesomeIcon icon={faCalendar} />
              År: {getYear()}
            </p>
            <p>
              <FontAwesomeIcon icon={faIdBadge}/>
              Fil: {data.filnamn}
            </p>
            <p>
              <FontAwesomeIcon icon={faSort}/>
              Typ: {data.doktyp ? data.doktyp : 'trasigt värde:'}
            </p>
          </div>
        </div>
      </article>
      <CollapsibleCard title={'Fulltext'} initalState={true}>
        <blockquote>{data.dokumenttext}</blockquote>
      </CollapsibleCard>
    </div>
  )
};

export default DocumentView;
