import * as React from 'react';
import { useEffect } from 'react';

export const Modal = (props: {disconnected:boolean, setDisconnected:(state: boolean) => void }) => {

    useEffect(() => {
        const handleEsc = (event: any) => {
            if (event.keyCode === 27) {
                props.setDisconnected(false);
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    });

    return (
        <div>
            <div className={props.disconnected ? 'modal is-active is-clipped' : 'is-hidden'}>
                <div className="modal-background" onClick={() => props.setDisconnected(false)}></div>
                <div className="modal-content">
                    <article className="message is-danger">
                        <div className="message-header ">
                            <p>Anslutningsproblem</p>
                            <button className="delete is-medium" aria-label="delete" onClick={() => props.setDisconnected(false)}></button>
                        </div>
                        <div className="message-body">
                            Ingen anslutning med databasen, uppdatera sidan och prova igen.
                        </div>
                    </article>
                </div>
            </div>
        </div>
    );
};
