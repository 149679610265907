import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParam
} from "use-query-params";
import {
  CollapsibleCard,
  errorDecider,
  Paginator,
  SearchResultDateSorting,
  StartStopDatePicker
} from "../model/Helpers";
import React, {ChangeEvent, useEffect, useState} from "react";

import {AnforandeExportModal} from "../model/AnforandeExportModal";
import {AnforandePageView} from "./SearchPageView";
import {AnforandeSearchResponse} from "./SearchInterface";
import {ClipboardButton} from "../model/ClipboardService";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Modal} from "../model/Modal";
import {PartierList} from "../model/Parties";
import {PersonSearch} from "../model/PersonFindAsYouType";
import {faQuestion, faSearch} from '@fortawesome/free-solid-svg-icons'
import {noBoxShadow} from "../CssConstants";
import {SearchLegendModal, Variant} from "../model/SearchLegendModal";

const PAGE_TITLE = "Enkammarriksdagen";
const STORED_QUERY = "anf-query";

interface StoredParams {
  queryParam?: string,
  partier?: Array<string>,
  gteDate?: string,
  lteDate?: string,
  page: number,
  talare?: string,
  sortType?: string
}

interface Props {
  setPageTitle: (pageTitle: string) => void;
}

const EMPTY_ARRAY: string[] = [];

const EMPTY_RESULT = {
  paging: { hits: '0', of: 0, page: 1 },
  anforanden: []
} as AnforandeSearchResponse;

/**
 * TODO, should search for dokuments, anforanden or both! (with advanced options).
 * @constructor
 */
export const AnforandeSearchPage = (props: Props) => {
  const [queryParam = "", setQueryParam] = useQueryParam('query', StringParam);
  const [chartParam = 0] = useQueryParam('fromChart', NumberParam);
  const [partier = EMPTY_ARRAY, setPartier] = useQueryParam('partier', ArrayParam);
  const [talare = "", setTalare] = useQueryParam('talare', StringParam);
  const [gteDate = "", setGteDate] = useQueryParam('gteDate', StringParam);
  const [lteDate = "", setLteDate] = useQueryParam('lteDate', StringParam);
  const [page = 1, setPage] = useQueryParam('page', NumberParam);
  const [sortType, setSortType] = useQueryParam("sortType", StringParam);
  const [emptySubmit, setEmptySubmit] = useState<boolean>(false);
  const [disconnected, setDisconnected] = useState<boolean>(false);
  const [advancedCollapse, setAdvanceCollapse] = useState<boolean>(!gteDate && !lteDate && partier.length === 0);
  const [searchResult, setSearchResult] = useState<AnforandeSearchResponse>(EMPTY_RESULT);
  const [loading, setLoading] = useState<boolean>(false);
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);

  props.setPageTitle(PAGE_TITLE);

  useEffect(() => {
    if (chartParam !== 1) {
      const storedValues = sessionStorage.getItem(STORED_QUERY);
      if (storedValues) {
        const { queryParam, partier, gteDate, lteDate, page, talare, sortType }: StoredParams = JSON.parse(storedValues);
        setQueryParam(queryParam);
        setPartier(partier);
        setGteDate(gteDate);
        setLteDate(lteDate);
        setTalare(talare);
        setPage(page);
        setSortType(sortType);
        (gteDate || lteDate || partier) ? setAdvanceCollapse(false) : setAdvanceCollapse(true)
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setEmptySubmit(false);
    if (queryParam === undefined || queryParam.trim() === '') {
      setSearchResult(EMPTY_RESULT); // do nothing if no text
      return
    }
    setStorage();
    doSearchAction(queryParam, page)
      .then(setSearchResult)
      .catch(errorHandler)
  }, [queryParam, page, gteDate, lteDate, talare, partier, sortType]); // eslint-disable-line react-hooks/exhaustive-deps

  function setStorage() {
    const storedObject: StoredParams = {
      queryParam,
      partier,
      gteDate,
      lteDate,
      page,
      talare,
      sortType
    };
    sessionStorage.setItem(STORED_QUERY, JSON.stringify(storedObject))
  }

  async function doSearchAction(query: string, page?: number): Promise<AnforandeSearchResponse> {
    setLoading(true);
    let jsonAnswer: AnforandeSearchResponse;
    let response: Response | undefined;
    const params = new URLSearchParams();
    params.append('query', query);
    talare && params.append('speaker', talare);
    partier && params.append('parties', partier.join(','));
    gteDate && params.append('gteDate', gteDate);
    lteDate && params.append('lteDate', lteDate);
    sortType && params.append('sortType', sortType);
    page && params.append('page', page.toString());
    try {
      response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/anforande/search?${params}`);
    } finally {
      setLoading(false);
      errorDecider(response);
    }
    jsonAnswer = await response.json();
    if (jsonAnswer.anforanden.length === 0) {
      let error = new Error(`The document [${query}] does not exist!`);
      error.name = "EmptyError";
      throw error;
    }
    return jsonAnswer;
  }


  function errorHandler(e: Error) {
    switch (e.name) {
      case "EmptyError":
        setEmptySubmit(true);
        setSearchResult(EMPTY_RESULT);
        break;
      case "ConnectionError":
        setDisconnected(true);
        break;
    }
  }

  function renderTalare() {
    if (!talare) return (<></>);
    return (<span className="tag is-success">
      Talare: {talare}
      <button onClick={_ => setTalare(undefined)} className="delete is-small" />
    </span>);
  }

  function doSearchEventHandler(query: string) {
    setPage(1);
    setQueryParam(query, "pushIn")
  }

  function openExportHandler() {
    setOpenExportModal(true)
  }

  return (
    <div>
      <FormInput queryParam={queryParam}
        searchResult={searchResult}
        doSearch={doSearchEventHandler}
        loading={loading}
        openExportHandler={openExportHandler}
      />
      {renderTalare()}
      <CollapsibleCard title={'Avancerade Sökinställningar'}

        initalState={advancedCollapse}
      >
        <StartStopDatePicker start={gteDate} setStartString={setGteDate}
          stop={lteDate} setStopString={setLteDate} />
        <SearchResultDateSorting setSortType={setSortType} />
        <PartierList setFunction={setPartier} currentParties={partier} />
        <PersonSearch setFunction={setTalare} parties={partier}
          currentSpeaker={talare} />
      </CollapsibleCard>
      <Paginator
        paginator={searchResult.paging}
        doSearch={
          (newPage: number) => {
            setPage(newPage, "pushIn")
            // doSearchAction(queryParam, newPage).then(setSearchResult)
          }} />
      <AnforandePageView input={searchResult} />
      <Paginator
        paginator={searchResult.paging}
        doSearch={
          (newPage: number) => {
            setPage(newPage, "pushIn")
            // doSearchAction(queryParam, newPage).then(setSearchResult)
          }} />
      <div className="box" style={noBoxShadow}>
        {emptySubmit &&
          <p className="subtitle is-4 has-text-danger has-text-centered ">Inga
          sökresultat </p>}
      </div>
      <Modal
        disconnected={disconnected}
        setDisconnected={setDisconnected} />
      <AnforandeExportModal
        openExportModal={openExportModal}
        setOpenExportModal={setOpenExportModal}
        exportParams={{ queryParam, partier, gteDate, lteDate, talare }}
        numberOfResults={searchResult.paging.hits.replace('hits', '')}

      />
    </div>

  );
};

const FormInput = (props: {
  queryParam: string,
  searchResult: AnforandeSearchResponse,
  doSearch: (query: string) => void,
  loading: boolean,
  openExportHandler: () => void
}) => {
  const [showSearchLegend, setShowSearchLegend] = useState<boolean>(false);
  const { queryParam, searchResult, doSearch, loading, openExportHandler } = props;
  const [query, setQuery] = useState<string>(queryParam ? queryParam : '');
  useEffect(() => {
    props.queryParam ? setQuery(props.queryParam) : setQuery("")

  }, [props.queryParam]);

  function textChanged(event: ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value);
  }

  function doSearchEventHandler(event: React.FormEvent) {
    event.preventDefault();
    doSearch(query);
  }

  return (
    <form onSubmit={doSearchEventHandler}>
      <SearchLegendModal isVisible={showSearchLegend} setIsVisible={setShowSearchLegend} variant={Variant.REGULAR}/>
      <div className="field has-addons">
        <p className="control is-expanded has-icons-left">
          <input className="input" type="text"
            value={query}
            id="anforande-search-input"
            placeholder="Skriv din sökning här"
            onChange={textChanged} />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faSearch} />
          </span>
          <span className="icon is-small"
                style={{right: 0, pointerEvents: "initial", cursor: "pointer"}}
                onClick={() => setShowSearchLegend(!showSearchLegend)}
          >
            <FontAwesomeIcon icon={faQuestion} />
          </span>

        </p>
        <div className="control">
          <button
            className={loading ? "button is-info is-loading" : "button is-info"}>
            Sök
          </button>
        </div>
        <p className="control">
          <span
            className={searchResult.paging && searchResult.paging.hits !== '0' ? "button is-static" : 'is-hidden'}>
            {searchResult.paging.hits.replace('hits', 'träffar')}
          </span>
          <button
            onClick={openExportHandler}
            className={searchResult.paging.hits !== '0' ? 'button is-centered is-pulled-right is-danger is-outlined' : 'is-hidden'}>
            Exportera
          </button>
        </p>
        <ClipboardButton hidden={searchResult.anforanden.length === 0} />
      </div>
    </form>
  )
};
