import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {Path} from "../../../../App";
import {Link} from "react-router-dom";

interface Props {
  title: string;
  img: string;
  onClickGoTo: Path;
}

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    minWidth: 280,
    margin: 10,
  },
  media: {
    height: 205,
  },
  spacer: {
    height: 5,
  }
});


const CustomCard = (props: Props) => {
  const {title, img, onClickGoTo} = props
  const classes = useStyles();

  return (
    <Link to={onClickGoTo}>
      <Card className={classes.root}>
        <CardActionArea>
          <div className={classes.spacer}/>
          <CardMedia
            className={classes.media}
            image={img}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography align={"center"} gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}

export default CustomCard
