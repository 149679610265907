import { useEffect, useState } from "react";

const TYPE_CACHE_KEY = 'typer-cache';

export interface Typ {
  seriekod: string    //: "01",
  typ: string         //: "bet",
  subtyp?: string     //: null,
  doktyp: string      //: "bet",
  sortering: string   //: "1001",
  namn: string        //: "Betänkande",
  pluralnamn: string  //: "Betänkanden och utlåtanden",
  familj: string      //: "Utskotten och EU-nämnden",
  grupp: string       //: "dokument",
  beskrivning: string //: "Betänkanden är förslag från riksdagens utskott till riksdagen. Utlåtanden skriver utskotten över olika EU-förslag."
  // Added for sort
  count: number
}

export interface TyperResponse {
  typer: {
    typ: Typ[]
  }
}

export const useTyper = (): TyperResponse => {

  const [typer, setTyper] = useState<TyperResponse>({typer: {typ: []}});

  async function fetchTyper() {
    const typeCache = localStorage.getItem(TYPE_CACHE_KEY);
    if (typeCache) {
      setTyper(JSON.parse(typeCache));
      return;
    }

    let jsonAnswer: TyperResponse;
    try {
      let response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/dokument/types`);
      jsonAnswer = await response.json();
    } catch (e) {
      console.error(e);
      return;
    }
    setTyper(jsonAnswer);
    localStorage.setItem(TYPE_CACHE_KEY, JSON.stringify(jsonAnswer));
  }

  // Make sure to fetch them from cache or if missing API.
  useEffect(() => {
    (async () => {
      await fetchTyper()
    })()
  }, []);

  return (typer)
};
