import { DateTypes } from "../anforande/AnforandeAnalysis";
import { HistogramList } from "../model/AnalysisModel";
import moment from "moment";
import { ExtraVoteResult, VoteResult } from "./ApiResponse";

export const downloadExportHistogramAsJson = (filename: string, data: HistogramList, dateType?: DateTypes): void => {
    mapHistogramList(data, dateType)
    const type = `application/json`;
    const blob = new Blob([JSON.stringify(data.histogramList)], { type });
    const url = window.URL.createObjectURL(blob);
    const element = document.createElement('a');

    element.href = url;
    element.style.display = 'none';
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    window.URL.revokeObjectURL(url);

};


export const downloadExportVoteringAsJson = (filename: string, data: VoteResult | Array<ExtraVoteResult> | undefined): void => {
    const type = `application/json`;
    const blob = new Blob([JSON.stringify(data)], { type });
    const url = window.URL.createObjectURL(blob);
    const element = document.createElement('a');

    element.href = url;
    element.style.display = 'none';
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    window.URL.revokeObjectURL(url);
};


const mapHistogramList = (data: HistogramList, dateType?: DateTypes) => {
  data.histogramList.forEach(item => {
    item.date = dateType === "riksmote" ? item.date : moment(item.date).format(dateType === "year" ? "YYYY" : "YYYY-MM")
  })

}
