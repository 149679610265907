import { Bar, BarChart, Brush, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";

import { HistogramList } from "../model/AnalysisModel";
import React, { useState } from "react";
import moment from 'moment';
import useWindowDimensions from "../model/WindowDimensions";
import { DateTypes } from "./AnforandeAnalysis";
import AnalyzeExportModal from "../model/AnalyzeExportModal"
import {Path} from "../App";

interface Props {
  histogramList?: HistogramList;
  boxWidth: number;
  query: string;
  dateType?: DateTypes;
}

const addYear = (endDate: string): string => {
  return moment(endDate).add(1, 'y').format('YYYY-MM-DD');
};

const addMonth = (endDate: string): string => {
  return moment(endDate).add(1, 'M').format('YYYY-MM-DD');
};

export const AnforandeAnalysisView = (props: Props) => {
  const windowDimensions = useWindowDimensions();
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);

  if (props.histogramList === undefined || props.histogramList.histogramList === undefined) return (<></>);

  const newData = props.histogramList.histogramList.map(it => {
    const S = it.partyCountList.find(it => it.party.toUpperCase() === 'S');
    const V = it.partyCountList.find(it => it.party.toUpperCase() === 'V');
    const SD = it.partyCountList.find(it => it.party.toUpperCase() === 'SD');
    const KD = it.partyCountList.find(it => it.party.toUpperCase() === 'KD');
    const M = it.partyCountList.find(it => it.party.toUpperCase() === 'M');
    const L = it.partyCountList.find(it => it.party.toUpperCase() === 'L');
    const FP = it.partyCountList.find(it => it.party.toUpperCase() === 'FP');
    const C = it.partyCountList.find(it => it.party.toUpperCase() === 'C');
    const MP = it.partyCountList.find(it => it.party.toUpperCase() === 'MP');
    const date = props.dateType === "riksmote" ? it.date : moment(it.date).format(!props.dateType || props.dateType === "year" ? 'YYYY' : 'YYYY-MM');
    return {
      date: date,
      total: it.total,
      S: S ? S.count : undefined,
      V: V ? V.count : undefined,
      SD: SD ? SD.count : undefined,
      KD: KD ? KD.count : undefined,
      M: M ? M.count : undefined,
      L: L ? L.count : undefined,
      C: C ? C.count : undefined,
      MP: MP ? MP.count : undefined,
      FP: FP ? FP.count : undefined,
    }
  });

  return (
    <>
      <AnalyzeExportModal openExportModal={openExportModal} setOpenExportModal={setOpenExportModal} tableData={props.histogramList} dateType={props.dateType} />
      <div style={{ display: "flex" }}>
        <h1 className={'title'} style={{ marginBottom: 0, marginTop: 30, marginLeft: 20, marginRight: 20 }}>
          {props.histogramList.queryTerm}
        </h1>
        <button
          onClick={() => setOpenExportModal(true)}
          style={{ marginTop: 30 }}
          className={'button is-centered is-danger is-outlined'}>
          Exportera
        </button>
      </div>
      <BarChart
        width={props.boxWidth - 40}
        height={windowDimensions.height * 0.6}
        data={newData} // newData.slice(0, 50)
        margin={{
          top: 30, right: 30, left: 20, bottom: 5,
        }}

        onClick={(e: { activeLabel: string } | null) => {
          if (!e) return; // invalid event
          if (props.dateType === "year") {
            // Label will be something like 2014
            const url = (`${Path.ENKAMMARRIKSDAGEN}/search/anforande?gteDate=${e.activeLabel}&lteDate=${addYear(e.activeLabel)}&query=${props.query}&fromChart=1`);
            const win = window.open(url);
            win && win.focus();
          } else if (props.dateType === "month") {
            // Label will be something like 2012-03
            const url = (`${Path.ENKAMMARRIKSDAGEN}/search/anforande?gteDate=${e.activeLabel}-01&lteDate=${addMonth(e.activeLabel)}&query=${props.query}&fromChart=1`);
            const win = window.open(url);
            win && win.focus();
          }
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        // @ts-ignore broke in '@types/recharts 1.8.8'
        <XAxis dataKey="date" />
        // @ts-ignore broke in '@types/recharts 1.8.8'
        <YAxis />
        // @ts-ignore broke in '@types/recharts 1.8.8'
        <Tooltip />
        <Legend />
        <Brush dataKey="date" height={30} stroke="#8884d8" />
        <Bar dataKey="S" stackId="a" fill={getPartyColour("S")} />
        <Bar dataKey="V" stackId="a" fill={getPartyColour("V")} />
        <Bar dataKey="SD" stackId="a" fill={getPartyColour("SD")} />
        <Bar dataKey="KD" stackId="a" fill={getPartyColour("KD")} />
        <Bar dataKey="M" stackId="a" fill={getPartyColour("M")} />
        <Bar dataKey="L" stackId="a" fill={getPartyColour("L")} />
        <Bar dataKey="FP" stackId="a" fill={getPartyColour("FP")} />
        <Bar dataKey="C" stackId="a" fill={getPartyColour("C")} />
        <Bar dataKey="MP" stackId="a" fill={getPartyColour("MP")} />
      </BarChart>
    </>
  );

  function getPartyColour(party: string): string | undefined {
    // färgerna tagna från: https://www.riksdagen.se/sv/ledamoter-partier/?typeoflist=parti&parti=-#partier
    switch (party) {
      case 'S':
        return '#ed1b34'; // Röd
      case 'V':
        return '#da291c'; // Knappt distinct Röd
      case 'MP':
        return '#53a045'; // Ljusgrön
      case 'M':
        return '#52bdec'; // Ljusblå
      case 'FP':
      case 'L':
        return '#006ab3'; // Medelblå
      case 'SD':
        return '#fbc700'; // Gul
      case 'KD':
        return '#005ea1'; // Mörkblå
      case 'C':
        return '#016a3a'; // Mörkgrön
      case 'FI':
        return '#ce1468'; // Ljusröd
    }
    return undefined;
  }
};
