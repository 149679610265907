import React from "react";
import {PieChartData} from "react-minimal-pie-chart";
import {Variant} from "../../PieChart";
import {CardContent, Typography} from "@material-ui/core";
import {useStyles} from "./ChartInfo.style";

interface Props {
  title: Variant;
  data: PieChartData[];
}

const ChartInfoText = (props: PieChartData) => {
  const classes = useStyles()
  return (
    <div className={classes.colorBox} style={{backgroundColor: props.color}}>
      <div className={classes.textContainer}>
        <Typography className={classes.colorBoxTitle} display={"inline"} component="h1" variant="subtitle1">
          {props.title}:
        </Typography>
        <Typography className={classes.colorBoxText} display={"inline"} component="p" variant="body1">
          {props.value}
        </Typography>
      </div>
    </div>

  )
}

const ChartInfo = (props: Props) => {
  const classes = useStyles()
  const {title, data} = props;

  return (
    <CardContent className={classes.root}>
      <Typography className={classes.title} component="h1" variant="h4">
        {title}
      </Typography>
      <div className={classes.colorBoxContainer}>
          {data.map(item => <ChartInfoText  {...item}/>)}
      </div>
    </CardContent>
  )
};

export default ChartInfo;
