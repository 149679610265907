import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles({
  headContainer: {
    marginTop: 50,
    textAlign: "center",
  },
  boldHeading: {
    fontWeight: 'bolder'
  }
});
