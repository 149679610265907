import {Paging, TvakammarriksdagenMeta} from "../model/ApiResponse";
import {PageSelection} from "../model/ApiRequest";

const PATH = `${process.env.REACT_APP_BASE_URL}/api/tvakammarriksdagen/search`
const CONFIG = {
  method: "GET",
  headers: {'Accept': 'application/json'},
  mode: "cors"
} as RequestInit

export enum Types {
  PROTOKOLL = "Protokoll",
  REGISTER = "Register",
  PROPOSITION = "Proposition",
  SKRIVELSE = "Skrivelse",
  BERATTELSER_OCH_REDOGORELSER = "Berättelser och redogörelser",
  MOTION = "Motion",
  UTLATANDE = "Utlåtande",
  BETANKANDE = "Betänkande",
  MEMORIAL = "Memorial",
  RIKSDAGENS_SKRIVELSER = "Riksdagens skrivelse",
  RIKSSTATEN = "Riksstaten",
  RIKSDAGSBESLUT = "Riksdagsbeslutet",
  REGLEMENTE_RIKSGALDSKONTORET = "Reglemente (Riksgäldskontoret)",
  REGLEMENTE_RIKSBANKEN = "Reglemente (Riksbanken)",
  EKONOMIDEPUTERADES = "Ekonomideputerades",
  KANSLIDEPUTERADES = "Kanslideputerades",
  BUDGETDEPUTERADES = "Budgetdeputerades",
  TILLAGSSTAT_RIKSSTATEN = "Tilläggsstat (Riksstaten)",
  RIKSDAGENS_FORFATTNINGSSAMLING = "Riksdagens författningssamling",
  KOMITTEBETANKANDE = "Kommittébetänkanden",
  PROTOKOLLSUTDRAG = "Protokollsutdrag",
  BEVILLNINGSSTADGA = "Bevillningsstadga",
}

export interface QueryParams extends PageSelection {
  query: string,
  gteYear?: number | string,
  lteYear?: number | string,
  types: Types[] | string[],
}

export interface ResponseData {
  dokument: TvakammarriksdagenMeta[],
  paging: Paging,
}

export const getTvakammarriksdagsData = (queryParams: QueryParams): Promise<ResponseData> => {
  return fetch(`${PATH}?${buildQueryString(queryParams)}`, CONFIG)
    .then(res => res.json())
};


const buildQueryString = (queryParams: QueryParams): string => {
  const keyValuePairs = [];
  for (let i = 0; i < Object.keys(queryParams).length; i += 1) {
    if (Object.values(queryParams)[i] !== "") {
      keyValuePairs.push(`${encodeURIComponent(Object.keys(queryParams)[i])}=${encodeURIComponent(Object.values(queryParams)[i])}`);
    }
  }
  return keyValuePairs.join('&');
}

export const getTypesValues = (): string[] => {
  // @ts-ignore
  return Object.keys(Types).map((type) => Types[type])
}
