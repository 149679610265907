import React from "react";
import {Typ} from "../model/useTyper";

export const getBackupIfNeeded = (typ: string, typer: Typ[]): string => {
  console.error('Could not find typ:', typ);
  try {
    const asJson = JSON.parse(typ);
    if (Array.isArray(asJson)) {
      console.error('identified as json, will use first value');
      const maybeTyp = typer.find(it => it.doktyp === asJson[0]);
      if (maybeTyp) {
        console.log('Successfully identified broken typ');
        return maybeTyp.namn
      }
      console.error('Could not identity first element as valid typ.');
      return asJson[0];
    }
    return typ
  } catch (e) {
    console.log('Could not parse as json', e);
    return typ
  }
}

export const displayMatchBlock = (matchBlock: Array<string> | undefined) => {
  if (!matchBlock || matchBlock.length === 0) return (<></>);
  return (<div
    className={matchBlock ? 'content blockquote is-small has-text-info' : 'is-hidden'}>
    <blockquote>
      {matchBlock && matchBlock.map((it, index) => {
        return (<div key={index}>
          {/* Would be preferred to not use dangerouslySetInnerHTML */}
          <em className={'is-child'} key={index}
              dangerouslySetInnerHTML={{ __html: it + "..." }} />
          <br />
        </div>)
      })}
    </blockquote>
  </div>)
};
