import {Anforande, StandsriksdagenMeta} from "./ApiResponse";
import { DokumentExport } from "./DokumentStatus";

interface ExportProps {
  exportType: string,
  exportName: string,
  csvString: string,
  json: Array<DokumentExport> | Array<Anforande> | Array<StandsriksdagenMeta>
}

const downloadExport = (exportProps: ExportProps) => {
  const {csvString, exportName, exportType, json} = exportProps

  const type = `application/${exportType}`;
  const URL = window.URL || window.webkitURL;
  if (typeof URL.createObjectURL === 'undefined') {
    const dataURI = `data:${type};charset=utf-8,${csvString}`;
    window.open(dataURI)
  } else {
    const blob = new Blob(exportType === "json" ? [JSON.stringify(json)] : [csvString], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${exportName}_export_${Date.now().valueOf()}.${exportType}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
};


export default downloadExport;
