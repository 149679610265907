import { useEffect, useState } from "react";

export interface PartierList {
  partier: string[]
}

const PARTY_CACHE_KEY = 'partier-cache';
const emptyAnswer: PartierList = {partier: []};

/**
 * A custom hook in order to fetch the list of parties.
 * The result from the API is aggressively cached in the local storage.
 * So if there is some kind of 'lots of changes' one probably need to add cache
 * invalidations, such as daily invalidations.
 */
export const usePartier = (): PartierList => {

  const [partier, setPartier] = useState<PartierList>(emptyAnswer);

  async function fetchPartier() {
    let partyCache = localStorage.getItem(PARTY_CACHE_KEY);
    if (partyCache) {
      setPartier(JSON.parse(partyCache));
      return;
    }

    let jsonAnswer: PartierList;
    try {
      let response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/partier`);
      jsonAnswer = await response.json();
    } catch (e) {
      console.error(e);
      return;
    }
    jsonAnswer.partier = jsonAnswer.partier.map(it => it.toUpperCase());
    localStorage.setItem(PARTY_CACHE_KEY, JSON.stringify(jsonAnswer));
    setPartier(jsonAnswer);
  }

  useEffect(() => {
    (async () => {
      await fetchPartier()
    })()
  }, []);


  return partier;
};
