import { AnforandeMeta, DokumentMeta } from "../model/ApiResponse";
import {
  AnforandeSearchResponse,
  DokumentSearchResponse
} from "./SearchInterface";
import { Typ, useTyper } from "../model/useTyper";
import {
  faAngleDoubleRight,
  faBook,
  faCalendar,
  faFingerprint,
  faGlobe,
  faIdBadge,
  faSort,
  faUser,
  faVoteYea
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { TitleCase } from "../model/Helpers";
import moment from "moment";
import { pagingMargin } from "../CssConstants";
import {Path} from "../App";

interface DokumentSearchView {
  input: DokumentSearchResponse
}

interface AnforandeSearchView {
  input: AnforandeSearchResponse
}

export const DokumentPageView = (props: DokumentSearchView) => {
  const typer = useTyper();

  function renderOneDokument(match: DokumentMeta) {
    const maybeTyp = typer.typer.typ.find(it => it.doktyp === match.doktyp);
    const missingTypBackup = maybeTyp ? undefined : getBackupIfNeeded(match.doktyp, typer.typer.typ);

    return <div key={match.dok_id} style={pagingMargin}>
        <article key={match.dok_id}  className="tile is-child notification">
          <div className="content">
            <p className="title">
              <a href={`${Path.ENKAMMARRIKSDAGEN}/dokument?dok_id=${match.dok_id}`}>
                <FontAwesomeIcon icon={faBook} />
                {' '} {match.titel && TitleCase(match.titel)} {' '}
                <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </p>
            <p className={match.subtitel ? 'subtitle' : 'is-hidden'}>
              <FontAwesomeIcon
                icon={faUser} /> {match.subtitel}</p>
            <div className="content">
              <p><FontAwesomeIcon icon={faIdBadge} /> Id: <a
                href={`${Path.ENKAMMARRIKSDAGEN}/dokument?dok_id=${match.dok_id}`}>{match.dok_id}</a>
              </p>
              <p><FontAwesomeIcon
                icon={faCalendar} /> Datum: {match.datum && moment(match.datum).format('YYYY-MM-DD')}
              </p>
              <p><FontAwesomeIcon
                icon={faGlobe} /> Web: <a href={match.dokument_url_html} >{match.dokument_url_html}</a> </p>
              <p><FontAwesomeIcon
                icon={faSort} /> {maybeTyp ? 'Typ' : 'Okänd typ'}: {maybeTyp ? maybeTyp.namn : missingTypBackup}
              </p>
              <p><FontAwesomeIcon
                icon={faVoteYea} /> Parti: {match.parti ? match.parti : "OKÄNT"}
              </p>
            </div>
            {displayMatchBlock(match.match)}
          </div>
        </article>
      </div>
  }

  return <div key={"no"}>
      {props.input.dokument.map(renderOneDokument)}
    </div>
  ;
};

export const AnforandePageView = (props: AnforandeSearchView) => {

  function renderOneAnforande(match: AnforandeMeta) {
    return (
      <div key={match.anforande_id} style={pagingMargin}>
        <article className="tile is-child notification">
          <div className="content">
            {/* submit=1 is to auto submit search. */}
            <p className="title">
              <a href={`${Path.ENKAMMARRIKSDAGEN}/anforande?uuid=${match.anforande_id}`}>
                <FontAwesomeIcon icon={faBook} />
                {' '} {match.dok_titel ? TitleCase(match.dok_titel) : 'Ingen Titel'} {' '}
                <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </p>
            <p className="subtitel"><FontAwesomeIcon
              icon={faUser} /> {match.talare ? TitleCase(match.talare) : 'Talarinformation saknas'}
            </p>
            <div className="content">
              <p>
                <a href={`${Path.ENKAMMARRIKSDAGEN}/anforande?uuid=${match.anforande_id}`}>
                  <FontAwesomeIcon icon={faIdBadge} /> {' '}
                  Dokument: {match.dok_id} Nr: {match.anforande_nummer}
                </a>
              </p>
              <p><FontAwesomeIcon
                icon={faFingerprint} /> Anförande: {match.anforande_id}</p>
              <p><FontAwesomeIcon
                icon={faCalendar} /> Datum: {match.dok_datum && moment(match.dok_datum).format('YYYY-MM-DD')}
              </p>
              <p className={match.parti ? '' : "is-hidden"}><FontAwesomeIcon
                icon={faVoteYea} /> Parti: {match.parti && match.parti.toUpperCase()}
              </p>
            </div>
            {displayMatchBlock(match.match)}
          </div>
        </article>
      </div>
    )
  }

  return (
    <>
      {props.input.anforanden.map(renderOneAnforande)}
    </>
  );
};

const displayMatchBlock = (matchBlock: Array<string> | undefined) => {
  if (!matchBlock || matchBlock.length === 0) return (<></>);
  return (<div
    className={matchBlock ? 'content blockquote is-small has-text-info' : 'is-hidden'}>
    <blockquote>
      {matchBlock && matchBlock.map((it, index) => {
        return (<div key={index}>
          {/* Would be preferred to not use dangerouslySetInnerHTML */}
          <em className={'is-child'} key={index}
            dangerouslySetInnerHTML={{ __html: it + "..." }} />
          <br />
        </div>)
      })}
    </blockquote>
  </div>)
};

function getBackupIfNeeded(typ: string, typer: Typ[]): string {
  console.error('Could not find typ:', typ);
  try {
    const asJson = JSON.parse(typ);
    if (Array.isArray(asJson)) {
      console.error('identified as json, will use first value');
      const maybeTyp = typer.find(it => it.doktyp === asJson[0]);
      if (maybeTyp) {
        console.log('Successfully identified broken typ');
        return maybeTyp.namn
      }
      console.error('Could not identity first element as valid typ.');
      return asJson[0];
    }
    return typ
  } catch (e) {
    console.log('Could not parse as json', e);
    return typ
  }
}
