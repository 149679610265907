export const noBoxShadow = {
  boxShadow: "none"
};

export const noPadding = {
  marginLeft: "-40px"

};

export const NotActive = {
  'pointer-events': "none",
  'cursor': "default",
};

export const pagingMargin = {
  marginTop: "1rem",
  marginBottom: "1rem",
  // marginLeft: "-0.3rem",
  // marginRight: "-0.3rem"
};
export const bottomBorder = {
  borderBottom: "1px solid white",
  margin: "0px 1.5rem",
};

export const checkBoxColumns = {
  marginLeft: "1.2rem",
  columnCount: 2
};

export const smallLeftMargin = {
  marginLeft: "0.5rem"
};
