import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      color: "#474748",
      border: "none",
      borderWidth: 0,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none"
      },
    },
  }),
);
