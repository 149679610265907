import {StandsriksdagenMeta} from "../model/ApiResponse";

const PATH = `${process.env.REACT_APP_BASE_URL}/api/standsriksdagen`
const CONFIG = {method: "GET", headers: {'Accept': 'application/json'}, mode: "cors"} as RequestInit

export interface QueryParams {
  filename: string,
}

export const getStandsriksdagsDataDocumentByName = (queryParams: QueryParams): Promise<StandsriksdagenMeta> =>  {
  return fetch(`${PATH}?${buildQueryString(queryParams)}`, CONFIG)
    .then(res => res.json())
};


const buildQueryString = (queryParams: QueryParams): string => {
  const keyValuePairs = [];
  for (let i = 0; i < Object.keys(queryParams).length; i += 1) {
    keyValuePairs.push(`${encodeURIComponent(Object.keys(queryParams)[i])}=${encodeURIComponent(Object.values(queryParams)[i])}`);
  }
  return keyValuePairs.join('&');
}
