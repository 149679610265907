import "react-datepicker/dist/react-datepicker.css";

import React, { useEffect, useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paging } from "./ApiResponse";
import { faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import { motion } from "framer-motion";
import { pagingMargin } from "../CssConstants";
import sv from 'date-fns/locale/sv';

export const TitleCase = function (toTitleCase: string): string {
  return toTitleCase.toLowerCase().split(' ').map(function (word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
};

export const Paginator = (props: {
  paginator: Paging,
  doSearch: (newPage: number) => void
}) => {
  const { paginator, doSearch } = props;

  if (paginator.hits.startsWith('0')) return (<></>);
  return (
    <>
      <div>
        <nav className="pagination is-centered" style={pagingMargin}
          role="navigation"
          aria-label="pagination">
          <button className="pagination-previous button is-info"
            disabled={paginator.page === 1} onClick={decrease}>Före. sida
          </button>
          <ul className="pagination-list">
            <li>
              <button className="pagination-link is-current"
                aria-label={'page'}
                aria-current="page">{`Sida ${paginator.page} av ${paginator.of}`}</button>
            </li>
          </ul>
          <button className="pagination-next button is-info"
            disabled={paginator.page === paginator.of}
            onClick={increase}>Nästa sida
          </button>
        </nav>
      </div>
    </>);

  function increase() {
    doSearch(paginator.page + 1)
  }

  function decrease() {
    if (1 < paginator.page) {
      doSearch(paginator.page - 1);
    }
  }
};


export const CollapsibleCard = (props: { title: string, initalState: boolean, children?: any | undefined }) => {
  const [collapsed, setCollapsed] = useState<boolean>(props.initalState);

  // automaticly open if state is set, do not close if in open state
  useEffect(() => {
    if (collapsed) {
      setCollapsed(props.initalState)
    }
  }, [props.initalState]);;// eslint-disable-line react-hooks/exhaustive-deps

  const variants = {
    open: {
      rotate: 0,
      transition: { ease: "linear", duration: 0.15 }
    },
    closed: {
      rotate: -180,
      transition: { ease: "linear", duration: 0.15 }
    }
  };

  function toggleCollapsed() {
    setCollapsed(oldState => !oldState);
  }
  return (
    <>
      <div className="card" style={{ boxShadow: "none" }}>
        <motion.div whileHover={{
          cursor: "pointer",
          backgroundColor: "rgba(0, 0, 0, 0.04)"
        }}>
          <div className="card-header" onClick={toggleCollapsed}>
            <p className="card-header-title">
              {props.title}
            </p>
            <motion.div
              animate={collapsed ? "open" : "closed"}
              variants={variants}
            >
              <p className="card-header-icon is-hidden-fullscreen"
                aria-label="more options">
                <span className="icon">
                  <FontAwesomeIcon
                    icon={faAngleDoubleUp} />
                </span>
              </p>
            </motion.div>
          </div>
        </motion.div>
      </div>
      <div className={collapsed ? 'is-hidden' : 'card-content'}>
        {props.children}
      </div>
    </>
  )
};

export function errorDecider(response: Response | undefined) {
  let error: Error;
  if (response === undefined || response.status === 503) {
    error = new Error(`Service not available`);
    error.name = "ConnectionError";
    throw error;
  } else if (response.status === 404) {
    error = new Error(`The object does not exist!`);
    error.name = "EmptyError";
    throw error;
  }
}


// Some estimated numbers.
const minuteInMilliseconds = 60000;
const hourInMilliseconds = minuteInMilliseconds * 60;
const dayInMilliseconds = hourInMilliseconds * 24;
const weekInMilliseconds = dayInMilliseconds * 7;
const monthInMilliseconds = weekInMilliseconds * 7;
const quarterInMilliseconds = monthInMilliseconds * 3;
const yearInMilliseconds = 31536000000;

export function estimateNumberOfBuckets(dateType: string, gteDate?: Date, lteDate?: Date): number {
  // TODO: What is oldest data date? :)
  const from = gteDate ? gteDate : Date.parse('1970-01-01'); //
  const to = lteDate ? lteDate : Date.now();
  const fromTime: number = from.valueOf();
  const toTime: number = to.valueOf();

  const diff = (toTime - fromTime); // diff in milliseconds.
  switch (dateType) {
    case 'minute':
      return diff / minuteInMilliseconds;
    case 'hour':
      return diff / hourInMilliseconds;
    case 'day':
      return diff / dayInMilliseconds;
    case 'week':
      return diff / weekInMilliseconds;
    case 'month':
      return diff / monthInMilliseconds;
    case 'quarter':
      return diff / quarterInMilliseconds;
    case 'year':
      return diff / yearInMilliseconds;
    default:
      return Number.POSITIVE_INFINITY; // Error!
  }
}

registerLocale('sv', sv);

export const StartStopDatePicker = (props: {
  setStartString?: (newValue: string, updateType?: "pushIn") => void
  setStopString?: (newValue: string, updateType?: "pushIn") => void
  start?: string
  stop?: string
}) => {
  const today = moment().toDate();

  const [startDate, setStartDate] = useState<Date | null>(props.start ? moment(props.start).toDate() : null);
  const [stopDate, setStopDate] = useState<Date | null>(props.stop ? moment(props.start).toDate() : null);

  useEffect(() => {
    setStartDate(props.start ? moment(props.start).toDate() : null);
  }, [props.start]);

  useEffect(() => {
    setStopDate(props.stop ? moment(props.stop).toDate() : null);
  }, [props.stop]);

  function setStart(date: Date) {
    props.setStartString && props.setStartString(date && moment(date).format('YYYY-MM-DD'), "pushIn");
    setStartDate(date);
  }

  function setStop(date: Date) {
    props.setStopString && props.setStopString(date && moment(date).format('YYYY-MM-DD'), "pushIn");
    setStopDate(date);
  }
  return (
    <div className="card is-collapsible">
      <CollapsibleCard
        title={'Start och Stopp Datum'}
        initalState={!props.start && !props.stop}
      >
        <p>Start datum:</p>
        <ReactDatePicker
          className={'card-message'}
          locale={'sv'}
          dateFormat={'yyyy-MM-dd'}
          selected={startDate}
          onChange={setStart}
          maxDate={today}
          showMonthDropdown
          showYearDropdown
          showWeekNumbers
          dropdownMode="select"
          placeholderText="Välj ett startdatum"
          isClearable
        />
        <p>Stopp datum:</p>
        <ReactDatePicker
          className={'card-message'}
          locale={'sv'}
          dateFormat={'yyyy-MM-dd'}
          selected={stopDate}
          onChange={setStop}
          maxDate={today}
          showMonthDropdown
          showYearDropdown
          showWeekNumbers
          dropdownMode="select"
          placeholderText="Välj ett stopdatum"
          isClearable
        />
      </CollapsibleCard>
    </div>
  )
};

export const SearchResultDateSorting = (props: {
  setSortType: (sortType: string) => void
}) => {

  const [sortType, setSortType] = useState<string>("relevance");

  useEffect(() => {
    props.setSortType(sortType);
  }, [sortType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card is-collapsible">
      <CollapsibleCard
        title={'Sortering datum'}
        initalState={true}
      >
        <form>
          <div>
            <label>
              <input type="radio" name={"relevance"} checked={sortType === "relevance"} onChange={() => setSortType("relevance")}/>
              &nbsp;- Relevans
            </label>
          </div>
          <div>
            <label>
              <input type="radio" name={"ascending"} checked={sortType === "ascending"} onChange={() => setSortType("ascending")} />
              &nbsp;- Stigande
            </label>
          </div>
          <div>
            <label>
              <input type="radio" name={"descending"} checked={sortType === "descending"} onChange={() => setSortType("descending")} />
              &nbsp;- Fallande
            </label>
          </div>
        </form>
      </CollapsibleCard>
    </div>
  )
};
