import React, {Dispatch, SetStateAction, useState} from 'react';
import Drawer from '@material-ui/core/Drawer';
import {Button} from "@material-ui/core";
import {useStyles} from "./SearchFilter.style";
import BetweenYearFilter, {BetweenYear} from "../BetweenYearFilter/BetweenYearFilter";

export interface Filter {
  betweenYear?: BetweenYear
}

interface Props {
  isOpen: boolean
  onApply: (filter: Filter) => void
  defaultFilter?: Filter;
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const SearchFilter = (props: Props) => {
  const classes = useStyles();
  const {isOpen, setIsOpen, onApply, defaultFilter} = props;
  const [betweenYearState, setBetweenYearState] = useState<BetweenYear>()

  const handleClose = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsOpen(false);
  };

  const handleApplyFilter = (event: React.MouseEvent) => {
    onApply({betweenYear: betweenYearState})
    setIsOpen(false);
  };

  const filters = () => (
    <div className={classes.list} role="presentation">
      <BetweenYearFilter defaultValues={defaultFilter?.betweenYear} setFilter={setBetweenYearState}/>
    </div>
  );

  return (
    <Drawer anchor={"top"} open={isOpen} onClose={handleClose()}>
      {filters()}
      <Button aria-label="use-filter" color="primary" variant="text" onClick={handleApplyFilter}>
        Använd filter
      </Button>
      <Button aria-label="close" color="secondary" variant="text" onClick={handleClose()}>
        Stäng
      </Button>
    </Drawer>
  );
}

export default SearchFilter;
