import React from "react";
import MuiIconButton from '@material-ui/core/IconButton';
import Divider from "@material-ui/core/Divider";
import {useStyles} from "./IconButton.style";

export enum DividerType {
  LEFT,
  RIGHT,
}

interface Props {
  icon: any;
  color: string
  divider?: DividerType;
  isVisible?: boolean;
  ariaLabel: string;
  onClick?: () => void;
}

const IconButton = (props: Props) => {
  const {icon, divider, ariaLabel, isVisible, onClick, color} = props
  const classes = useStyles(color)();

  if (!isVisible){
    return null;
  }

  return (
    <>
      {divider === DividerType.LEFT &&
      <Divider className={classes.divider} orientation="vertical"/>}
      <MuiIconButton onClick={onClick}  className={classes.iconButton} aria-label={ariaLabel}>
        {icon}
      </MuiIconButton>
      {divider === DividerType.RIGHT &&
      <Divider className={classes.divider} orientation="vertical"/>}
    </>
  )
}

export default IconButton;
