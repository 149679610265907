import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {useStyles} from "./ExportDialog.style";
import {
  StandsriksdagenMeta,
  TvakammarriksdagenMeta
} from "../../model/ApiResponse";
import downloadExport from "../../model/downloadExport";
import {Parser} from "json2csv";

export interface Props {
  exportName: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  properties: string[];
  onExport: () => Promise<StandsriksdagenMeta[] | TvakammarriksdagenMeta[]>;
}

export const ExportDialog = (props: Props) => {
  const {title, open, setOpen, properties, onExport, exportName} = props
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selections, setSelections] = useState<string[]>([]);
  const [exportType, setExportType] = useState<string>("json");

  const handleExport = (): void => {
    onExport()
      .then(result => {
        const json2csv = new Parser({
          quote: '"',
          delimiter: ","
        });
        const filteredSelection = selections.filter(item => item !== "all")
        const propertiesToBeRemoved = properties.filter(property => !filteredSelection.includes(property))
        propertiesToBeRemoved.push("match")

        // @ts-ignore
        result.map(item => propertiesToBeRemoved.map(property => delete item[property]))
        downloadExport({exportType: exportType, csvString: json2csv.parse(result), exportName: exportName, json: result})
      })
  }


  const handleChange = (event: React.ChangeEvent<{}>, checked: boolean) => {
    // @ts-ignore
    if (event.target.name === "all"){
      // @ts-ignore
      properties.forEach(property => document.getElementById(property).checked = checked)
      setSelections(checked ? [...properties, "all"]: [])
    }
    else if (checked) {
      // @ts-ignore
      selections.push(event.target.name)
      if (properties.every(property => selections.includes(property))){
        setSelections([...selections, "all"])
      } else {
        setSelections([...selections])
      }
    } else {
      // @ts-ignore
      setSelections(selections.filter(item => item !== event.target.name && item !== "all"))
    }
  };

  const handleRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExportType(event.target.value)
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {}, [selections, exportType]);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="export-dialog"
        fullWidth={true}
        maxWidth={"sm"}
      >

        <DialogTitle disableTypography className={classes.root}>
          <Typography variant="h6">{title}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Välj de fält du vill att exporten ska inehålla
          </DialogContentText>
          <FormGroup>
            <FormControlLabel onChange={handleChange} control={<Checkbox name={"all"} checked={selections.includes("all")} />} label={"Alla"} />
            {properties.map(property => <FormControlLabel onChange={handleChange} className={classes.subCheckbox} control={<Checkbox id={property} name={property} checked={selections.includes(property)}/>} label={property} />)}
          </FormGroup>
        </DialogContent>

        <DialogActions style={{backgroundColor: "lightgrey"}}>
          <RadioGroup row value={exportType} onChange={handleRadioButton}>
            <FormControlLabel value="json" control={<Radio />} label="JSON" />
            <FormControlLabel value="csv" control={<Radio />} label="CSV" />
          </RadioGroup>

          <Button color="primary" variant={"outlined"} onClick={handleClose}>
            Avbryt
          </Button>
          <Button disabled={selections.length < 1} color="primary" variant={"contained"} onClick={handleExport}>
            Exportera
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default ExportDialog;
