import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    form: {
      width: "100%",
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },

    // ===== Mobile =====
    formMobile: {
      width: "100%",
      marginTop: 20,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
    },
    actionTopBarMobile: {
      position: "fixed",
      zIndex: 1110, // appBar has 1100, to be able to show the actionbar on top this is necessary
      width: "100%",
      top: 0,
      left:0,
      marginLeft: 40,
      display: 'flex',
      alignItems: 'center',
    },
    hitsTextMobile: {
      color: "#FFF",
    },
  }),
);
