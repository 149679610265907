import {CollapsibleCard, errorDecider} from "../model/Helpers";
import {
  Person,
  PersonResponse,
  PersonUppdrag,
  PersonUppgift
} from "./PersonResponse";
import React, {useEffect, useState} from "react";
import {faCalendar, faGlobe, faUsers} from "@fortawesome/free-solid-svg-icons";

import {ClipboardButton} from "../model/ClipboardService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal} from "../model/Modal";
import {StringParam, useQueryParam} from "use-query-params";
import moment from 'moment';

const PAGE_TITLE = "Enkammarriksdagen";
const STORED_QUERY = "person-id-query";

interface Props {
  setPageTitle: (pageTitle: string) => void;
}

interface StoredParams {
  idParam?: string,
}

export const PersonTab = (props: Props) => {
  const [idParam = "", setIdParam] = useQueryParam('intressent_id', StringParam);
  const [intressentId, setIntressentId] = useState<string>(idParam);
  const [person, setPerson] = useState<PersonResponse>({});
  const [disconnected, setDisconnected] = useState<boolean>(false);
  const [emptySubmit, setEmptySubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  props.setPageTitle(PAGE_TITLE);

  useEffect(() => {
    if (!idParam) {
      const storedObject = sessionStorage.getItem(STORED_QUERY);
      if (storedObject) {
        const { idParam }: StoredParams = JSON.parse(storedObject);
        setIdParam(idParam)
      }
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // so that input follows Param
    setIntressentId(idParam);
    setEmptySubmit(false);
    if (idParam) {
      findPerson()
        .then(setPerson)
        .catch((e) => {
          errorHandler(e)
        })
    } else {
      setPerson({})
    }
  }, [idParam]); // eslint-disable-line react-hooks/exhaustive-deps

  async function findPerson(): Promise<PersonResponse> {
    setLoading(true);
    setStorage();
    let jsonAnswer: PersonResponse;
    const params = new URLSearchParams();
    let response: Response | undefined;
    params.append('intressent_id', idParam);
    try {
      response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/personer?${params}`);
    } finally {
      setLoading(false);
      errorDecider(response);
    }
    jsonAnswer = await response.json();
    return jsonAnswer;
  }

  function setStorage() {
    const storedObject: StoredParams = { idParam };
    sessionStorage.setItem(STORED_QUERY, JSON.stringify(storedObject))
  }

  function errorHandler(e: Error) {
    switch (e.name) {
      case "EmptyError":
        setEmptySubmit(true);
        setPerson({});
        break;
      case "ConnectionError":
        setDisconnected(true);
        break;
    }
  }

  function doSearch(submitSearch: React.FormEvent) {
    submitSearch.preventDefault();
    setIdParam(intressentId, "pushIn");
  }

  return (
    <>
      <form onSubmit={doSearch}>
        <div className="field has-addons">
          <p className="control is-expanded has-icons-left">
            <input className="input" type="text"
              id="person-id-input"
              value={intressentId}
              placeholder="Ange 'intressent_id' för sökt parlamentarike"
              onChange={e => setIntressentId(e.target.value)} />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faUsers} />
            </span>
          </p>
          <div className="control">
            <button
              className={loading ? "button is-info is-loading" : "button is-info"}>
              Sök
            </button>
          </div>
          <ClipboardButton hidden={person.person === undefined} />
        </div>
      </form>
      <br /> {/* Why was the spacing so bad that this was needed? */}
      {emptySubmit &&
        <p className="subtitle is-4 has-text-danger has-text-centered ">Inga
        sökresultat </p>}
      <RenderOnePerson person={person.person} />
      <Modal
        disconnected={disconnected}
        setDisconnected={setDisconnected} />
    </>
  )

};

const RenderOnePerson = (props: { person?: Person }) => {
  if (!props.person) return (<></>);
  const person = props.person;

  return (
    <>
      <article className="media">
        <figure className={person.bild_url_192 ? 'media-left' : 'is-hidden'}>
          <p className="image is-128x128">
            <img alt={`${person.tilltalsnamn} ${person.efternamn}`}
              src={person.bild_url_192} />
          </p>
        </figure>
        <div className="media-content">
          <div className="content">
            <h3>{person.tilltalsnamn} {person.efternamn} - {person.parti}</h3>
            <h4>{person.valkrets}</h4>
            <h4>{person.status}</h4>
          </div>
          <CollapsibleCard title={'Uppdrag'}
            initalState={false}
          >
            <RenderAllUppdrag uppdrag={person.personuppdrag.uppdrag} />
          </CollapsibleCard>
          {person.personuppgift && <CollapsibleCard
            title={'Uppgifter'}
            initalState={false}
          >
            <RenderAllUppgift
              uppgifter={person.personuppgift && person.personuppgift.uppgift} />
          </CollapsibleCard>
          }
        </div>
      </article>
    </>
  )
};

const RenderAllUppdrag = (props: { uppdrag?: Array<PersonUppdrag> | PersonUppdrag }) => {
  if (!props.uppdrag) return (<></>);
  let useArray;
  if (Array.isArray(props.uppdrag)) {
    useArray = props.uppdrag;
  } else {
    useArray = [props.uppdrag];
  }

  const sortedByStart = useArray.sort((a, b) => {
    return (moment(b.from).valueOf() - moment(a.from).valueOf()); // Decending.
  });

  return (
    <>
      {sortedByStart.map(RenderOneUppdrag)}
    </>
  )
};

const RenderOneUppdrag = (uppdrag: PersonUppdrag, index: number) => {

  return <article key={index} className={colourFromDate(uppdrag.tom)}>
    <div className={'content'}>
      <p className={uppdrag.status !== 'Ledig' ? 'subtitle' : 'is-hidden'}>
        {uppdrag.roll_kod}{uppdrag.uppgift && ', ' + uppdrag.uppgift}
      </p>
      <p className={uppdrag.status === 'Ledig' ? 'subtitle' : 'is-hidden'}>
        Ledig från: {uppdrag.roll_kod}
      </p>
      <p className={'subtitle'}>
        <FontAwesomeIcon
          icon={faCalendar} /> Fr.o.m: {uppdrag.from} {uppdrag.tom ? `- T.o.m: ${uppdrag.tom}` : ''}
      </p>
    </div>
    <div
      className={uppdrag.status === 'Ledig' ? 'content is-small' : 'is-hidden'}>
      <blockquote style={{ backgroundColor: "transparent" }}>
        Ersättare: {uppdrag.uppgift}
      </blockquote>
    </div>
  </article>
};

const RenderAllUppgift = (props: { uppgifter: Array<PersonUppgift> | PersonUppgift }) => {
  if (!props.uppgifter) return (<></>);
  let useArray;
  if (Array.isArray(props.uppgifter)) {
    useArray = props.uppgifter;
  } else {
    useArray = [props.uppgifter];
  }

  return (
    <>
      {useArray.map(RenderOneUppgift)}
      <RenderTitlar titlarArray={useArray.filter(it => it.typ === 'titlar')} />
    </>
  )
};

function transformEmail(eadress: string) {
  if (eadress.includes('[på]')) {
    return 'mailto:' + eadress.replace('[på]', '@')
  }
  return eadress;

}
const RenderOneUppgift = (uppgift: PersonUppgift, index: number) => {
  if (uppgift.typ === 'eadress')
    return (<article key={index} className={'tile is-child notification'}>
      <div className={'content'}>
        <FontAwesomeIcon icon={faGlobe} />
        <a href={transformEmail(uppgift.uppgift)}>
          {' ' + uppgift.kod}
        </a>
      </div>
    </article>
    );
  if (uppgift.typ === 'val' && uppgift.kod === 'KandiderarINastaVal') return (
    <article key={index} className={'tile is-child notification'}>
      <div className={'content'}>
        <p>
          {uppgift.uppgift === 'true' ? 'Kandiderar i nästa val.' : 'Kandiderar inte i nästa val.'}
        </p>
      </div>
    </article>
  );
  // Telefonnummer, no interest to show.
};

const RenderTitlar = (props: { titlarArray: Array<PersonUppgift> }) => {
  const { titlarArray } = props;
  if (titlarArray.length === 0) return null;
  // Instead of rendering, add to list of titles.
  const combinedTitles: Array<string> = [];

  titlarArray.forEach(it => {
    let icon: string;
    if (!it.uppgift) return; // Null for example is filtered.
    if (it.kod === 'sv') {
      icon = '🇸🇪'
    } else if (it.kod === 'en') {
      icon = '🇬🇧'
    } else {
      icon = `${it.kod}`
    }
    combinedTitles.push(`${icon}: ${it.uppgift}`);
  }
  );

  return <article key={'titlar'} className={'tile is-child notification'}>
    <div className={'content'}>
      <h4>Titlar:</h4>
      {combinedTitles.map((it, index) => (<p key={index}>{it}</p>))}
    </div>
  </article>
};

const colourFromDate = (date?: string | Date) => {
  if (!date) return 'tile is-child notification'; // No date, just regular
  const now = moment();
  const theirDate = moment(date);
  if (now.valueOf() > theirDate.valueOf()) {
    // OK! We are before their time.
    return 'tile is-child notification';
  } else {
    // We are past their time.
    return 'tile is-child notification is-info';
  }
};


