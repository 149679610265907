import React, {useEffect, useState} from "react";
import SearchBar from "../../../Component/SearchBar/SearchBar";
import {StringParam, useQueryParam} from "use-query-params";
import {StandsriksdagenMeta} from "../../../model/ApiResponse";
import {
  getStandsriksdagsDataDocumentByName,
  QueryParams
} from "../../../Dataprovider/GetStandsriksdagsDataDocumentByName";
import DocumentView from "../../../Component/Document/DocumentView";

const PAGE_TITLE = "Ståndsriksdagen";
const STORED_QUERY = "stand-query-filename";

interface Props {
  setPageTitle: (pageTitle: string) => void;
}

const DocumentSearchView = (props: Props) => {
  const [query = "", setQuery] = useQueryParam('filename', StringParam);
  const [result, setResult] = useState<StandsriksdagenMeta>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  props.setPageTitle(PAGE_TITLE);

  const handleSubmit = (query: string) => {
    if (query !== "") {
      setIsLoading(true)
      setQuery(query)
      getStandsriksdagsDataDocumentByName({filename: query})
        .then(result => {
          setIsLoading(false)
          setResult(result)
        })
      sessionStorage.setItem(STORED_QUERY, JSON.stringify({filename: query}))
    }
  }

  // Reads session storage or queryparams and makes a search
  useEffect(() => {
    const storedValues = sessionStorage.getItem(STORED_QUERY);
    if (query) {
      handleSubmit(query)
    }
    else if (storedValues) {
      const storedQuery: QueryParams = JSON.parse(storedValues);
      setQuery(storedQuery.filename)
      handleSubmit(storedQuery.filename)
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SearchBar
        isLoading={isLoading}
        defaultQuery={query}
        showExport={false}
        showCopyLink={!!result?.filnamn}
        showQuestionMark={false}
        onSubmit={handleSubmit}
      />
      {result && <DocumentView data={result}/>}
    </>
  )
};

export default DocumentSearchView;
