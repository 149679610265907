import React, {useEffect, useRef, useState} from "react";
import {
  CollapsibleCard,
  errorDecider,
  StartStopDatePicker
} from "../model/Helpers";
import {StringParam, useQueryParam} from "use-query-params";
import {noBoxShadow, noPadding} from "../CssConstants";

import {DokumentAnalysisView} from "./DokumentHistogramView";
import {HistogramList} from "../model/AnalysisModel";
import {ClipboardButton} from "../model/ClipboardService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal} from "../model/Modal";
import {faQuestion, faSearch} from "@fortawesome/free-solid-svg-icons";
import {SearchLegendModal, Variant} from "../model/SearchLegendModal";

// Valid choices for 'dateType'
export type DateTypes = "minute" | "hour" | "day" | "week" | "month" | "year" | "quarter";
const STORED_QUERY = "dok-query-analysis";
const PAGE_TITLE = "Enkammarriksdagen";

interface Props {
  setPageTitle: (pageTitle: string) => void;
}

interface StoredParams {
  queryParam?: string,
  gteDate?: string,
  lteDate?: string,
  dateType?: string,
  type?: string
}

export const DokumentAnalysis = (props: Props) => {
  // Param only update on submit, as it is ugly to have it set for each letter.
  const [queryParam = "", setQueryParam] = useQueryParam('query', StringParam);
  const [type = "", setType] = useQueryParam('type', StringParam);
  const [gteDate = "", setGteDate] = useQueryParam('gteDate', StringParam);
  const [lteDate = "", setLteDate] = useQueryParam('lteDate', StringParam);
  const [dateType = "", setDateType] = useQueryParam('dateType', StringParam);
  const [result, setResult] = useState<HistogramList[] | undefined>(undefined);
  const [disconnected, setDisconnected] = useState<boolean>(false);
  const [emptySubmit, setEmptySubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const customRef = useRef<HTMLDivElement>(null);
  props.setPageTitle(PAGE_TITLE);

  useEffect(() => {
    const storedValues = sessionStorage.getItem(STORED_QUERY);
    if (storedValues) {
      const { queryParam, gteDate, lteDate, dateType, type }: StoredParams = JSON.parse(storedValues);
      setQueryParam(queryParam);
      setGteDate(gteDate);
      setLteDate(lteDate);
      setDateType(dateType);
      setType(type ? type : 'mot');
    }else{
      setType( 'mot');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setEmptySubmit(false);
    if (queryParam) {
      getData(queryParam)
        .then(setResult)
        .catch(errorHandler)
    } else {
      setResult(undefined);
      setEmptySubmit(false)
    }
  }, [dateType, queryParam, gteDate, lteDate, dateType, type]); // eslint-disable-line react-hooks/exhaustive-deps

  async function getData(query: string): Promise<HistogramList[]> {
    setLoading(true);
    setStorage();
    let jsonAnswer: HistogramList[];
    const params = new URLSearchParams();
    params.append('query', query);
    params.append('dateType', dateType ? dateType : 'year');
    gteDate && params.append('gteDate', gteDate);
    lteDate && params.append('lteDate', lteDate);
    type && params.append('type', type);
    let response: Response | undefined;
    try {
      response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/dokument/analyze?${params}`);
      // TODO: Handle error.
      jsonAnswer = await response.json();
    } finally {
      setLoading(false);
      errorDecider(response);
    }
    if (jsonAnswer.length === 0 || !jsonAnswer.some(x => x.histogramList.length > 0)) {
      let error = new Error(`The document [${query}] does not exist!`);
      error.name = "EmptyError";
      throw error;
    }
    return jsonAnswer;
  }

  function setStorage() {
    const storedObject: StoredParams = { queryParam, gteDate, lteDate, dateType };
    sessionStorage.setItem(STORED_QUERY, JSON.stringify(storedObject))
  }

  function errorHandler(e: Error) {
    switch (e.name) {
      case "EmptyError":
        setEmptySubmit(true);
        setResult(undefined);
        break;
      case "ConnectionError":
        setDisconnected(true);
        break;
    }
  }

  function submitSearch(query: string) {
    setQueryParam(query, "pushIn");
    // do better validation. too many buckets = bad search
    /*      if(dateType !== 'year') {
            const estimatedBuckets = estimateNumberOfBuckets(dateType, gteDate, lteDate);
            if(1000 < estimatedBuckets) {
              console.error('Search could probably create a lot of buckets, it is unsafe.');
              return;
            }
          }*/

  }

  return (
    <>
      <InputForm queryParam={queryParam || ""}
        doSearch={submitSearch}
        resultIsUndefined={result === undefined}
        loading={loading}
        setType={setType}
      />
      <StartStopDatePicker setStartString={setGteDate} setStopString={setLteDate}
        start={gteDate} stop={lteDate} />
      <SelectDateType setDateType={setDateType} current={dateType} />
      {/* <DokumentTyperList alreadySelectedTypes={types} limitTypesTo={TYPE_LIMITER} setFunction={setTypes} /> */}
      <div ref={customRef} className="box" style={{ ...noBoxShadow, ...noPadding }}>
        {emptySubmit ?
          <p className="subtitle is-4 has-text-danger has-text-centered ">Inga
          sökresultat </p> :
          result?.map((histogramList, index) =>
            <DokumentAnalysisView
              key={index}
              histogramList={histogramList}
              boxWidth={customRef.current ? customRef.current.clientWidth : 0}
              query={queryParam}
              dateType={dateType as DateTypes}
            />
          )
        }
      </div>
      <Modal
        disconnected={disconnected}
        setDisconnected={setDisconnected} />
    </>)
};

const InputForm = (props: {
  queryParam: string,
  resultIsUndefined: boolean,
  doSearch: (query: string) => void,
  loading: boolean,
  setType: (type: string) => void
}) => {
  const { queryParam, resultIsUndefined, doSearch, loading } = props;
  const [query, setQuery] = useState<string>(queryParam);
  const [showSearchLegend, setShowSearchLegend] = useState<boolean>(false);

  useEffect(() => {
    setQuery(queryParam)
  }, [queryParam]);

  function submitSearch(event: React.FormEvent) {
    event.preventDefault();
    doSearch(query)
  }

  return (
    <form onSubmit={submitSearch}>
      <SearchLegendModal isVisible={showSearchLegend} setIsVisible={setShowSearchLegend} variant={Variant.ANALYSIS}/>
      <div className="field has-addons">
        <div className="control">
        <div className="select">
          <select onChange={event => props.setType(event.target.value)}>
            <option value="mot">Motion</option>
            <option value="ip">Interpellation</option>
          </select>
        </div>
      </div>
        <p
          className={'control is-expanded has-icons-left'}>
          <input className="input" type="text" id="dokument-analysis-input"
            value={query || ""}
            placeholder="Skriv din sökfras här"
            onChange={event => setQuery(event.target.value)} />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faSearch} />
          </span>
          <span className="icon is-small"
                style={{right: 0, pointerEvents: "initial", cursor: "pointer"}}
                onClick={() => setShowSearchLegend(!showSearchLegend)}
          >
            <FontAwesomeIcon icon={faQuestion} />
          </span>
        </p>

        <div className="control">
          <button
            className={loading ? "button is-info is-loading" : "button is-info"}>
            Sök
          </button>
        </div>
        <ClipboardButton hidden={resultIsUndefined} />
      </div>
    </form>

  )
};


export const SelectDateType = (props: {
  setDateType?: (newValue: string, updateType?: "pushIn") => void
  current?: string
}) => {

  const [startType, setStartType] = useState<string | null>(props.current ? props.current : null);

  useEffect(() => {
    setStartType(props.current ? props.current : null);
  }, [props]);

  function setDateType(dateType: string) {
    props.setDateType && props.setDateType(dateType, "pushIn");
    setStartType(dateType);
  }

  return (
    <div className="card is-collapsible">
      <CollapsibleCard
        title={'Intervallsuppdelning'}
        initalState={!props.current}
      >
        <div className="control">
          <div className="select">
            <select onChange={event => setDateType(event.target.value)}
              value={startType || undefined}>
              <option value={"year" as DateTypes}>År</option>
              <option value={"month" as DateTypes}>Månad</option>
            </select>
          </div>
        </div>
      </CollapsibleCard>
    </div>
  )
};
