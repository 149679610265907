import React, { useEffect, useState } from "react";
import { PersonVoteringFull, VoteringFull } from "../model/ApiResponse";
import {
  faBirthdayCake,
  faFlag,
  faHome,
  faLink,
  faSquare,
  faTh,
  faThLarge,
  faUser,
  faVoteYea,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TitleCase } from "../model/Helpers";
import {Path} from "../App";

interface ViewInput {
  input?: VoteringFull,
  filterNamn?: string
  filterRost?: string
}

const VOTES_PER_RENDER = 20;

export const VoteringFullView = (props: ViewInput) => {
  const [render, setRender] = useState<number>(VOTES_PER_RENDER);
  const [scroll = 0, setScroll] = useState<number>();
  const [columnView, setColumnView] = useState<string>("is-full");


  const listener = () => {
    setScroll(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener('scroll', listener);
  }, []);

  useEffect(() => {
    if ((window.innerHeight + scroll) >= (document.body.offsetHeight - 5)) {
      setRender(render + VOTES_PER_RENDER);
    }
  }, [scroll]); // eslint-disable-line react-hooks/exhaustive-deps

  if (props === undefined || props.input === undefined || props.input.dokvotering === undefined) {
    return (<></>) // empty data, render nothing.
  }

  function getCardColourClass(vote: string): string {
    let colourState: string;
    if (vote === 'Avstår')
      colourState = 'dark';
    else if (vote === 'Frånvarande')
      colourState = 'light';
    else if (vote === 'Ja')
      colourState = 'success';
    else if (vote === 'Nej')
      colourState = 'danger';
    else {
      colourState = 'warning'
    }
    return `tile s notification is-${colourState}`;
  }

  function renderOneVote(personRost: PersonVoteringFull, index: number) {
    return (
      <>
        {index < render &&
        <div key={personRost.intressent_id}
             className={`column ${columnView}`}>
          <div className={getCardColourClass(personRost.rost)}>
            <div className="content">
              <p className="title">
                <FontAwesomeIcon icon={faUser}/>&nbsp;
                {TitleCase(personRost.namn)}
              </p>
              <p className="subtitle">
                <FontAwesomeIcon icon={faVoteYea}/>&nbsp;{personRost.rost}</p>
              <div className="content">
                <p><FontAwesomeIcon
                  icon={faFlag}/>&nbsp;Parti: {personRost.parti}
                </p>
                <p><FontAwesomeIcon
                  icon={faBirthdayCake}/>&nbsp;Född: {personRost.fodd}</p>
                <p><FontAwesomeIcon
                  icon={faHome}/>&nbsp;Valkrets: {personRost.valkrets}</p>
                <a
                  href={`${Path.ENKAMMARRIKSDAGEN}/person?intressent_id=${personRost.intressent_id}`}><FontAwesomeIcon
                  icon={faLink}/>&nbsp;Mer info...</a>
              </div>
            </div>
          </div>
        </div>
        }
      </>
    )
  }

  return (
    <>
      <div className="buttons has-addons">
        <button
          className={columnView === "is-full" ? "button is-info" : "button"}
          onClick={() => setColumnView("is-full")}>
          <FontAwesomeIcon icon={faSquare}/>
        </button>
        <button
          className={columnView === "is-half" ? "button is-info" : "button"}
          onClick={() => setColumnView("is-half")}>
          <FontAwesomeIcon icon={faThLarge}/>
        </button>
        <button
          className={columnView === "is-one-third" ? "button is-info" : "button"}
          onClick={() => setColumnView("is-one-third")}>
          <FontAwesomeIcon icon={faTh}/>
        </button>
      </div>
      <div className="columns is-multiline">
        {props.input.dokvotering.votering
          .filter(it => {
              return (props.filterRost !== undefined && props.filterRost !== '') ?
                it.rost === props.filterRost
                : true
            }
          )
          .filter(it => {
              return (props.filterNamn !== undefined) ?
                it.namn.toLowerCase().includes(props.filterNamn.toLowerCase())
                : true
            }
          ).map(renderOneVote)}
      </div>
    </>
  );
};

