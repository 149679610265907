import React, { useEffect, useState } from "react";

import { CollapsibleCard } from "./Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useTyper } from "./useTyper";

const TYPE_SORTED_CACHE_KEY = 'typer-cache-sorted';
const TYPE_POPULARITY_CACHE_KEY = 'typer-popularity-cache';
const INFO_TEXT = 'De olika dokument-typerna som dokument kan vara markerade som';

interface Typ {
  seriekod: string    //: "01",
  typ: string         //: "bet",
  subtyp?: string     //: null,
  doktyp: string      //: "bet",
  sortering: string   //: "1001",
  namn: string        //: "Betänkande",
  pluralnamn: string  //: "Betänkanden och utlåtanden",
  familj: string      //: "Utskotten och EU-nämnden",
  grupp: string       //: "dokument",userPa
  beskrivning: string //: "Betänkanden är förslag från riksdagens utskott till riksdagen. Utlåtanden skriver utskotten över olika EU-förslag."
  // Added for sort
  count: number
}

interface TyperResponse {
  typer: {
    typ: Typ[]
  }
}

interface TyperPopularityResponse {
  popularity: {
    doktyp: string,
    size: number
  }[]
}

export const DokumentTyperList = (props: {
  alreadySelectedTypes: string[],
  limitTypesTo?: string[],
  setFunction: (set: string[], updateType?: "pushIn") => void
}
) => {

  const typer: TyperResponse = useTyper();
  const [typerSorted, setTyperSorted] = useState<TyperResponse>({ typer: { typ: [] } });
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [showAll, setShowAll] = useState<boolean>(false);
  const typeLimiter = props.limitTypesTo ?? typerSorted.typer.typ.map(typ => { return typ.doktyp }) ?? [];


  useEffect(() => {
    if (props.alreadySelectedTypes) {
      setSelectedTypes(props.alreadySelectedTypes)
    }
    else setSelectedTypes([]);
  }, [props.alreadySelectedTypes]);


  async function fetchTyperPopularity(): Promise<TyperPopularityResponse> {
    let typePopularityCache = localStorage.getItem(TYPE_POPULARITY_CACHE_KEY);
    if (typePopularityCache) {
      return JSON.parse(typePopularityCache)
    }

    let jsonAnswer: TyperPopularityResponse;
    try {
      // NOTE: Popularity can be used with 'query', default if missing is "*."
      let response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/dokument/types/popularity`);
      jsonAnswer = await response.json();
    } catch (e) {
      console.error(e);
      // TODO: Error handling
      throw new Error('failed with the networking.')
    }
    localStorage.setItem(TYPE_POPULARITY_CACHE_KEY, JSON.stringify(jsonAnswer));
    return jsonAnswer;
  }

  function sortTypesList(popularityResponse: TyperPopularityResponse, sortMe: TyperResponse): TyperResponse {
    popularityResponse.popularity.forEach(pop => {
      const maybeFound = sortMe.typer.typ.find(it => it.doktyp === pop.doktyp);
      if (maybeFound !== undefined) {
        maybeFound.count = pop.size;
      }
    }
    );
    // replace all undefined with 0.
    sortMe.typer.typ.forEach(it => {
      if (it.count === undefined) it.count = 0
    });
    sortMe.typer.typ.sort((a, b) => {
      return b.count - a.count // descending order, biggest first!
    });
    return sortMe;
  }

  useEffect(() => {
    async function fetchTyperSorted(): Promise<TyperResponse> {
      let typeCache = localStorage.getItem(TYPE_SORTED_CACHE_KEY);
      if (typeCache) {
        return JSON.parse(typeCache) as TyperResponse;
      }

      const popularityResponse: TyperPopularityResponse = await fetchTyperPopularity(); // get popularity for sorting
      let sorted = sortTypesList(popularityResponse, typer);
      localStorage.setItem(TYPE_SORTED_CACHE_KEY, JSON.stringify(sorted));
      return sorted;
    }
    fetchTyperSorted()
      .then(setTyperSorted)
  }, [typer]);
  // Make sure to fetch them from cache or if missing API.

  function pressToAdd(wasPressed: Typ) {
    const newState = [...selectedTypes, wasPressed.doktyp];
    setSelectedTypes(newState);
    props.setFunction && props.setFunction(newState, "pushIn")
  }

  function pressToRemove(wasPressed: Typ) {
    const newState = selectedTypes.filter(it => it !== wasPressed.doktyp);
    setSelectedTypes(newState);
    props.setFunction && props.setFunction(newState, "pushIn")
  }
  return (
    <>
      <div className="card">
        <CollapsibleCard title={'Dokument Typer'}
          initalState={props.alreadySelectedTypes.length === 0}>
          {typerSorted.typer.typ.map(typ => {
            if (typeLimiter.some(x => typ.doktyp === x)) {
              if (!showAll && typ.count < 500) return undefined; // Too small, don't show.
              if (typ.count === 0) return undefined; // Zero has never any value
              const alreadyContains = selectedTypes.some(it => it === typ.doktyp);
              return <button key={typ.doktyp}
                className={alreadyContains ? 'button is-info is-selected' : 'button'}
                onClick={alreadyContains ? _ => pressToRemove(typ) : _ => pressToAdd(typ)}
                title={typ.beskrivning}
              >
                {typ.namn} ({typ.count ? typ.count : 0})
                  </button>
            }
            return undefined;
          }
          )}
          <button key={'expand-button'}
            className={'button is-small is-outlined is-warning'}
            onClick={_ => setShowAll(old => !old)}>{showAll ? 'Visa inte alla.' : 'Visa alla.'}</button>
          <span className="icon is-medium has-text-info" title={INFO_TEXT}>
            <FontAwesomeIcon className="is-center" icon={faInfoCircle} />
          </span>
        </CollapsibleCard>
      </div>
    </>
  )
};
