import { Parser } from 'json2csv';
import React, { useEffect, useState } from 'react';
import { smallLeftMargin } from '../CssConstants'
import downloadExport from './downloadExport'
import exportDokument from '../dokument/export/ExportDokument'
import { selectableDokumentProperties } from '../dokument/DokumentProperties'
import { DokumentExport } from "./DokumentStatus";

const selectableDokumentPropertiesWithDokumnet = selectableDokumentProperties.concat("dokument");

export interface ExportParams {
  queryParam: string,
  types?: Array<string>,
  gteDate?: string,
  lteDate?: string,
  selectedProperties: Array<string>
}

export const DokumentExportModal = (props: { openExportModal: boolean, setOpenExportModal: (state: boolean) => void, exportParams: ExportParams, numberOfResults: string }) => {
  const { openExportModal, setOpenExportModal, exportParams, numberOfResults } = props;
  const [checkedExportProps, setCheckedExportProps] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);
  const [downloadedDokuments, setDownloadedDokuments] = useState(0);
  const [csvExport, setCsvExport] = useState<boolean>(false);
  const [exportWithBom, setExportWithBom] = useState<boolean>(false);

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        setOpenExportModal(false)
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  });
  const handleClick = (elem: string, alreadyContains: boolean) => {
    if (alreadyContains) {
      setCheckedExportProps(checkedExportProps.filter(item => item !== elem))
    } else {
      setCheckedExportProps([...checkedExportProps, elem])
    }
  };
  const addDownloaded = (inc: number) => {
    if (inc === -1) {
      setDownloadedDokuments(0);
      return
    }
    setDownloadedDokuments((old) => old + inc)
  };

  const customConverter = (objArray: Array<DokumentExport>) => {
    const json2csv = new Parser({
      withBOM: true,
      quote: '',
      unwindBlank: true,
      delimiter: ";"
    });
    let csvString = json2csv.parse(objArray);
    downloadExport({exportType: "csv", exportName: "dokument", csvString: csvString, json: []});
  };
  return (
    <div>
      <div className={openExportModal ? "modal is-active" : 'is-hidden'}>
        <div className="modal-background"
             onClick={() => setOpenExportModal(false)}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Exportera dokumentresultat</p>
            <button className="delete" aria-label="close"
                    onClick={() => setOpenExportModal(false)}></button>
          </header>
          <section className="modal-card-body">
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  onChange={(e) => e.target.checked ? setCheckedExportProps([...selectableDokumentPropertiesWithDokumnet]) : setCheckedExportProps([])}
                  checked={checkedExportProps.length === selectableDokumentPropertiesWithDokumnet.length}>
                </input>
                {`  alla`}
              </label>
              <div style={smallLeftMargin}>
                {selectableDokumentPropertiesWithDokumnet.map((elem, index) => {
                  const alreadyContains = checkedExportProps.some(it => it === elem);
                  return (
                    <div key={index.toString()}>
                      <label className="checkbox">
                        <input type="checkbox"
                               onChange={() => handleClick(elem, alreadyContains)}
                               checked={alreadyContains}></input>
                        {`  ${elem}`}
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>

          <footer className="modal-card-foot">
            {loading ?
              <div
                className="modal-card-title is-left is-size-6"> {downloadedDokuments === 0 ? `Fetching metadata...` : `Fetched ${downloadedDokuments} of ${numberOfResults}`}</div>
              :
              <div className="modal-card-title control is-left is-size-6">
                <label className="radio">
                  <input type="radio" name="answer" checked={!csvExport}
                         onChange={() => {
                           setCsvExport(false)
                         }}/>
                  {` JSON`}
                </label>
                <label className="radio">
                  <input type="radio" name="answer" checked={csvExport}
                         onChange={() => {
                           setCsvExport(true)
                         }}/>
                  {` CSV `}
                </label>
                {csvExport &&
                <label className="checkbox" style={smallLeftMargin}>
                  <input type="checkbox" checked={exportWithBom} name="answer"
                         onChange={() => setExportWithBom(!exportWithBom)}/>
                  {` with UTF-8 BOM`}
                </label>}
              </div>


            }
            {/* checkedExportProps.length > 0 &&  */}
            <button className="button"
                    onClick={() => setOpenExportModal(false)}>Avbryt
            </button>
            <button
              className={loading ? "button is-info is-right is-loading" : "button is-info is-right"}
              disabled={checkedExportProps.length <= 0} onClick={() =>
              exportDokument(checkedExportProps, exportParams, setLoading, addDownloaded)
                .then((list) => {
                  csvExport ? customConverter(list) : downloadExport({exportType: "json", exportName: "dokument", csvString: "", json: list})
                })
            }>
              Exportera
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};
