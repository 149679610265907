import React from "react";
import {pagingMargin} from "../../CssConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faBook,
  faCalendar,
  faIdBadge,
  faSort
} from "@fortawesome/free-solid-svg-icons";
import {TitleCase} from "../../model/Helpers";
import {
  StandsriksdagenMeta,
  TvakammarriksdagenMeta
} from "../../model/ApiResponse";
import {displayMatchBlock} from "../../util/DocumentUtil";
import {Path} from "../../App";

interface Props {
  basePath: Path;
  match: StandsriksdagenMeta | TvakammarriksdagenMeta;
}

const DocumentCard = (props: Props) => {
  const {match, basePath} = props;

  const getYear = (): string => {
    if (match.years?.from && match.years?.from) {
      return match.years.from === match.years.to ?
        match.years.from : `${match.years.from}-${match.years.to}`;
    }

    return 'trasigt värde'
  }

  return (
    <div key={match.filnamn} style={pagingMargin}>
      <article key={match.filnamn}  className="tile is-child notification">
        <div className="content">
          <p className="title">
            <a href={`${basePath}?filename=${match.filnamn}`}>
              <FontAwesomeIcon icon={faBook} />
              {' '} {match.title && TitleCase(match.title)} {' '}
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </a>
          </p>
          <div className="content">
            <p><FontAwesomeIcon icon={faCalendar} />
              År: {getYear()}
            </p>
            <p>
              <FontAwesomeIcon icon={faIdBadge}/>
              Fil: <a href={`${basePath}?filename=${match.filnamn}`}>{match.filnamn}</a>
            </p>
            <p>
              <FontAwesomeIcon icon={faSort}/>
              Typ: {match.doktyp}
            </p>
          </div>
          {displayMatchBlock(match.match)}
        </div>
      </article>
    </div>
  )
};

export default DocumentCard;
