import React, {SetStateAction, useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faFingerprint,
  faHashtag,
  faSearch
} from '@fortawesome/free-solid-svg-icons'
import {Anforande} from "../model/ApiResponse";
import {SearchPageView} from "./AnforandeView";
import {SimplePager} from "./Paging";
import {StringParam, useQueryParam} from "use-query-params";
import {ClipboardButton} from "../model/ClipboardService";
import {Modal} from "../model/Modal";
import {errorDecider} from "../model/Helpers";
import {noBoxShadow} from "../CssConstants";

const PAGE_TITLE = "Enkammarriksdagen";
const STORED_QUERY = "anf-query-uuid";

interface Props {
  setPageTitle: (pageTitle: string) => void;
}

interface StoredParam {
  uuidParam?: string,
}

export const AnforandeTab = (props: Props) => {
  const [uuidParam = "", setUuidParam] = useQueryParam('uuid', StringParam);
  const [emptySubmit, setEmptySubmit] = useState<boolean>(false);
  const [anforande, setAnforande] = useState<Anforande | undefined>(undefined);
  const [searchByUuid, setSearchByUUid] = useState<boolean>(true);
  const [disconnected, setDisconnected] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  props.setPageTitle(PAGE_TITLE);

  useEffect(() => {
    if (!uuidParam) {
      const storedValues = sessionStorage.getItem(STORED_QUERY);
      if (storedValues) {
        const { uuidParam }: StoredParam = JSON.parse(storedValues);
        setUuidParam(uuidParam)
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //  Effect that fires from both search types
  useEffect(() => {
    // default search on mount or from Uuid change
    async function doSearchByUUID(uuid: string): Promise<Anforande> {
      setLoading(true);
      let jsonAnswer: Anforande;
      let response: Response | undefined;
      try {
        response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/anforande?uuid=${uuid}`);
      } finally {
        setLoading(false);
        errorDecider(response)
      }
      jsonAnswer = await response.json();
      return jsonAnswer;
    }

    setStorage();
    if (searchByUuid) {
      setEmptySubmit(false);
      if (uuidParam) {
        doSearchByUUID(uuidParam)
          .then((anforande) => (anforande.anforande !== undefined) && setAnforande(anforande))
          .catch(errorHandler)
      } else {
        setAnforande(undefined);
      }
    }
  }, [uuidParam]); // eslint-disable-line react-hooks/exhaustive-deps

  function setStorage() {
    const storedObject: StoredParam = { uuidParam };
    sessionStorage.setItem(STORED_QUERY, JSON.stringify(storedObject))
  }

  function errorHandler(e: Error) {
    switch (e.name) {
      case "EmptyError":
        setEmptySubmit(true);
        break;
      case "ConnectionError":
        setDisconnected(true);
        break;
    }
  }

  function setNrAndReload(newNr: string) {
    if (anforande) {
      doSearchByDokIdAndNr(anforande.anforande.dok_id, newNr)
        .then(setAnforandeAndSync)
        .catch(errorHandler)
    }
  }

  // search came from dok or nr change
  function setAnforandeAndSync(maybeAnforande: Anforande | undefined) {
    if (maybeAnforande === undefined || maybeAnforande.anforande === undefined) return;
    // this is here so that effect does not fetch but still pushIn the URL in cache
    if (searchByUuid) {
      setSearchByUUid(false);
      setUuidParam(maybeAnforande.anforande.anforande_id, "pushIn");
      setSearchByUUid(true)
    } else {
      setUuidParam(maybeAnforande.anforande.anforande_id, "pushIn");
    }

    setAnforande(maybeAnforande);
  }

  async function doSearchByDokIdAndNr(dok_id: string, number: string): Promise<Anforande> {
    setLoading(true);
    let jsonAnswer: Anforande;
    let response: Response | undefined;
    try {
      response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/anforande?dok_id=${dok_id}&nr=${number}`);
      jsonAnswer = await response.json();
    } finally {
      setLoading(false);
      errorDecider(response)
    }
    jsonAnswer.anforande ? setEmptySubmit(false) : setEmptySubmit(true);
    return jsonAnswer;
  }

  return (
    <div>
      <InputForm
        uuidParam={uuidParam}
        searchByUuid={uuid => setUuidParam(uuid, "pushIn")}
        searchByDokIdNr={(dokId, nr) =>
          doSearchByDokIdAndNr(dokId, nr)
            .then(setAnforandeAndSync)
            .catch(errorHandler)}
        maybeAnforande={anforande}
        setSearchByUUid={setSearchByUUid}
        loading={loading}
      />
      <SimplePager anf={anforande}
                   doSearch={setNrAndReload}/>
      {emptySubmit ?
        <p className="subtitle is-4 has-text-danger has-text-centered ">Inga
          sökresultat </p> : <SearchPageView anf={anforande}/>}
      <div className="box" style={noBoxShadow}>
      </div>
      <Modal
        disconnected={disconnected}
        setDisconnected={setDisconnected}/>
    </div>
  );

};

const InputForm = (props: {
  uuidParam?: string
  maybeAnforande?: Anforande
  searchByUuid: (uuid: string) => void,
  searchByDokIdNr: (dokId: string, nr: string) => void,
  setSearchByUUid: React.Dispatch<SetStateAction<boolean>>,
  loading: boolean
}) => {
  const { maybeAnforande, searchByUuid, searchByDokIdNr, uuidParam, setSearchByUUid, loading } = props;

  const [uuid, setUuid] = useState(maybeAnforande ? maybeAnforande.anforande.anforande_id : uuidParam);
  const [dok_id, setDokId] = useState(maybeAnforande ? maybeAnforande.anforande.dok_id : undefined);
  const [nr, setNr] = useState(maybeAnforande ? maybeAnforande.anforande.anforande_nummer : undefined);
  const [useUuid = true, setUseUuid] = useState<boolean>(true);

  useEffect(() => {
    setUuid(props.uuidParam);
    setDokId(maybeAnforande ? maybeAnforande.anforande.dok_id : undefined);
    setNr(maybeAnforande ? maybeAnforande.anforande.anforande_nummer : undefined)
  }, [props.uuidParam, props.maybeAnforande]); // eslint-disable-line react-hooks/exhaustive-deps


  function validateNr(newNr: string | undefined): boolean {
    if (!newNr) return false;
    const parsedNr = Number(newNr);
    return parsedNr >= 1;
  }

  function selectChange(selected: string) {
    switch (selected) {
      case 'doknr':
        setUseUuid(false);
        setSearchByUUid(false);
        break;
      default:
      case 'uuid':
        setUseUuid(true);
        setSearchByUUid(true)
    }
  }

  function submitSearch(submitSearch: React.FormEvent) {
    submitSearch.preventDefault();
    if (useUuid) {
      searchByUuid(uuid || "");
    } else if (dok_id) {
      validateNr(nr) ? searchByDokIdNr(dok_id, nr || "") : searchByDokIdNr(dok_id, "1")
    }
  }

  return (

    <form className="field has-addons" onSubmit={submitSearch}>
      <div className="control">
        <div className="select">
          <select onChange={event => selectChange(event.target.value)}>
            <option value="uuid">UUID sökning</option>
            <option value="doknr">Dok_id och Nummer sökning</option>
          </select>
        </div>
      </div>
      <div
        className={useUuid ? 'control is-expanded has-icons-left' : 'is-hidden'}>
        <input className="input" type="text"
               value={uuid || ''}
               id="anforande-UUID-input"
               placeholder="Skriv din UUID sökning här"
               onChange={e => setUuid(e.target.value)}/>
        <span className="icon is-small is-left">
          <FontAwesomeIcon icon={faFingerprint}/>
        </span>
      </div>
      <div
        className={!useUuid ? "control is-expanded has-addons has-icons-left" : 'is-hidden'}>
        <input className="input" type="text"
               value={dok_id || ''}
               id="anforande-dokId-input"
               placeholder="Dokument-Id for dokumentet bundet kopplat till debatten."
               onChange={e => setDokId(e.target.value)}/>
        <span className="icon is-small is-left">
          <FontAwesomeIcon icon={faSearch}/>
        </span>
      </div>
      <div
        className={!useUuid ? "control has-addons has-icons-left" : 'is-hidden'}>
        <input className="input" type="number"
               value={nr || ''}
               placeholder="Nummer, Anförande"
               onChange={e => {
                 setNr(e.target.value)
               }}/>
        <span className="icon is-small is-left">
          <FontAwesomeIcon icon={faHashtag}/>
        </span>
      </div>

      <div className="control">
        <button
          className={loading ? "button is-info is-loading" : "button is-info"}>
          Sök
        </button>
      </div>
      <ClipboardButton hidden={maybeAnforande === undefined}/>
    </form>
  )
};
