import { createMuiTheme } from '@material-ui/core/styles';

export const PANTONE_COOL_GRAY_9 = "#747678"
export const PANTONE_186 = "#d4021d"
export const PANTONE_279 = "#4a96cd"

export const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: PANTONE_279
    },
    secondary: {
      main: PANTONE_186,
    },
  },
});
