import React, {ChangeEvent, useState} from "react";
import {useStyles} from "./SelectField.style";
import {MenuItem, Select} from "@material-ui/core";

interface Props {
  defaultValue?: string;
  values?: string[];
  isVisible?: boolean;
  onChange: (value: string) => void;
}

const SelectField = (props: Props) => {
  const {defaultValue, values, isVisible, onChange} = props
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState<string>()

  const handleChange = (event: ChangeEvent<{name?: string|undefined, value: unknown}>, child?: React.ReactNode): void => {
    // @ts-ignore
    setSelectedValue(event.target.value)
    // @ts-ignore
    onChange(event.target.value)
  }

  if (!isVisible){
    return null;
  }

  return (
    <>
      <Select id="types" variant={"outlined"} defaultValue={defaultValue} value={selectedValue} onChange={handleChange} className={classes.select}>
        {values?.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
      </Select>
    </>
  )
}

export default SelectField;
