import React from 'react';
import {useStyles} from "./CustomAppBar.style";
import {AppBar, Toolbar, Typography} from "@material-ui/core";
import Menu from "../Menu/Menu";
import logo from '../../Assets/Img/logo.svg';
import orebroLogo from "../../Assets/Img/orebroLogo.svg";
import {isBrowser} from 'react-device-detect';


interface Props {
  pageTitle: string;
}

const CustomAppBar = (props: Props) => {
  const classes = useStyles();

  return (
      <AppBar position="fixed">
        <Toolbar>
          <Menu/>
            <Typography variant="h6" className={classes.title}>
              {isBrowser && props.pageTitle}
            </Typography>
          {isBrowser ?
            <img src={orebroLogo} width={130} alt={"logo"}/>:
            <img className={classes.logo} src={logo} width={25} alt={"logo"}/>}
        </Toolbar>
      </AppBar>
  );
}

export default CustomAppBar;
