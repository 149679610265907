import React, {useState} from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import LinkIcon from '@material-ui/icons/Link';
import FilterListIcon from '@material-ui/icons/FilterList';
import HelpIcon from '@material-ui/icons/Help';
import IconButton, {DividerType} from "./Component/IconButton/IconButton";
import TextButton from "./Component/TextButton/TextButton";
import {useStyles} from "./SearchBar.style";
import SelectField from "./Component/SelectField/SelectField";
import {BrowserView, MobileView} from 'react-device-detect';
import copy from "clipboard-copy";
import Snackbar from "../Snackbar/Snackbar";
import {LinearProgress} from "@material-ui/core";
import {customTheme, PANTONE_COOL_GRAY_9} from "../../CustomTheme";
import SearchFilter, {Filter} from "./Component/SearchFilter/SearchFilter";

interface Props {
  isLoading?: boolean
  defaultQuery?: string;
  defaultFilter?: Filter;
  selectDefaultValue?: string;
  selectValues?: string[];
  hits?: string;
  showExport?: boolean;
  showFilter?: boolean
  showCopyLink?: boolean;
  showQuestionMark?: boolean;
  onExport?: () => void;
  onQuestionMark?:() => void;
  onSubmit: (query: string, selectedValue?: string, filter?: Filter) => void;
}

interface State {
  query: string;
  selectedValue: string;
  filter?: Filter,
}

export default function SearchBar(props: Props) {
  const classes = useStyles();
  const {
    isLoading,
    defaultQuery,
    defaultFilter,
    hits,
    selectDefaultValue,
    selectValues,
    onSubmit,
    onExport,
    showExport,
    showCopyLink,
    onQuestionMark,
    showQuestionMark,
    showFilter,
  } = props;

  const [state, setState] = useState<State>({
    query: defaultQuery ? defaultQuery : "",
    selectedValue: selectDefaultValue ? selectDefaultValue: ""
  });

  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false)


  const handleCopyToClipboard = (): void => {
      copy(window.location.toString()).then(() => setIsSnackbarOpen(true))
    }

  const handleSubmit = (event: React.FormEvent<HTMLDivElement>): void => {
    event.preventDefault();
    onSubmit(state.query, state.selectedValue, state.filter);
  }

  const handleSubmitFromButton = (): void => {
    onSubmit(state.query, state.selectedValue, state.filter);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({...state, query: event.target.value})
  }

  const handleSelectChange = (value: string): void => {
    setState({...state, selectedValue: value})
    onSubmit(state.query, value, state.filter);
  }

  const handleOpenFilter = (): void => {
    setIsFilterMenuOpen(!isFilterMenuOpen);
  };

  const handleApplyFilter = (filter: Filter): void => {
    setState({...state, filter})
    onSubmit(state.query, state.selectedValue, filter);
  };

  return (
    <>
      <SearchFilter defaultFilter={defaultFilter} onApply={handleApplyFilter} isOpen={isFilterMenuOpen} setIsOpen={setIsFilterMenuOpen}/>
      <Snackbar
        severity={"success"}
        open={isSnackbarOpen}
        setIsOpen={setIsSnackbarOpen}
        message={"URL copied to clipboard"}
      />
      <BrowserView>
        <Paper className={classes.root} onSubmit={handleSubmit}>
          <SelectField isVisible={selectValues !== undefined} defaultValue={selectDefaultValue} values={selectValues} onChange={handleSelectChange}/>
          <form className={classes.form}>
            <IconButton color={PANTONE_COOL_GRAY_9} onClick={handleSubmitFromButton} icon={<SearchIcon/>} isVisible={true} ariaLabel={"search"} divider={selectValues ? DividerType.LEFT : undefined}/>
            <InputBase
              className={classes.input}
              onChange={handleChange}
              fullWidth={true}
              placeholder="Skriv din sökning här"
              inputProps={{'aria-label': 'search google maps'}}
              defaultValue={state.query}
            />
            <IconButton color={PANTONE_COOL_GRAY_9} onClick={onQuestionMark} icon={<HelpIcon/>} isVisible={showQuestionMark} ariaLabel={"help"}/>
            <TextButton color={PANTONE_COOL_GRAY_9} text={`${hits} träffar`} isVisible={hits !== undefined} divider={DividerType.LEFT} ariaLabel={"hits"}/>
            <IconButton color={customTheme.palette.secondary.main} onClick={onExport} icon={<GetAppIcon/>} isVisible={showExport} divider={DividerType.LEFT} ariaLabel={"export"}/>
            <IconButton color={customTheme.palette.primary.main} onClick={handleCopyToClipboard} icon={<LinkIcon/>} isVisible={showCopyLink} divider={DividerType.LEFT} ariaLabel={"copy-link"}/>
            <IconButton color={PANTONE_COOL_GRAY_9} onClick={handleOpenFilter} icon={<FilterListIcon/>} divider={DividerType.LEFT} isVisible={showFilter} ariaLabel={"filter"}/>
          </form>
        </Paper>
        {isLoading && <LinearProgress color={"secondary"}/>}
      </BrowserView>

      <MobileView>
        {hits &&
          <div className={classes.actionTopBarMobile}>
            <SelectField isVisible={selectValues !== undefined} defaultValue={selectDefaultValue} values={selectValues} onChange={handleSelectChange}/>
            <TextButton color={"#FFF"} text={`${hits} träffar`} isVisible={hits !== undefined} ariaLabel={"hits"}/>
            <IconButton color={customTheme.palette.secondary.main} onClick={onExport} icon={<GetAppIcon/>} isVisible={showExport} divider={DividerType.LEFT} ariaLabel={"export"}/>
          </div>
        }

        <Paper className={classes.root} onSubmit={handleSubmit}>
        <form className={classes.formMobile}>
          <IconButton color={"#494949"} onClick={handleSubmitFromButton} icon={<SearchIcon/>} isVisible={true} ariaLabel={"search"}/>
          <InputBase
            className={classes.input}
            onChange={handleChange}
            fullWidth={true}
            placeholder="Skriv din sökning här"
            inputProps={{'aria-label': 'search google maps'}}
            defaultValue={state.query}
          />
            <IconButton color={"#969393"} onClick={onQuestionMark} icon={<HelpIcon/>} isVisible={showQuestionMark} ariaLabel={"help"}/>
            <IconButton color={"#205ec6"} onClick={handleCopyToClipboard} icon={<LinkIcon/>} isVisible={showCopyLink} divider={DividerType.LEFT} ariaLabel={"copy-link"}/>
        </form>
        </Paper>
        {isLoading && <LinearProgress color={"secondary"}/>}
      </MobileView>
    </>
  );
}
