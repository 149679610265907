import React, {useEffect, useState} from "react";
import {NumberParam, StringParam, useQueryParam} from "use-query-params";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faFlag,
  faIdBadge,
  faMapMarkedAlt,
  faQuestionCircle,
  faUser,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import {ClipboardButton} from "../model/ClipboardService";
import {PersonMeta, PersonMetaList} from "./PersonMeta";
import {errorDecider, Paginator} from "../model/Helpers";
import {noBoxShadow, NotActive} from "../CssConstants";
import {Modal} from "../model/Modal";
import {Path} from "../App";

const PAGE_TITLE = "Enkammarriksdagen";
const STORED_QUERY = "person-search-query";

interface Props {
  setPageTitle: (pageTitle: string) => void;
}

interface StoredParams {
  queryParam: string,
  pageParam: number
}

export const PersonSearchTab = (props: Props) => {
  const [queryParam = '', setQueryParam] = useQueryParam('query', StringParam);
  const [pageParam = 1, setPageParam] = useQueryParam('page', NumberParam);
  const [emptySubmit, setEmptySubmit] = useState<boolean>(false);
  const [person, setPerson] = useState<PersonMetaList>();
  const [disconnected, setDisconnected] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  props.setPageTitle(PAGE_TITLE);

  useEffect(() => {
    if (!queryParam) {
      const storedObject = sessionStorage.getItem(STORED_QUERY);
      if (storedObject) {
        const { queryParam, pageParam }: StoredParams = JSON.parse(storedObject);
        setQueryParam(queryParam);
        setPageParam(pageParam)
      }
    }

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setEmptySubmit(false);
    if (queryParam) {
      searchPerson(queryParam, pageParam)
        .then(setPerson)
        .catch(errorHandler)
    } else {
      setPerson(undefined)
    }
  }, [queryParam, pageParam]); // eslint-disable-line react-hooks/exhaustive-deps


  async function searchPerson(query: string, page?: number): Promise<PersonMetaList> {
    setLoading(true);
    setStorage();
    let jsonAnswer: PersonMetaList;
    const params = new URLSearchParams();
    params.append('query', query);
    page && params.append('page', page.toString());
    let response: Response | undefined;
    try {
      response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/personer/search?${params}`);
    } finally {
      setLoading(false);
      errorDecider(response);
    }
    jsonAnswer = await response.json();
    return jsonAnswer;
  }

  function setStorage() {
    const storedObject: StoredParams = { queryParam, pageParam };
    sessionStorage.setItem(STORED_QUERY, JSON.stringify(storedObject))
  }

  function errorHandler(e: Error) {
    switch (e.name) {
      case "EmptyError":
        setEmptySubmit(true);
        setPerson(undefined);
        break;
      case 'ConnectionError':
        setDisconnected(true);
        break;
      default:
        console.error("Cannot handle error: ", e);
        break;
    }
  }

  function handleSubmit(query: string) {
    setQueryParam(query, "pushIn");
    setPageParam(1)
  }

  return (
    <>
      <PersonSearchForm hideClipboardButton={person === undefined}
        // searchPerson={doSearch}
                        queryParam={queryParam}
                        handleSubmit={handleSubmit}
                        loading={loading}
      />
      {/* want good spacing, don't want br */}
      <br></br>
      {person && person.paging && <Paginator paginator={person?.paging}
                                             doSearch={(newPage: number) => {
                                               setPageParam(newPage, "pushIn")
                                             }}/>}
      <RenderMetaList result={person && person.people}/>
      {person && person.paging && <Paginator paginator={person?.paging}
                                             doSearch={(newPage: number) => {
                                               setPageParam(newPage, "pushIn")
                                             }}/>}
      <div className="box" style={noBoxShadow}>
        {emptySubmit &&
        <p className="subtitle is-4 has-text-danger has-text-centered ">Inga
          sökresultat </p>}
      </div>
      <Modal
        disconnected={disconnected}
        setDisconnected={setDisconnected}/>
    </>
  )
};

const PersonSearchForm = (props: {
  hideClipboardButton: boolean,
  queryParam: string,
  handleSubmit: Function,
  loading: boolean
}) => {
  const { hideClipboardButton, queryParam, handleSubmit, loading } = props;
  const [query, setQuery] = useState<string>(queryParam);

  useEffect(() => {
    setQuery(queryParam)
  }, [queryParam]);

  function submitSearch(submitSearch: React.FormEvent) {
    submitSearch.preventDefault();
    handleSubmit(query)
  }


  return <form onSubmit={submitSearch}>
    <div className="field has-addons">
      <p className="control is-expanded has-icons-left">
        <input className="input" type="text"
               id="person-seach-input"
               defaultValue={queryParam}
               placeholder="Ange för eller efternamn av sökt person."
               onChange={e => setQuery(e.target.value)}/>
        <span className="icon is-small is-left">
          <FontAwesomeIcon icon={faUsers}/>
        </span>
      </p>
      <div className="control">
        <button
          className={loading ? "button is-info is-loading" : "button is-info"}>
          Sök
        </button>
      </div>
      <ClipboardButton hidden={hideClipboardButton}/>
    </div>
  </form>
};

function toHttps(urlString: string) : string {
  if(urlString.startsWith('http:')) {
    return urlString.replace('http', 'https')
  }
  return urlString;
}

const RenderMetaList = (props: {
  result?: Array<PersonMeta>
}) => {
  const { result } = props;
  if (!result) return <></>; // Nothing to render.
  return <>
    {result.map(person => {
      return (
        <article
          key={person.intressent_id ? person.intressent_id : person.name}
          className="card-content media">
          <figure
            className={person.picture_url_192 ? 'media-left' : 'is-hidden'}>
            <p className="image is-128x128">
              <img alt={''} // empty for decorative image.
                   src={toHttps(person.picture_url_192)}/>
            </p>
          </figure>
          <div className="media-content">
            <p className="title">
              <a
                style={person.intressent_id ? undefined : NotActive}
                href={person.intressent_id && `${Path.ENKAMMARRIKSDAGEN}/person?intressent_id=${person.intressent_id}`}>
                <FontAwesomeIcon icon={faUser}/>
                &nbsp;{person.name}&nbsp;
                <FontAwesomeIcon icon={faAngleDoubleRight}/>
              </a>
            </p>
            <p className={person.county ? 'subtitle' : 'is-hidden'}>
              <FontAwesomeIcon
                icon={faMapMarkedAlt}/> {person.county}</p>
            <div className="content">
              <p><FontAwesomeIcon
                icon={faFlag}/> Parti: {person.party ? person.party : 'uppgift saknas'}
              </p>
              <p><FontAwesomeIcon
                icon={faIdBadge}/> Intressent
                ID: {person.intressent_id ? person.intressent_id : 'uppgift saknas'}
              </p>
              <p><FontAwesomeIcon
                icon={faQuestionCircle}/> {person.currentStatus ? person.currentStatus : 'status saknas.'}
              </p>
            </div>
          </div>
        </article>
      )
    })
    }
  </>
};
