import {Paging, StandsriksdagenMeta} from "../model/ApiResponse";
import {PageSelection} from "../model/ApiRequest";

const PATH = `${process.env.REACT_APP_BASE_URL}/api/standsriksdagen/search`
const CONFIG = {
  method: "GET",
  headers: {'Accept': 'application/json'},
  mode: "cors"
} as RequestInit

export enum Types {
  BONDESTANDET = "Bondeståndet",
  BORGARSTANDET = "Borgarståndet",
  PRASTSTANDET = "Prästeståndet",
  RIDDARSKAPET_OCH_ADELN = "Riddarskapet och adeln",
  BIHANG_PROTOKOLL = "Bihang (till protokollen)",
  KONSTITUTIONSUTSKOTTET = "Konstitutionsutskottet",
  PERSONREGISTER = "Personregister",
  SAKREGISTER = "Sakregister",
  SVERIGES_RIKES_STANDERS_BESLUT = "Sveriges rikes ständers beslut",

}

export interface QueryParams extends PageSelection {
  query: string,
  gteYear?: number | string,
  lteYear?: number | string,
  types: Types[] | string[],
}

export interface ResponseData {
  dokument: StandsriksdagenMeta[],
  paging: Paging,
}

export const getStandsriksdagsData = (queryParams: QueryParams): Promise<ResponseData> => {
  return fetch(`${PATH}?${buildQueryString(queryParams)}`, CONFIG)
    .then(res => res.json())
};


const buildQueryString = (queryParams: QueryParams): string => {
  const keyValuePairs = [];
  for (let i = 0; i < Object.keys(queryParams).length; i += 1) {
    if (Object.values(queryParams)[i] !== "") {
      keyValuePairs.push(`${encodeURIComponent(Object.keys(queryParams)[i])}=${encodeURIComponent(Object.values(queryParams)[i])}`);
    }
  }
  return keyValuePairs.join('&');
}

export const getTypesValues = (): string[] => {
  // @ts-ignore
  return Object.keys(Types).map((type) => Types[type])
}
