import React, {useState} from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {useStyles} from "./Menu.style";
import {Divider, IconButton, ListItemIcon} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import {Link} from "react-router-dom";
import {Path} from "../../App";
import HomeIcon from '@material-ui/icons/Home';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import orebroLogo from "../../Assets/Img/orebroLogo.svg";

interface ListItemConfig {
  name: string;
  icon: JSX.Element;
  path: Path
}

const getListItemConfig = (): ListItemConfig[] => {
  return [
    {name: "Hem", path: Path.HOME, icon: <HomeIcon/>},
    {name: "Ståndsriksdagen", path: Path.STANDSRIKSDAGEN_SEARCH, icon: <KeyboardArrowRightIcon/>},
    {name: "Tvåkammarriksdagen", path: Path.TVAKAMMARRIKSDAGEN_SEARCH, icon: <KeyboardArrowRightIcon/>},
    {name: "Enkammarriksdagen", path: Path.ENKAMMARRIKSDAGEN, icon: <KeyboardArrowRightIcon/>},
    {name: "Om", path: Path.ABOUT, icon: <KeyboardArrowRightIcon/>}
  ]
}

const Menu = () => {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsMenuOpen(open);
  };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {getListItemConfig().map((config, index) => {
        return (
          <>
          <List>
            <Link to={config.path}>
              <ListItem className={classes.listItem} button key={config.name}>
                <ListItemIcon className={classes.listItemIcon}>{config.icon}</ListItemIcon>
                <ListItemText className={classes.listItemText} primary={config.name} />
              </ListItem>
            </Link>
          </List>
            {index === 0 && <Divider/>}
        </>
          )
      })}
      <div className={classes.logoContainer}>
        <a href={"https://www.oru.se/"}><img src={orebroLogo} width={200} alt={"logo"}/></a>
      </div>
    </div>
  );

  return (
    <div>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer(true)}
        edge="start"
        className={classes.icon}
      >
        <MenuIcon/>
      </IconButton>
      <Drawer anchor={"left"} open={isMenuOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
}

export default Menu;
