import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles({
  textContainer: {
    marginTop: 50,
    textAlign: "center",
  },
  cardContainer: {
    paddingBottom: 50,
  }
});
